import { useState, useEffect, useContext } from 'react'
import { Button, Space, Badge, Spin } from "antd"
import { RedoOutlined, MailOutlined } from '@ant-design/icons'
import { useImmer } from "use-immer"

import Axios from "axios"
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'


function DealerConnStatus(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const header = props.id in props.waState.header ? props.waState.header[props.id] : ""
    const message = props.id in props.waState.message ? props.waState.message[props.id] : ""
    const qrCode = props.id in props.waState.qrCode ? props.waState.qrCode[props.id] : ""
    const connected = props.id in props.waState.connected ? props.waState.connected[props.id] : false
    //const connStr = props.id in props.waState.connStr ? props.waState.connStr[props.id] : "disconnected"

    const [color, setColor] = useState("#FF4D4F")

    const [sendMailClicked, setSendMailClicked] = useImmer({isClicked: false, phoneNumber:"", requestCount:0})

    function onSendMailClick() {
        setSendMailClicked(draft => {
            draft.isClicked = true
            draft.phoneNumber = props.dealer.phone_number
            draft.requestCount++
        })
        setTimeout(() => {
            setSendMailClicked(draft => {
                draft.isClicked = false
            })
        }, 20000) // 20 seconds
    }

    useEffect(() => {
        if (sendMailClicked.requestCount === 0 || sendMailClicked.phoneNumber === "") return

        const ourRequest = Axios.CancelToken.source()

        async function sendMail() {
            try {
                const response = await api.post(
                    'send_mail_for_conn_notification/',
                    { 'phone_number':sendMailClicked.phoneNumber },
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                appDispatch({
                    type: "success",
                    data: "Notification mail is sent to user"
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to sent mail to user!"
                })
            }
        }

        sendMail()

        return () => {
            ourRequest.cancel()
        }

    }, [sendMailClicked.requestCount])


    useEffect(() => {
        const currentDate = new Date()
        let tempColor = "#FF4D4F"
        if (props.latestMessageDates !== null) {
            const latestMessageDate = props.latestMessageDates.find(item => item.phone.substring(1) === props.id)
            if (latestMessageDate) {
                const differenceInMinutes = (currentDate - latestMessageDate.time) / (1000 * 60)
                if (differenceInMinutes < 15) {
                    tempColor = "#73D673"
                }
            }
        }
        setColor(tempColor)
    }, [props.latestMessageDates])
    
    return (
        <div className='grid-item'>
            {/*(connected || connStr === "connected") ?
                <Badge count={" "} size="small" color="#52c41a">
                    <div style={{width:"100%"}}></div>
                </Badge> :
                <Badge count={" "} size="small" color="#f5222d">
                    <div style={{width:"100%"}}></div>
                </Badge>
            */}
            <Button
                className='btn-style'
                disabled={sendMailClicked.isClicked}
                onClick={onSendMailClick}
                icon={<MailOutlined />}
            >
                Send Mail
            </Button>
            <p>{props.dealer.full_name}</p>
            <p>{props.dealer.phone_number}</p>
            {/*<p>{connStr}</p>*/}
            <div>
                <h4 style={{color:color}}>{header}</h4>
                <p id="msg" className='withprewrap'>
                    {message}
                </p>
                {qrCode === "" || qrCode === undefined || connected ? "" : 
                <Space direction="vertical">
                    <img src={qrCode} alt="QR Code" id="qrcode" width={30}/>
                    {/*<Button
                        className='btn-style-red'
                        style={{marginLeft: '14px', width:'272px'}}
                        onClick={props.onRefreshClick(props.dealer.phone_number)}
                        //size="small"
                        icon={<RedoOutlined />}
                    >
                        Refresh QR Code
                    </Button>*/}
                </Space>
                }
            </div>
        </div>
    )
}

export default DealerConnStatus