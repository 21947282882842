import { useEffect, useContext, useState, useRef } from 'react'
import { Modal, Input, Button, Space, Tag, Spin, Table } from "antd"
import { useImmer } from "use-immer"
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { isValidEAN_, isValidUPC_ } from '../Utils'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

import Axios from "axios"
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'


const { TextArea } = Input


function ExtractUPCsAndGetProductInfoModal(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [state, setState] = useImmer({upcs: [], isLoading: false, msgId:-1, reqCntUPC: 0, reqCntApi: 0, apiResult: null})
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const inputRef = useRef(null)

    const isLarge = (state.apiResult !== null && state.apiResult.length > 0) ? true : false

    const showInput = () => {
        setInputVisible(true)
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const handleInputConfirm = () => {
        if (inputValue && !state.upcs.includes(inputValue)) {
            if (isValidUPC_(inputValue) || isValidEAN_(inputValue)) {
                setState(draft => {
                    draft.upcs = [...state.upcs, inputValue]
                })
            }
        }
        setInputVisible(false)
        setInputValue('')
    }

    function handleCancel() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
    }

    const handleClose = (removedTag) => {
        console.log(state.upcs.filter(tag => tag !== removedTag))
        setState(draft => {
            draft.upcs = state.upcs.filter(tag => tag !== removedTag)
        })
    }

    useEffect(() => {
        /*setState(draft => {
            draft.upcs = ['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4', 'Tag 5']
        })*/
    }, [])

    useEffect(() => {
        if (inputVisible) {
          inputRef.current?.focus()  // Focus input when it becomes visible
        }
    }, [inputVisible])

    useEffect(() => {
        if (state.reqCntUPC === 0 || state.msgId === -1) return
        const ourRequest = Axios.CancelToken.source()

        async function fetchUPCs() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setState(draft => {
                draft.isLoading = true
            })
            try {
                const response = await api.get(`extract_upcs/${state.msgId}/`, appState.token.get_config, { cancelToken: ourRequest.token })
                setState(draft => {
                    draft.upcs = response.data['upcs']
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch UPCs!"
                })
            }
            setState(draft => {
                draft.isLoading = false
            })
        }
        fetchUPCs()
        return () => {
            ourRequest.cancel()
        }
    }, [state.reqCntUPC])

    useEffect(() => {
        if (state.reqCntApi === 0 || state.upcs.length === 0) return
        const ourRequest = Axios.CancelToken.source()

        async function fetchProductData() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setState(draft => {
                draft.isLoading = true
            })
            let responseArr = []
            /*for (const upc of state.upcs) {
                try {
                    const response = await api.get(`get_product_info_from_api_by_upc/${upc}/`, appState.token.get_config, { cancelToken: ourRequest.token })
                    responseArr.push(response.data)
                } catch (err) {
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: `Unable to fetch product info from api for ${upc}`
                    })
                }
            }*/
            responseArr.push({
                "upc": "0885909950805",
                "product_name": "Apple iPhone 6, Space Gray, 64 GB (T-Mobile)",
                "asin": "B00NQGOZV0",
                "brand": "Apple",
                "part_number": "MG5A2LL/A"
            },
            {
                "upc": "0885909950805",
                "product_name": "Apple iPhone 6, Space Gray, 64 GB (T-Mobile)",
                "asin": "B00NQGOZV0",
                "brand": "Apple",
                "part_number": "MG5A2LL/A"
            })
            setState(draft => {
                draft.apiResult = responseArr
                draft.isLoading = false
            })
        }
        fetchProductData()
        return () => {
            ourRequest.cancel()
        }
    }, [state.reqCntApi])

    /*useEffect(() => {
        console.log(state.apiResult)
    }, [state.apiResult])*/

    useEffect(() => {
        setState(draft => {
            draft.upcs = []
            draft.isLoading = false
            draft.apiResult = null
        })
    }, [props.state.msgId])


    const productDataColumns = [
        {
            title: 'UPC',
            dataIndex: 'upc',
            key: 'upc',
        },
        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'product_name',
        },
        {
            title: 'ASIN',
            dataIndex: 'asin',
            key: 'asin',
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: 'Part Number',
            dataIndex: 'part_number',
            key: 'part_number',
        },
    ]

    const exportToExcel = () => {
        const exportData = state.apiResult.map(row => ({
            UPC: row.upc,
            ProductName: row.product_name,
            ASIN: row.asin,
            Brand: row.brand,
            PartNumber: row.part_number,
        }))
    
        // Create a new workbook
        const workbook = XLSX.utils.book_new()
    
        // Convert the export data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(exportData)
    
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Product Data')
    
        // Create a binary Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

        const date = new Date()
        const formattedDateTime = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}_${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`
    
        // Save the file
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
        saveAs(blob, `${props.state.msgId}_${formattedDateTime}.xlsx`)
    }

    return (
        <Modal
            centered
            title={""}
            open={props.state.modal.isOpen}
            onCancel={handleCancel}
            footer={""}
            width={isLarge ? "1200px" : "600px"}
        >
            {state.isLoading ? <div className='text-center'><Spin size="large" /></div> :
            <div style={{display:'flex', flexDirection:'row', gap:'10px'}}>
                <div style={{display:'flex', flexDirection:'column', gap:'10px', width:'600px'}}>
                    <strong>Message Content:</strong>
                    <TextArea 
                        style={{width:'96%', resize:'none'}}
                        rows={24}
                        value={props.state.content}
                        onChange={(e) => props.setState(draft => {draft.content = e.target.value })}
                    />
                    <div style={{display:'flex', flexDirection:'row', gap:'10px'}}>
                        <strong>UPCs:</strong>
                        <div>
                            {state.upcs.map((tag) => (
                                <Tag style={{fontSize:'14px', marginBottom:'8px'}} closable key={tag} onClose={() => handleClose(tag)}>
                                    {tag}
                                </Tag>
                            ))}
                            {inputVisible ? (
                                <Input
                                    ref={inputRef}
                                    type="text"
                                    size="small"
                                    style={{ width: 78 }}
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onBlur={handleInputConfirm}
                                    onPressEnter={handleInputConfirm}
                                />
                            ) : (
                                <Tag onClick={showInput} className="site-tag-plus">
                                    <PlusOutlined /> New UPC
                                </Tag>
                            )}
                            {state.upcs.length === 0 ? "" :
                                <Tag icon={<CloseCircleOutlined />} color="error" style={{cursor:'pointer'}}
                                        onClick={() => {setState(draft => {draft.upcs = []})}}>
                                    Remove All
                                </Tag>
                            }
                        </div>
                    </div>
                    <Space direction="horizontal" style={{paddingTop:'8px'}}>
                        <Button className='btn-style-blue' disabled={state.isLoading}
                                onClick={() => {setState(draft => {
                                    draft.msgId = props.state.msgId
                                    draft.reqCntUPC++
                                })}}
                        >
                            Extract UPCs
                        </Button>
                        <Button className='btn-style' disabled={state.upcs.length === 0 || state.isLoading}
                                onClick={() => {setState(draft => {
                                    draft.reqCntApi++
                                })}}
                        >
                            Get Product Data
                        </Button>
                    </Space>
                </div>
                {isLarge ?
                <div style={{display:'flex', flexDirection:'column', gap:'10px', width:'600px'}}>
                    <Table dataSource={state.apiResult} columns={productDataColumns} />
                    <Button className='btn-style-green' style={{width:'150px'}}
                        onClick={exportToExcel}
                    >
                        Export To Excel
                    </Button>
                </div> : ""}
            </div>}
        </Modal>
    )
}

export default ExtractUPCsAndGetProductInfoModal
