let logoutFunction // Function to be assigned later

export const setCustomLogoutFunction = func => {
	logoutFunction = func
}

export const logout = () => {
	if (logoutFunction) {
		logoutFunction()
	}
}