import { useState, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Divider, Input, Select, Space, Button } from 'antd'

import '../App.css'


function AntSelectWithAdd(props) {
    const [name, setName] = useState('')
    const inputRef = useRef(null)

    const onNameChange = (event) => {
        setName(event.target.value)
    }

    const addItem = (e) => {
        e.preventDefault()
        if (name === '') return
        if (name.length > props.maxLen) return
        setName(name.toLowerCase())
        props.addItem(name)
        //if (props.items.includes(name)) return
        //props.setItems([...props.items, name])
        setName('')
        setTimeout(() => {
            inputRef.current?.focus()
        }, 0)
    }

    return (
        <Select
            style={{
                width: 160,
            }}
            size='small'
            showSearch={true}
            onChange={props.onChange}
            value={props.selected}
            placeholder={props.selectPlaceholder}
            notFoundContent={props.notFoundContent}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider
                        style={{
                            margin: '8px 0',
                        }}
                    />
                    <Space
                        style={{
                            padding: '0 8px 4px',
                        }}
                    >
                        <Input
                            size='small'
                            placeholder={props.inputPlaceholder}
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                            
                        </Button>
                    </Space>
                </>
            )}
            options={props.items.map((item) => ({
                label: item,
                value: item,
            }))}
        />
    )
}

export default AntSelectWithAdd