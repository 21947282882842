import { Select } from 'antd'

import '../App.css'

function AntSelect(props) {

    let color = "#ffffff"
    if (props.selected === "WTS") {
        color = "#b7eb8f"
    } else if (props.selected === "WTB") {
        color = "#ffe58f"
    } else if (props.selected === "None") {
        color = "#bfbfbf"
    }

    const options = props.options.map((item) => {
        let option = null
        if (typeof item === 'string') {
            option = {
                label: item,
                value: item,
            }
        } else if (Array.isArray(item)) {
            option = {
                label: item[0],
                value: item[1],
            }
        } else {
            option = {
                label: item?.name ?? item?.full_name ?? '',
                value: item.id,
            }
        }
        /*
        option["background"] = "#ffffff"
        */
        return option
    })

    return (
        <Select
            mode={props.mode}
            size={props.size}
            disabled={props.disabled}
            allowClear={props.allowClear}
            showSearch={props.showSearch}
            placeholder={props.placeholder}
            bordered={false}
            style={{
                width: props.width,
                border: props.border === undefined ? "1px solid #d9d9d9" : props.border,
                borderRadius: "4px",
                backgroundColor: props.disabled ? "#F0EDE7" : "white",
                /*backgroundColor: color,*/ 
            }}
            onChange={props.onChange}
            //onSelect={props.onSelect}
            onSearch={props.onSearch}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            tagRender={props.tagRender}
            options={options}
            notFoundContent={props.notFoundContent}
            value={props.selected}
            listHeight={props.listHeight}
        >
            {/*{options.map((op) => (
                <Select.Option
                    key={op.value}
                    value={op.value}
                    style={{ background: op.background }}
                >
                    {op.label}
                </Select.Option>
            ))}*/}
        </Select>
    )
}

export default AntSelect
