import { Space, Tooltip, Button } from "antd"
import { DownloadOutlined, FileSyncOutlined, /*SyncOutlined*/} from '@ant-design/icons'

import '../App.css'

function DealsTableActions(props) {
    
    return (
        <Space size="small" style={{marginLeft: 'auto', paddingRight:'9px'}}>
            {props.contextHolder}
            <Tooltip
                placement="top"
                title={<span>Fetch New Deals</span>}
            >
                <Button
                    disabled={props.disabled}
                    className="btn-style"
                    onClick={props.onFetchNewDeals}
                    size="small"
                    icon={<DownloadOutlined />}
                />
            </Tooltip>
            <Tooltip
                placement="top"
                title={<span>Reload Data</span>}
            >
                <Button
                    disabled={props.disabled}
                    className="btn-style-red"
                    onClick={props.onPageRefresh}
                    size="small"
                    icon={<FileSyncOutlined />}
                />
            </Tooltip>
            {/*<Tooltip
                placement="bottom"
                title={<span>Sync with ZOHO</span>}
            >
                <Button
                    disabled={props.disabled}
                    className="btn-style-green"
                    onClick={props.onZohoSync}
                    size="small"
                    icon={<SyncOutlined />}
                />
            </Tooltip>*/}
        </Space>
    )
}

export default DealsTableActions