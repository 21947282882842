import { Modal } from "antd"


function SimilarDealsModal(props) {

    function handleOk() {
        props.setState(draft => {
            draft.modal.isOpen = false
            draft.fireExportReqCnt++
        })
    }

    function handleCancel() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
        props.setDataSource(draft => {
            draft.isLoading = false
        })
    }

    return (
        <Modal
            centered
            title={"Deals that have same Deal Type, Product and Vendor in last 24 hours"}
            open={props.state.modal.isOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
                {
                    props.state.data === null ? "" :
                    props.state.data.map((item, index) => (
                        <>
                            <p key={2*index}>{item['zoho_id']}</p>
                            <p key={2*index+1}>{item['name']}</p>
                        </>
                    ))
                }
        </Modal>
    )
}

export default SimilarDealsModal