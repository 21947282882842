import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import AppUnderConst from './AppUnderConst'
//import AppTest from './yedek/AppTest'
import reportWebVitals from './reportWebVitals'
import { IS_UNDER_CONST } from './Settings'

const root = ReactDOM.createRoot(document.getElementById('root'))
if (IS_UNDER_CONST) {
  root.render(
      <AppUnderConst />
  )
} else {
  root.render(
      <App />
  )
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

/*
<React.StrictMode>
  <App />
</React.StrictMode>
*/