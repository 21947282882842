import { useEffect, useContext } from 'react'
import { useImmer } from "use-immer"
import { Space, Tooltip, Spin } from "antd"
import { HeartOutlined, HeartFilled } from '@ant-design/icons'
import { splitArrToFields } from '../Utils'
import Axios from "axios"
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'


function ContactActions(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [actionData, setActionData] = useImmer({ isLoading: false, contactId: -1, actionType: '', requestCount: 0 })

    function haveUserDoneAction(arr, actionType, username) {
        let result = false
        arr.forEach(item => {
            if (item['action'] === actionType && item['custom_user__full_name'] === username) {
                result = true
                return
            }
        })
        return result
    }

    function updateTableData() {
        props.dataSource.messages.forEach(function(row, index) {
            if (row.vendor.id === props.record.vendor.id) {
                let vendor_actions = row.vendor_actions

                const checkUserAction = haveUserDoneAction(vendor_actions, actionData.actionType, appState.user.username)

                if (checkUserAction) {
                    vendor_actions = vendor_actions.filter(
                        item => !(item["custom_user__full_name"] === appState.user.username && item["action"] === actionData.actionType)
                    )
                } else {
                    let tempArr = [...vendor_actions]
                    tempArr.push({"custom_user__full_name": appState.user.username, "action": actionData.actionType})
                    vendor_actions = tempArr
                }

                props.setDataSource(draft => {
                    draft.messages[index].vendor_actions = vendor_actions
                })
            }
        })
    }

    useEffect(() => {
        if (actionData.requestCount === 0 || actionData.contactId === -1 || actionData.actionType === '') {
            return
        }

        const ourRequest = Axios.CancelToken.source()

        async function toggleContactAction() {
            setActionData(draft => {
                draft.isLoading = true
            })
            try {
                const response = await api.post(
                    'toggle_vendor_action/',
                    { 'vendor_id': actionData.contactId, 'action': actionData.actionType, },
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                //console.log(response.data)
                let message = "Action is successfull"
                if (response.data === 'FAVORITE created') {
                    message = "Contact is added to favorites"
                } else if (response.data === 'FAVORITE removed') {
                    message = "Contact is removed from favorites"
                }
                appDispatch({
                    type: "success",
                    data: message
                })
                updateTableData()
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to do action!"
                })
            } finally {
                setActionData(draft => {
                    draft.isLoading = false
                })
            }
        }
        toggleContactAction()
        return () => {
            ourRequest.cancel()
        }
    }, [actionData.requestCount])


    function onActionClick(actionType) {
        if (appState.user.role === "DATA MANAGER" && actionType === "FAVORITE")
            return
        setActionData(draft => {
            draft.contactId = props.record.vendor.id
            draft.actionType = actionType
            draft.requestCount++
        })
    }

    function heartStyle(hType) {
        let fontSize = '18px'
        if (hType === 'outlined')
            fontSize = '16px'
        let cursor = 'pointer'
        if (appState.user.role === "DATA MANAGER")
            cursor = 'default'
        return { fontSize: fontSize, color: 'red', cursor: cursor }
    }

    function getUsersFromActionType(arr, actionType) {
        let result = []
        arr.forEach(item => {
            if (item['action'] === actionType)
                result.push(item['custom_user__full_name'])
        })
        return result
    }

    function checkFavorite() {
        let checked = false
        let message = ""

        if (appState.user.role === "DATA MANAGER") {
            const vendor_actions = splitArrToFields(props.record.vendor_actions)
            if (vendor_actions === null || !vendor_actions.action.includes("FAVORITE")) {
                checked = false
		        message = "No favs"
            } else {
                checked = true
		        message = getUsersFromActionType(props.record.vendor_actions, "FAVORITE").join(', ')
            }
        } else {
            const checkUserAction = haveUserDoneAction(props.record.vendor_actions, "FAVORITE", appState.user.username)
            if (checkUserAction) {
                checked = true
                message = "Unfavorite"
            } else {
                checked = false
                message = "Favorite"
            }
        }

        if (checked) {
            return (
                <Tooltip
                    placement="right"
                    title={<span>{message}</span>}
                >
                    <HeartFilled
                        style={heartStyle('filled')}
                        onClick={() => onActionClick('FAVORITE')}
                    />
                </Tooltip>
            )
        }

        return (
            <Tooltip
                placement="right"
                title={<span>{message}</span>}
            >
                <HeartOutlined
                    style={heartStyle('outlined')}
                    onClick={() => onActionClick('FAVORITE')}
                />
            </Tooltip>
        )
    }

    if (actionData.isLoading) {
        return <Spin size="small" />
    }

    return (
        <Space size="small">
        {
            checkFavorite()
        }
    </Space>
    )
}

export default ContactActions