import { useEffect, useContext } from 'react'
import { useImmer } from "use-immer"
import { Tooltip, Input, Spin, Button, Space, Collapse } from 'antd'
import AntSelect from "./AntSelect"
import { UserOutlined, RetweetOutlined, SaveOutlined, GlobalOutlined, PhoneOutlined, MailOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { vendor_company_name, vendor_country_names_from_area_code } from '../Utils'
import { cropText, getLocalTime, isValidEmail, isItemInArrayIgnoreCase } from '../Utils'
import { NOT_AVAILABLE } from "../Constants"

import Axios from "axios"
import api from '../AxiosInstance'

import '../App.css'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

const { Panel } = Collapse


function VendorPanel(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const localIndex = props.messageData.localIndex
    const currMsgId = props.messageData.messageIndices[localIndex]
    const currMsgData = props.messageData.messageData[currMsgId]

    const [companyData, setCompanyData] = useImmer({company: {'name':'', 'country':null}, requestCount: 0})
    const [vendorReqData, setVendorReqData] = useImmer({isExport: false, requestCount: 0})

    useEffect(() => {
        if (vendorReqData.requestCount === 0) return
        const ourRequest = Axios.CancelToken.source()

        async function importVendorFromZOHO() {
            let isFailed = false
            let updateSuccessMsg = ""

            // company
            const company = currMsgData.vendor.company
            if (company !== null) {
                const new_company_data = {
                    'name': company.name,
                    'country': company.country !== null ? company.country.id : null
                }
                try {
                    const response = await api.patch(
                        `update_company/${company.id}/`,
                        new_company_data,
                        appState.token.post_config
                    )
                    //console.log(response.data)
                    updateSuccessMsg += 'Company, '
                } catch (err) {
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to update company!"
                    })
                    isFailed = true
                }
            }

            // vendor
            const vendor = currMsgData.vendor
            let new_vendor_data = {
                'full_name': vendor.full_name,
                'email': vendor.email,
                'company': vendor.company !== null ? vendor.company.id : null,
                'is_saved': true,
            }
            try {
                const response = await api.patch(
                    `update_vendor/${vendor.id}/`,
                    new_vendor_data,
                    appState.token.post_config
                )
                //console.log(response.data)
                props.setFilterOptions(draft => {
                    draft.vendors.requestCount++
                })
                updateSuccessMsg += 'Vendor, '
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to update vendor!"
                })
                isFailed = true
            }
            
            if (updateSuccessMsg.length > 0) {
                appDispatch({
                    type: "success",
                    data: updateSuccessMsg.substring(0, updateSuccessMsg.length-2) + ' updated'
                })
            }
            
            if (vendorReqData.isExport) {
                props.setVendorData(draft => {
                    draft.vendor = vendor
                    draft.key = -1
                    draft.isFireExport = false
                    draft.requestCount++
                })
            } else {
                props.setFilterOptions(draft => {
                    draft.requestCount++
                })
            }
        }
        importVendorFromZOHO()

        return () => {
            ourRequest.cancel()
        }
    }, [vendorReqData.requestCount])

    function vendorNameBorderColor(vendor) {
        if (vendor === null || vendor.full_name === "" || vendor.full_name === NOT_AVAILABLE)
            return "#ff7875"
        return ""
    }

    function vendorEmailBorderColor(vendor) {
        if (vendor === null || !isValidEmail(vendor.email))
            return "#ff7875"
        return ""
    }

    function vendorCompanyBorder(vendor) {
        if (vendor === null || vendor.company === null)
            return "1px solid #ff7875"
        return "1px solid #d9d9d9"
    }

    function companySelected(vendor) {
        let selected = NOT_AVAILABLE
        if (vendor !== null && vendor.company !== null ) {
            selected = vendor.company.id
        }
        return selected
    }

    function addNewCompany() {
        const company_name = companyData.company.name
        if (company_name === null || company_name === "" || isItemInArrayIgnoreCase(props.filterOptions.companies.data, company_name)) {
            setCompanyData(draft => {
                draft.company.name = new Date().getTime().toString().slice(-6)
                draft.requestCount++
            })
        } else {
            setCompanyData(draft => {
                draft.requestCount++
            })
        }
    }

    function countrySelected(vendor) {
        let selected = NOT_AVAILABLE
        if (vendor !== null && vendor.company !== null && vendor.company.country != null) {
            selected = vendor.company.country.id
        }
        return selected
    }

    function regionSelected(vendor) {
        let selected = NOT_AVAILABLE
        if (vendor !== null && vendor.company !== null && vendor.company.country != null) {
            selected = vendor.company.country.region.id
        }
        return selected
    }

    if (props.dataSource.isLoading) return (
        <div style={{display: 'flex', alignItems:'center', justifyContent:'center', width: '400px', height:'300px'}}>
            <Spin />
        </div>
    )

    if (currMsgData === undefined || currMsgData === null) {
        return (
            <div></div>
        )
    }

    return (
        <Collapse
            size="small"
            bordered={false}
            defaultActiveKey={['1']}
            style={{width: '400px', fontSize: "13px", marginLeft:'0px', marginTop:"10px", backgroundColor:'#FFEFA5'}}
        >
            <Panel header="Contact Details" key="1">
                <Space direction="vertical" size="small" style={{paddingLeft:'2px', paddingTop:'5px'}}>
                    <Space direction="horizontal" size="small">
                        <strong style={{fontSize:'12px'}}>Contact Infos</strong>
                    </Space>
                    <div>
                        <Input prefix={<Tooltip title="Vendor Name"><UserOutlined className='site-form-item-icon' /></Tooltip>} placeholder='Name'
                                disabled={currMsgData.vendor === null}
                                value={currMsgData.vendor?.full_name ?? ''}
                                //onPressEnter={(e) => {props.updateField('vendor_name', props.record.key, e)}}
                                //onBlur={(e) => {props.updateField('vendor_name', props.record.key, e)}}
                                onChange={(e) => {props.updateField('vendor_name', currMsgId, e)}}
                                size='small' style={{width:'310px', borderColor:vendorNameBorderColor(currMsgData.vendor)}}/>
                        {currMsgData.vendor === null ? '' :
                        <>
                            <Tooltip title="Import/Export Vendor from/to ZOHO" placement="bottom">
                                <Button className='message-panel-button btn-style' size='small' icon={<RetweetOutlined />}
                                        onClick={(e) => {
                                            setVendorReqData(draft => {
                                                draft.isExport = true
                                                draft.requestCount++
                                            })
                                        }} />
                            </Tooltip>
                            <Tooltip
                                placement="bottom"
                                title={<span>Save Vendor</span>}
                            >
                                <Button
                                    className='btn-style-red'
                                    style={{marginLeft:'6px'}}
                                    onClick={(e) => {
                                        setVendorReqData(draft => {
                                            draft.isExport = false
                                            draft.requestCount++
                                        })
                                    }}
                                    size="small"
                                    icon={<SaveOutlined />}
                                />
                            </Tooltip>
                        </>
                        }
                    </div>
                    <Space direction="horizontal" size="small">
                        <Tooltip title={vendor_country_names_from_area_code(currMsgData.vendor)} placement="bottom">
                            <GlobalOutlined
                                style={{color:"#1677FF", fontSize: "18px"}}
                                //size='small'
                            />
                        </Tooltip>
                        <Input prefix={<Tooltip title="Vendor Phone"><PhoneOutlined className='site-form-item-icon' /></Tooltip>} placeholder='Phone'
                                disabled={true}
                                defaultValue={currMsgData.vendor?.phone_number ?? ''}
                                //onPressEnter={(e) => {props.updateField('vendor_phone', props.record.key, e)}}
                                //onBlur={(e) => {props.updateField('vendor_phone', props.record.key, e)}}
                                size='small' style={{width:'344px', }}/>
                    </Space>
                    <Input prefix={<Tooltip title="Vendor Email"><MailOutlined className='site-form-item-icon' /></Tooltip>} placeholder='Email'
                            disabled={currMsgData.vendor === null}
                            defaultValue={currMsgData.vendor?.email ?? ''}
                            onPressEnter={(e) => {props.updateField('vendor_email', currMsgId, e)}}
                            onBlur={(e) => {props.updateField('vendor_email', currMsgId, e)}}
                            size='small' style={{width:'370px', borderColor:vendorEmailBorderColor(currMsgData.vendor)}}/>
                    <small />
                    <strong style={{fontSize:'12px'}}>Company Infos</strong>
                    <div>
                        <AntSelect
                                size="small"
                                disabled={currMsgData.vendor === null || props.filterOptions.companies.data.length === 0}
                                showSearch={true}
                                selected={companySelected(currMsgData.vendor)}
                                options={props.filterOptions.companies.data}
                                onSearch={(newValue) => {
                                    if (newValue.length >= 6) {
                                        setCompanyData(draft => {
                                            draft.company.name = newValue
                                        })
                                    }
                                }}
                                onChange={(newValue) => { 
                                    props.updateField('vendor_company', currMsgId, newValue)
                                }}
                                width={340}
                                border={vendorCompanyBorder(currMsgData.vendor)}/>
                        {currMsgData.vendor === null ? '' :
                        <Tooltip title="Add New Company" placement="bottom">
                            <Button className='message-panel-button btn-style-red' size='small' icon={<PlusCircleOutlined />}
                                    onClick={(e) => {
                                        addNewCompany()
                                    }} />
                        </Tooltip>
                        }
                    </div>
                    <Input /*prefix={<Tooltip title="Company Name"><ContactsOutlined className='site-form-item-icon' /></Tooltip>}*/ placeholder='Name'
                            //value={props.record.vendor.company === null ? '' : props.record.vendor.company?.name ?? ''}
                            value={vendor_company_name(currMsgData.vendor)}
                            disabled={currMsgData.vendor === null || currMsgData.vendor.company === null}
                            onChange={(e) => {
                                //props.updateField('company_name', props.record.key, e)
                            }}
                            size='small' style={{width:'370px', }}/>
                    <AntSelect
                            size="small"
                            disabled={props.filterOptions.countries.data.length === 0 ||
                                (currMsgData.vendor === null || currMsgData.vendor.company === null)}
                            showSearch={true}
                            selected={countrySelected(currMsgData.vendor)}
                            options={props.filterOptions.countries.data}
                            onChange={(newValue) => {
                                props.updateField('company_country', currMsgId, newValue)
                            }}
                            width={370}/>
                    <AntSelect
                            size="small"
                            //disabled={props.filterOptions.regions.data.length === 0}
                            disabled={true}
                            showSearch={true}
                            selected={regionSelected(currMsgData.vendor)}
                            options={props.filterOptions.regions.data}
                            /*onChange={(newValue) => {
                                props.updateField('company_region', props.record.key, newValue)
                            }}*/
                            width={370}/>
                    <small />
                </Space>
            </Panel>
        </Collapse>
    )
}

export default VendorPanel