import { useEffect, useContext } from 'react'
import { useImmer } from "use-immer"
import { Modal, Button } from "antd"
import Axios from "axios"
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'


function KvkkModal(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [signData, setSignData] = useImmer({requestCount:0, })

    useEffect(() => {
        if (signData.requestCount == 0) return

        const ourRequest = Axios.CancelToken.source()

        async function user() {
            try {
                const response = await api.post('sign_kvkk/', { 'sign':'signed' }, appState.token.post_config, { cancelToken: ourRequest.token })
                //console.log(response.data)
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to sign!"
                })
            }
        }
        user()
        return () => {
            ourRequest.cancel()
        }
    }, [signData.requestCount])


    function handleOk() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
        setSignData(draft => {
            draft.requestCount++
        })
        setTimeout(() => {
            window.location.reload(true)
        }, 1000)
    }

    return (
        <Modal
            centered
            title=""
            open={props.state.modal.isOpen}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    Onayla
                </Button>,
                ]}
            >
                <span>
                    {"Devin Tech Inc, şirket tarafından sağlanan mobil telefon hattı üzerinden yapılan WhatsApp iletişimini kayıt altına alma hakkına sahiptir. "}
                    {"Bu kayıtlar, şirketin gelişimine katkı sağlamak amacıyla analiz edilecektir. "}
                    {"Analiz sonuçları, şirket içi işleyişlerin daha etkili bir şekilde yönetilmesine ve karar destek sistemleri oluşturulmasına yardımcı olmak üzere kullanılacaktır. "}
                    {"Kaydedilen verilen arasında şirket hatları arasında yapılan çalışan-çalışan görüşmeleri bulunmamaktadır. "}
                    {"Bu bilgileri okudum ve anladım. "}
                    {"Şirket tarafından sağlanan mobil telefon hattım üzerinden yapılan WhatsApp mesajlarının kayıt altına alınması ve analiz edilmesi konusunda onay veriyorum. "}
                </span>
        </Modal>
    )
}

export default KvkkModal
