import { useEffect, useContext, useState, useRef } from 'react'
import { useImmer } from "use-immer"
import { Alert, Input, Button, Row, Col, Spin } from 'antd'
import { sleep } from '../Utils'
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'


function VerificationCodeInput() {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', ''])
    const inputRefs = useRef([])

    const [seconds, setSeconds] = useState(120)

    const [showError, setShowError] = useImmer({isShow:false, message:""})
    const [loginData, setLoginData] = useImmer({
        email:null,
        code:"",
        requestCount:0,
        isLoading: false,
    })

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(prevSeconds => prevSeconds - 1)
            } else {
                clearInterval(interval)
            }
        }, 1000)
    
        return () => clearInterval(interval)
    }, [seconds])

    useEffect(() => {
        if (loginData.requestCount === 0) return

        async function login() {
            const email = loginData.email
            const code = loginData.code
            try {
                //const response = await Axios.post('token/login/', { email, code })
                const response = await api.post('token/login_code/', { email, code })
                //console.log(response.data)
                appDispatch({
                    type: "login",
                    data: response.data.auth_token,
                })
            } catch (err) {
                setLoginData(draft => {
                    draft.isLoading = false
                })
                //console.log(err)
                /*appDispatch({
                    type: "error",
                    data: "Unable to login!"
                })*/
                if (err.response.data.error === "Expired code") {
                    setShowError(draft => {
                        draft.isShow = true
                        draft.message = "Code is expired!"
                    })
                    await sleep(3000)
                    window.location.reload(true)
                } else {
                    setShowError(draft =>{
                        draft.isShow = true
                        draft.message = "Code is incorrect!"
                    })
                }
            }
            setLoginData(draft => {
                draft.isLoading = false
            })
        }
        login()
    }, [loginData.requestCount])

    function handleChange(index, e) {
        const value = e.target.value
        // Only allow digits
        //if (/^\d$/.test(value)) {
        const newVerificationCode = [...verificationCode]
        newVerificationCode[index] = value
        setVerificationCode(newVerificationCode)
        // Move to next input automatically
        if (index < 5 && value !== '') {
            inputRefs.current[index + 1].focus()
        }
        //}
    }

    function handlePaste(e) {
        e.preventDefault()
        const pastedData = e.clipboardData.getData('Text')
    
        // Split pasted data into individual characters
        const pastedCodes = pastedData.split('').slice(0, 6)
    
        // Fill inputs accordingly
        const newCode = [...verificationCode]
        pastedCodes.forEach((char, index) => {
            newCode[index] = char
        })
    
        setVerificationCode(newCode)
    }

    function handleVerify() {
        // Assuming you have a function to verify the code here
        const code = verificationCode.join('')
        //console.log('Verifying code:', code)
        if (code === "") return
        setLoginData(draft => {
            draft.email = localStorage.getItem("dc_email")
            draft.code = code
            draft.isLoading = true
            draft.requestCount++
        })
    }

    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60

    if (loginData.isLoading) return (
        <div style={{display: 'flex', alignItems:'center', justifyContent:'center', height:'100vh'}}>
            <Spin size='large' />
        </div>
    )

    return (
        <>
            {showError.isShow && <div style={{display: 'block', width: '100%', textAlign: 'center'}}>
                <Alert message={showError.message} type="error" />
            </div> }
            {showError.message !== "Code is expired!" && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
                <span>Please enter the verification code sent to your email</span>
                <p style={{ marginTop: '20px' }}>
                    Time Remaining: {minutes < 10 ? '0' + minutes : minutes}:{remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds}
                </p>
                <Row gutter={[8, 0]} style={{ marginTop: '20px' }}>
                {verificationCode.map((digit, index) => (
                    <Col key={index}>
                    <Input
                        ref={(el) => (inputRefs.current[index] = el)}
                        value={digit}
                        onChange={(e) => handleChange(index, e)}
                        maxLength={1}
                        onPaste={handlePaste}
                        style={{ width: '60px', fontSize: '2rem', textAlign: 'center', appearance: 'textfield' }}
                    />
                    </Col>
                ))}
                </Row>
                <div style={{ marginTop: '20px' }}>
                    <Button type="primary" onClick={handleVerify} style={{ width: '100px' }}>Verify</Button>
                </div>
            </div>}
        </>
    )
}

export default VerificationCodeInput
