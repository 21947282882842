import { Input, DatePicker } from "antd"
import DealsTableActions from "./DealsTableActions"
import '../App.css'

const { Search } = Input
const { RangePicker } = DatePicker

function SearchBar(props) {

    function fetchDeals() {
        if (props.dataSource.pagination.current === 1) {
            props.setFilterOptions(draft => {
                draft.resetRowSpan = true
                draft.requestCount++
            })
        } else {
            props.setDataSource(draft => {
                draft.resetRowSpan = true
                draft.pagination.current = 1
            })
        }
    }

    function searchTable(value) {
        /*props.setFilterOptions(draft => {
            draft.searchText = value
        })*/
        fetchDeals()
    }

    function onSearchChange(value) {
        props.setFilterOptions(draft => {
            draft.searchText = value
        })
    }

    function onRangeChange(value) {
        if (value === null) {
            props.setFilterOptions(draft => {
                draft.startDate = null
                draft.endDate = null
            })
            fetchDeals()
        }
    }

    function onRangeSelected(value) {
        if (value[0] === null || value[1] === null) return
        const startDate = new Date(value[0]['$d']).toUTCString()
        const endDate = new Date(value[1]['$d']).toUTCString()
        //console.log(startDate)
        props.setFilterOptions(draft => {
            draft.startDate = startDate
            draft.endDate = endDate
        })
        fetchDeals()
    }

    function fetchNewDeals() {
        props.setDataSource(draft => {
            draft.queryDateTime = new Date().toUTCString()
        })
        fetchDeals()
    }

    function refreshPage() {
        props.setFilterOptions(draft => {
            draft.resetRowSpan = true
            draft.productModels.requestCount++
            draft.brands.requestCount++
            draft.categories.requestCount++
            draft.vendors.requestCount++
            draft.companies.requestCount++
            draft.countries.requestCount++
            draft.regions.requestCount++
            draft.dealers.requestCount++
            draft.statuses.requestCount++
            draft.requestCount++
        })
    }

    function syncWithZoho() {
        console.log('ZOHO')
        refreshPage()
    }

    return (
        <div className='search-bar'>
            <Search
                disabled={props.disabled}
                placeholder="UPC / PN / Product Name Search"
                allowClear
                onChange={(e) => onSearchChange(e.target.value)}
                onSearch={searchTable}
                style={{
                    width: 328,
                }}/>
            <RangePicker
                disabled={props.disabled}
                showTime
                onOk={onRangeSelected}
                onChange={onRangeChange}/>
            <strong>
                {`${props.dataSource.pagination.total} deals from ${props.dataSource.message_count} messages from ${props.dataSource.vendor_count} contacts found`}
            </strong>
            <DealsTableActions
                disabled={props.disabled}
                onPageRefresh={() => refreshPage()}
                onZohoSync={() => syncWithZoho()}
                onFetchNewDeals={() => fetchNewDeals()}/>
        </div>
    )
}

export default SearchBar