import { useContext, useEffect } from 'react'
import { useImmer } from "use-immer"
import Axios from "axios"
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'


function Test(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [msgData, setMsgData] = useImmer("")

    useEffect(() => {
        async function testCache() {
            try {
                const response = await api.post(
                    'create_message/',
                    { 'from_number': "+905300419038", },
                    appState.token.post_config
                )
                setMsgData(response.data)
            } catch (err) {
                console.log(err)
                setMsgData(err.response.data)
            }
        }
        testCache()
    }, [])

    return (
        <p>{msgData}</p>
    )
}

export default Test