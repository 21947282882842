
function AppUnderConst() {

    const styles = {
        container: {
            fontFamily: 'Arial, sans-serif',
            backgroundColor: '#f4f4f4',
            margin: 0,
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '125vh',
            textAlign: 'center',
        },
        image: {
            maxWidth: '100%',
            height: 'auto',
        },
        title: {
            color: '#333',
        },
        text: {
            color: '#555',
        },
    }
    
    return (
        <div style={styles.container}>
            <img src="coding.png" alt="Under Construction" style={styles.image} />
            <h1 style={styles.title}>Under Construction</h1>
            <p style={styles.text}>We're working on something exciting. Please check back later!</p>
        </div>
    )
}

export default AppUnderConst