import { Modal, Table, Button, Tooltip } from "antd"
import { CaretDownOutlined } from '@ant-design/icons'

import '../App.css'


function SearchProductResultsModal(props) {

    function selectProduct(record) {
        props.setProduct(record)
        props.setState(draft => {
            draft.modal.isOpen = false
        })
        props.setLoading(false)
    }
      
    const columns = [
        {
            title: '',
            render: (_, record) => {
                return (
                    <Tooltip title={'Select Product'} placement="bottom">
                        <Button className='message-panel-button btn-style-green' size='small' icon={<CaretDownOutlined />}
                                onClick={(e) => {
                                    selectProduct(record)
                                }} />
                    </Tooltip>
                )
            }
        },
        /*{
            title: 'ZOHO Id',
            dataIndex: 'zoho_id',
            key: 'zoho_id',
        },*/
        {
            title: 'UPC',
            dataIndex: 'UPC',
            key: 'UPC',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Spec',
            dataIndex: 'nation',
            key: 'nation',
        },
        {
            title: 'PN',
            dataIndex: 'part_number',
            key: 'part_number',
        },
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
        },
    ]

    function handleOk() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
        props.setLoading(false)
    }

    function handleCancel() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
        props.setLoading(false)
    }

    return (
        <Modal
            centered
            title={"Product Search Results"}
            open={props.state.modal.isOpen}
            footer={[]}
            onOk={handleOk}
            onCancel={handleCancel}
            width={'1500px'}
            >
                {
                    <Table dataSource={props.state.data} columns={columns} />
                }
        </Modal>
    )
}

export default SearchProductResultsModal