import { useEffect, useContext } from 'react'
import { useImmer } from "use-immer"

import { Input, Modal, Space } from "antd"
import AntSelect from "./AntSelect"

import { NOT_AVAILABLE } from '../Constants'

import Axios from "axios"
import api from '../AxiosInstance'

import '../App.css'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'


function AddProductModelModal(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [newProductModelState, setNewProductModelState] = useImmer({name: '', brand: null, category: null, requestCount: 0})

    useEffect(() => {
        if (newProductModelState.requestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function createProductModel() {
            const newObj = { ...newProductModelState }
            delete newObj['requestCount']
            try {
                const response = await api.post(
                    'list_product_models/',
                    newObj,
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                appDispatch({
                    type: "success",
                    data: "New product model created"
                })
                props.setFilterOptions(draft => {
                    draft.productModels.requestCount++
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to create product model!"
                })
            }
        }
        createProductModel()

        return () => {
            ourRequest.cancel()
        }
    }, [newProductModelState.requestCount])

    function handleOk() {
        if (newProductModelState.name === '' || newProductModelState.name === NOT_AVAILABLE) {
            appDispatch({
                type: "info",
                data: "Model name can not be empty!"
            })
        } else if (newProductModelState.brand === null) {
            appDispatch({
                type: "info",
                data: "Brand must be selected!"
            })
        } else if (newProductModelState.category === null) {
            appDispatch({
                type: "info",
                data: "Category must be selected!"
            })
        } else if (props.productModels.some(obj => obj.name.toLowerCase().replace(/\s/g, '') === newProductModelState.name.toLowerCase().replace(/\s/g, ''))) {
            appDispatch({
                type: "info",
                data: "Model name already exists!"
            })
        } else {
            setNewProductModelState(draft => {
                draft.requestCount++
            })
            props.setState(draft => {
                draft.modal.isOpen = false
            })
        }
    }

    function handleCancel() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
    }
    
    return (
        <Modal
            centered
            title={"Create New Product Model"}
            open={props.state.modal.isOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
            <Space direction="vertical" size="small">
                <Input
                    placeholder='Model Name'
                    value={newProductModelState.name}
                    onChange={(e) => {
                        setNewProductModelState(draft => {
                            draft.name = e.target.value
                        })
                    }}
                    size='small' style={{width:'210px', }}/>
                <Space direction="horizontal" size="small">
                    <AntSelect
                        placeholder='Select Brand'
                        size="small"
                        disabled={props.brands.length === 0}
                        showSearch={true}
                        options={props.brands}
                        onChange={(newValue) => {
                            setNewProductModelState(draft => {
                                draft.brand = newValue
                            })
                        }}
                        width={210}/>
                    <AntSelect
                        placeholder='Select Category'
                        size="small"
                        disabled={props.categories.length === 0}
                        showSearch={true}
                        options={props.categories}
                        onChange={(newValue) => {
                            setNewProductModelState(draft => {
                                draft.category = newValue
                            })
                        }}
                        width={210}/>
                </Space>
            </Space>
        </Modal>
    )
}

export default AddProductModelModal