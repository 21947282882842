import { useEffect, useContext, useState } from 'react'
import { useImmer } from "use-immer"
import { Upload, Button, Space, List, Pagination, Tooltip, Spin, message } from 'antd'
import { UploadOutlined, FileExcelOutlined, CloseCircleOutlined, RedoOutlined } from '@ant-design/icons'
import SideMenu from './SideMenu'
import { getFileNameAfterLastSlash, cropText, sleep } from '../Utils'

import Axios from "axios"
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'


var fileDownload = require('js-file-download')


function FileUploadScreen(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [fileData, setFileData] = useImmer({
        body: null,
        requestCount: 0,
    })
    const [fileList, setFileList] = useState([])

    const [fileListData, setFileListData] = useImmer({
        files: [],
        requestCount: 0,
        currentPage: 1,
        pageSize: 5,
        isLoading: false,
    })

    const [downloadFileData, setDownloadFileData] = useImmer({
        id: null,
        fname: "",
        requestCount: 0,
    })

    const [parseFileData, setParseFileData] = useImmer({
        id: null,
        requestCount: 0,
    })

    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function listFiles() {
            if (!appState.loggedIn) return

            setFileListData(draft => {
                draft.isLoading = true
            })

            try {
                const response = await api.get('list_uploaded_files/', appState.token.get_config, { cancelToken: ourRequest.token })
                //console.log(response)
                setFileListData(draft => {
                    draft.files = response.data
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to list uploaded files!"
                })
            }

            setFileListData(draft => {
                draft.isLoading = false
            })
        }
        listFiles()
        return () => {
            ourRequest.cancel()
        }
    }, [fileListData.requestCount])


    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function downloadFile() {
            if (!appState.loggedIn || downloadFileData.requestCount === 0 || downloadFileData.id === null) return

            try {
                const response = await api.get(
                    `download_file/?file_id=${downloadFileData.id}`, appState.token.get_file_config, { cancelToken: ourRequest.token })
                //console.log(response)
                fileDownload(response.data, downloadFileData.fname)
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to download file!"
                })
            }
        }
        downloadFile()
        return () => {
            ourRequest.cancel()
        }
    }, [downloadFileData.requestCount])


    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function uploadFile() {
            if (!appState.loggedIn || fileData.requestCount === 0 || fileData.body === null) return

            let fileStatus = "uploading"

            try {
                const response = await api.post('upload/', fileData.body, appState.token.post_file_config, { cancelToken: ourRequest.token })
                //console.log(response)
                fileStatus = "done"
                appDispatch({
                    type: "success",
                    data: "File is uploaded successfully"
                })
                setFileListData(draft => {
                    draft.requestCount++
                })
            } catch (err) {
                //console.log(err)
                fileStatus = "error"
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to upload file!"
                })
            } finally {
                let newFileList = [...fileList]
                newFileList[0].status = fileStatus
                setFileList(newFileList)
            }
        }
        uploadFile()
        return () => {
            ourRequest.cancel()
        }
    }, [fileData.requestCount])

    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function parseFile() {
            if (!appState.loggedIn || parseFileData.requestCount === 0 || parseFileData.id === null) return

            try {
                const response = await api.get(
                    `parse_file/?file_id=${parseFileData.id}`, appState.token.get_config, { cancelToken: ourRequest.token })
                //console.log(response)
                /*appDispatch({
                    type: "success",
                    data: "File is parsed successfully"
                })*/
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to parse file!"
                })
            }
            await sleep(1000)
            setFileListData(draft => {
                draft.requestCount++
            })
        }
        parseFile()
        return () => {
            ourRequest.cancel()
        }
    }, [parseFileData.requestCount])


    function onChange(info) {
        let newFileList = [...info.fileList]
        newFileList = newFileList.slice(-1)
        setFileList(newFileList)
    }


    function beforeUpload(file) {
        // Check file type before upload
        const fileType = file.type
        if (fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            message.error('You can only upload .xlsx file!')
            return false
        }
        return true
    }


    function handleUpload(info) {
        const { file } = info
        const formData = new FormData()
        formData.append('file_field', file)
        
        setFileData(draft => {
            draft.body = formData
            draft.requestCount++
        })
    }

    const onChangePage = (page, pageSize) => {
        setFileListData(draft => {
            draft.currentPage = page
        })
    }

    function downloadFileOnClick(item) {
        setDownloadFileData(draft => {
            draft.id = item.id
            draft.fname = getFileNameAfterLastSlash(item.file_field)
            draft.requestCount++
        })
    }

    function parseFileOnClick(item) {
        setParseFileData(draft => {
            draft.id = item.id
            draft.requestCount++
        })
    }

    function refreshOnClick() {
        setFileListData(draft => {
            draft.requestCount++
        })
    }

    function showIcon(item) {
        switch(item.status) {
            case "UPLOADED":
                return (
                    <Tooltip title="Parse" placement="right">
                        <Button className='btn-style' size='small' icon={<FileExcelOutlined />}
                            onClick={() => {
                                parseFileOnClick(item)
                            }} />
                    </Tooltip>
                )
            case "PARSING":
                return <Spin />
            case "PARSED":
                return (
                    <p>{`${item.num_records} records added`}</p>
                )
            case "FAILED":
                return (
                    <Space direction='horizontal'>
                        <Tooltip title={item.fail_reason} placement="right">
                            <CloseCircleOutlined style={{color: 'red'}} />
                        </Tooltip>
                        <Tooltip title="Retry" placement="right">
                            <Button className='btn-style' size='small' icon={<FileExcelOutlined />}
                                onClick={() => {
                                    parseFileOnClick(item)
                                }} />
                        </Tooltip>
                    </Space>
                )
        }
    }

    return (
        <div className='App'>
            <SideMenu connected={props.connected}/>
            {fileListData.isLoading ? <Spin size="large" /> :
            <Space size="small" direction='vertical' style={{paddingLeft: 15, paddingTop: 25}}>
                <div style={{paddingLeft:'24px', display:'flex'}}>
                    <div>
                        <Upload accept=".xlsx" beforeUpload={beforeUpload} onChange={onChange} customRequest={handleUpload} fileList={fileList}>
                            <Button icon={<UploadOutlined />}>Upload Excel File</Button>
                        </Upload>
                    </div>
                    <div style={{marginLeft: 'auto', paddingTop:'5px', paddingRight:'15px'}}>
                        <span className='link-btn-style' onClick={() => refreshOnClick()}>{"Refresh"}<RedoOutlined /></span>
                    </div>
                </div>
                <div style={{paddingTop:'15px', width:'800px'}}>
                    <List
                        itemLayout="horizontal"
                        dataSource={fileListData.files.slice((fileListData.currentPage - 1) * fileListData.pageSize, fileListData.currentPage * fileListData.pageSize)}
                        renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={<span className='link-btn-style' onClick={() => downloadFileOnClick(item)}>{cropText(getFileNameAfterLastSlash(item.file_field), 75)}</span>}
                            />
                            <div style={{paddingRight:'7px', paddingTop:'7px'}}>
                                {
                                    showIcon(item)
                                }
                            </div>
                        </List.Item>
                        )}
                    />
                    <Pagination
                        style={{float:'right', paddingTop:'25px'}}
                        current={fileListData.currentPage}
                        pageSize={fileListData.pageSize}
                        total={fileListData.files.length}
                        onChange={onChangePage}
                        showSizeChanger={false} // Disable changing page size
                    />
                </div>
            </Space>}
        </div>
    )
}

export default FileUploadScreen
