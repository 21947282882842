import { useEffect, useContext } from 'react'
import { useImmer } from "use-immer"
import { Tooltip, Input, Spin, List, Space, Collapse } from 'antd'
import { HistoryOutlined } from '@ant-design/icons'
import { cropText, getLocalTime } from '../Utils'

import Axios from "axios"
import api from '../AxiosInstance'

import '../App.css'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

const { TextArea } = Input
const { Panel } = Collapse


function DealerPanel(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const localIndex = props.messageData.localIndex
    const currMsgId = props.messageData.messageIndices[localIndex]

    const [panelData, setPanelData] = useImmer({
        dealersInfo: [],
        dealers: "",
        isLoading: false,
    })

    useEffect(() => {
        if (currMsgId === undefined || currMsgId === null) return
        const ourRequest = Axios.CancelToken.source()

        setPanelData(draft => {
            draft.isLoading = true
        })

        async function fetchDealersInfo() {
            try {
                const response = await api.get(
                    `get_dealers_info_by_message_id/${currMsgId}/`, appState.token.get_config, { cancelToken: ourRequest.token })
                const dealersInfo = response.data.map((row) => {
                    //const msg_time = new Date(Date.parse(row["message_dealer_time"])).toLocaleString()
                    const msg_time = getLocalTime(row["message_dealer_time"], false)
                    const chat_name = cropText(row["chat_name"], 20)
                    const full_name = cropText(row["custom_user"]["full_name"], 30)
                    const quoted_content = cropText(row["quoted_content"], 50)
                    return [[msg_time, full_name, chat_name, quoted_content].join(' | '), row["chat_name"]]
                })
                if (dealersInfo === null || dealersInfo.length === 0) dealersInfo = ['-']
                setPanelData(draft => {
                    draft.dealersInfo = dealersInfo
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to get message infos!"
                })
            }
        }
        fetchDealersInfo()

        async function fetchDealers() {
            try {
                const response = await api.get(
                    `get_dealers_by_message_id/${currMsgId}/`, appState.token.get_config, { cancelToken: ourRequest.token })
                let dealers = response.data.map((row) => {
                    return row["full_name"]
                })
                dealers = ((dealers === null) ? "" : dealers.join(', '))
                setPanelData(draft => {
                    draft.dealers = dealers
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to get dealers!"
                })
            }
        }
        fetchDealers()

        setPanelData(draft => {
            draft.isLoading = false
        })

        return () => {
            ourRequest.cancel()
        }
    }, [props.messageData, props.filterOptions.requestCount])

    function dealers() {
        return (
            <div className='other-deal-info' style={{marginTop:'4px'}}>
                <strong style={{fontSize:'12px', }}>Dealers:</strong>
                <TextArea
                    //disabled
                    readOnly
                    rows={2}
                    value={panelData.dealers}
                    style={{border: "1px solid lightgray", fontSize:'12px', /*width:'498px',*/ resize:'none', paddingLeft:'10px', marginRight:'2px'}}
                />
            </div>
        )
    }

    if (panelData.isLoading || props.dataSource.isLoading) return (
        <div style={{display: 'flex', alignItems:'center', justifyContent:'center', width: '400px', height:'300px'}}>
            <Spin />
        </div>
    )

    if (currMsgId === undefined || currMsgId === null) {
        return (
            <div></div>
        )
    }

    return (
        <Collapse
            size="small"
            bordered={false}
            defaultActiveKey={['1']}
            style={{width: '400px', fontSize: "13px", marginLeft:'0px', marginTop:"10px", backgroundColor:'#FFEFA5'}}
        >
            <Panel header="Message History" key="1">
                <Space direction="vertical" size="small">
                    <div style={{borderStyle:'solid', borderRadius: 6, borderWidth:1, borderColor:'lightgray', height:'230px', marginRight:'2px', width: '371px', overflowY: 'auto'}}>
                        <List
                            style={{borderRadius: 6, border:0, width:'100%', minHeight: 229, fontSize:'12px', backgroundColor:'white'}}
                            size="small"
                            header={<div><HistoryOutlined />{" "}<strong>Msg Time | Dealer | Chat Name | Quoted Msg</strong></div>}
                            bordered
                            dataSource={panelData.dealersInfo}
                            renderItem={(item) => <Tooltip title={item[1]}><List.Item style={{fontSize:'12px', }}>{item[0]}</List.Item></Tooltip>}/>
                    </div>
                    {appState.user.isstaff && dealers()}
                </Space>
            </Panel>
        </Collapse>
    )
}

export default DealerPanel
