import { useEffect, useContext } from 'react'
import { NavLink } from "react-router-dom"
import { useImmer } from "use-immer"
import { Table, Spin, Tooltip, message } from "antd"
import SideMenu from './SideMenu'
import Login from './Login'
import { getLocalTime } from '../Utils'

import Axios from "axios"
import api from '../AxiosInstance'

import '../App.css'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'


function ChatLinksTable(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [messageApi, messageContextHolder] = message.useMessage()

    const [dataSource, setDataSource] = useImmer({
        chatLinks: [],
        otherData: [],
        pagination: {current:1, pageSize:20, total:0, showSizeChanger: true, showQuickJumper: true, /*pageSizeOptions: ['10', '20'], position: ["topRight", ], */},
        isLoading: true,
    })

    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchChatLinks() {
            if (!appState.loggedIn) return

            setDataSource(draft => {
                draft.isLoading = true
            })

            try {
                let url = 'list_chatlinks/'
                url += `?page_size=${dataSource.pagination.pageSize}`
                url += `&page=${dataSource.pagination.current}`

                const response = await api.get(url, appState.token.get_config, { cancelToken: ourRequest.token })
                const chatLinksWithKey = response.data["results"].map((row) => {
                    //console.log(row)
                    return {
                        'url': row['url'],
                        'key': row['id'], /* rowKey="id" not working */
                    }
                })
                const otherDataWithKey = response.data["results"].map((row) => {
                    //console.log(row)
                    return {
                        'from_number': row['from_number'],
                        'message_time': row['message_time'],
                        'message_id': row['message_id'],
                        'key': row['id'], /* rowKey="id" not working */
                    }
                })

                setDataSource(draft => {
                    draft.chatLinks = chatLinksWithKey
                    draft.otherData = otherDataWithKey
                    draft.pagination.total = response.data["count"]
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch chatlinks!"
                })
            }

            setDataSource(draft => {
                draft.isLoading = false
            })
        }
        fetchChatLinks()
        return () => {
            ourRequest.cancel()
        }
    }, [dataSource.pagination.current,
        dataSource.pagination.pageSize,
        appState.loggedIn,
    ])

    const columnsChatLinks = [
        {
            title: 'ChatLink',
            width: 500,
            //dataIndex: ['url'],
            key: 'chat_link',
            render: (_, record) => {
                return (
                    <p style={{paddingLeft:'25px'}}>
                        {/*<a href={record.url} target="_blank">{record.url}</a>*/}
                        {record.url}
                    </p>
                )
            }
        },
    ]

    const columnsOtherData = [
        {
            title: 'Msg Id',
            width: 150,
            //dataIndex: ['message_id'],
            key: 'msg_id',
            render: (_, record) => {
                return (
                    <Tooltip title="Open Message in New Tab" placement="bottom">
                        <NavLink to={`/messages/message/${record.message_id}`} target="_blank" rel="noopener noreferrer" className="nav-link">
                            <p style={{paddingLeft:'10px'}}>
                                {record.message_id}
                            </p>
                        </NavLink>
                    </Tooltip>
                    
                )
            }
        },
        {
            title: 'From',
            width: 200,
            //dataIndex: ['from_number'],
            key: 'from',
            render: (_, record) => {
                return (
                    <p style={{paddingLeft:'10px'}}>
                        {record.from_number}
                    </p>
                )
            }
        },
        {
            title: 'Msg Time',
            width: 250,
            //dataIndex: ['message_time'],
            key: 'msg_time',
            render: (_, record) => {
                const msg_time = getLocalTime(record.message_time, false)
                return (
                    <p style={{paddingLeft:'10px'}}>
                        {msg_time.split(",")[0]}{msg_time.split(",")[1]}
                    </p>
                )
            }
        },
    ]

    /*** Table Related Functions ***/
    function handleTableChange(pagination, filters, sorter) {
        setDataSource(draft => {
            draft.pagination.current = pagination.current
            draft.pagination.pageSize = pagination.pageSize
        })
    }

    const tableLoading = {
        spinning: dataSource.isLoading,
        indicator: <Spin size="large" />,
    }

    if (!appState.loggedIn) return <Login />

    return (
        <div className='App'>
            {messageContextHolder}
            <SideMenu connected={props.connected}/>
            <div className="table-container" style={{paddingLeft:'12px', paddingTop:'8px'}}>
                <Table
                    loading={tableLoading}
                    style={{width: 600, }}
                    className="messages-table"
                    bordered={{ border: 'none', }}
                    dataSource={dataSource.otherData}
                    columns={columnsOtherData}
                    pagination={false}
                />
            </div>
            <div className="table-container" style={{paddingLeft:'0px', paddingTop:'8px'}}>
                <Table
                    loading={tableLoading}
                    style={{width: 500, }}
                    className="messages-table"
                    bordered={{ border: 'none', }}
                    dataSource={dataSource.chatLinks}
                    columns={columnsChatLinks}
                    onChange={handleTableChange}
                    pagination={dataSource.pagination}
                />
            </div>
        </div>
    )
}

export default ChatLinksTable