import { useContext, useEffect } from 'react'
import { useImmer } from "use-immer"
import { List, Button, Space, Form, Input, Alert } from 'antd'
import { UserOutlined, PhoneOutlined, MailOutlined, LockOutlined } from '@ant-design/icons'
import { formatPhoneNumber } from "../Utils"

import SideMenu from './SideMenu'
import Login from "./Login"

import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'

function Settings(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [showError, setShowError] = useImmer(false)
    const [pwData, setPWData] = useImmer({
        password:null,
        newPassword1: null,
        newPassword2: null,
        requestCount: 0,
        isLoading: false,
    })
    
    let phoneNumber = formatPhoneNumber(appState.user.phonenumber)
    if (phoneNumber === null) phoneNumber = appState.user.phonenumber

    useEffect(() => {
        if (pwData.requestCount === 0) return

        const currPassword = pwData.password
        const newPassword1 = pwData.newPassword1
        const newPassword2 = pwData.newPassword2

        async function updatePassword() {
            try {
                const response = await api.post(
                    'users/set_password/',
                    { 'new_password':newPassword1, 're_new_password':newPassword2, 'current_password':currPassword },
                    appState.token.post_config
                )
                appDispatch({
                    type: "success",
                    data: "Password Changed Successfully"
                })
                appDispatch({type: "logout", })
            } catch (err) {
                //console.log(err)
                const currPw = (err.response.data?.current_password ?? [""])[0]
                const newPW = (err.response.data?.new_password ?? [""])[0]
                if (currPw !== "") {
                    appDispatch({
                        type: "error",
                        err: null,
                        data: currPw
                    })
                } else if (newPW !== "") {
                    appDispatch({
                        type: "error",
                        err: null,
                        data: newPW
                    })
                }
                /*appDispatch({
                    type: "error",
                    data: "Unable to reset password!"
                })*/
            }
            setPWData(draft => {
                draft.password = null
                draft.newPassword1 = null
                draft.newPassword2 = null
                draft.isLoading = false
            })
        }
        updatePassword()
    }, [pwData.requestCount])

    function resetPassword(values) {
        //console.log(values)
        if (values.newPW1 !== values.newPW2) {
            setShowError(true)
        } else {
            setShowError(false)
            setPWData(draft => {
                draft.password = values.curPW
                draft.newPassword1 = values.newPW1
                draft.newPassword2 = values.newPW2
                draft.requestCount++
                draft.isLoading = true
            })
        }
    }

    const data = [
        {
            icon: <UserOutlined />,
            title: 'Name',
            value: appState.user.username,
        },
        {
            icon: <PhoneOutlined />,
            title: 'Phone Number',
            value: phoneNumber,
        },
        {
            icon: <MailOutlined />,
            title: 'E-mail',
            value: appState.user.email,
        },
    ]

    if (!appState.loggedIn) return <Login />

    return (
        <div className='App'>
            <SideMenu connected={props.connected}/>
            <Space direction='horizontal' align='start' className='settings-panel'>
                <List style={{width: '300px', marginLeft: '15px'}}
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<div style={{paddingTop:'36px'}}>{item.icon}</div>}
                                title={<p>{item.title}</p>}
                                description={item.value}
                            />
                        </List.Item>
                    )}
                />
                <Form
                    style={{paddingTop: '32px'}}
                    name='updatePW'
                    onFinish={resetPassword}
                >
                    <Form.Item
                        htmlFor='curPW'
                        name='curPW'
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your password!'
                            }
                        ]}
                    >
                        <Input autoComplete='off' prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='Current Password'/>
                    </Form.Item>
                    <Form.Item
                        htmlFor='newPW1'
                        name='newPW1'
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your new password!'
                            }
                        ]}
                    >
                        <Input autoComplete='off' prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='New Password'/>
                    </Form.Item>
                    <Form.Item
                        htmlFor='newPW2'
                        name='newPW2'
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your new password again!'
                            }
                        ]}
                    >
                        <Input autoComplete='off' prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='New Password Again'/>
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' name='login'>
                            Update Password
                        </Button>
                    </Form.Item>
                    {showError && <div >
                        <Alert message="Passwords do not match!" type="error" />
                    </div> }
                </Form>
            </Space>
        </div>
    )
}

export default Settings