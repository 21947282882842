import React, { useState, useRef, useContext, useEffect } from 'react'
import { Form, Input } from 'antd'
import { NOT_AVAILABLE } from '../Constants'

import DispatchContext from '../DispatchContext'

import '../App.css'

const EditableContext = React.createContext(null)

export const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props}/>
            </EditableContext.Provider>
        </Form>
    )
}

export const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    upcStatusState,
    handleProductNameSave,
    isFieldDisabled,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef(null)
    const form = useContext(EditableContext)

    const appDispatch = useContext(DispatchContext)
        
    useEffect(() => {
        if (editing) {
            inputRef.current.focus()
        }
    }, [editing])

    const toggleEdit = () => {
        setEditing(!editing)
        form.setFieldsValue({
            [dataIndex[1]]: record[dataIndex[0]][dataIndex[1]],
        })
    }

    const save = async () => {
        try {
            const values = await form.validateFields()
            toggleEdit()
            handleProductNameSave(record, values, dataIndex)
        } catch (errInfo) {
            //console.log('Save failed:', errInfo)
            appDispatch({
                type: "error",
                data: errInfo
            })
        }
    }

    let childNode = children

    let pName = ""

    if (record !== undefined) {
        pName = record.product_instance === null ? '' : record.product_instance.original_name
        if (/*upcStatusState.isUPCSet[record.key] || */record.zoho_id !== null || record.product_instance === null || isFieldDisabled(record)) {
            return (
                <td {...restProps}>
                    <div
                        className="cell-value-wrap long-text-without-spaces"
                        style={{
                            paddingRight: 24,
                        }}
                    >
                        {children}
                    </div>
                    <div className='long-text-without-spaces' style={{paddingLeft: 12, }}><small >{pName}</small></div>
                </td>
            )
        }
    }

    let cssStyle = "editable-cell-value-wrap"

    if (record !== undefined) {
        const productName = record.product_instance.name
        if (productName === "" || productName === NOT_AVAILABLE) {
            cssStyle = "editable-cell-value-wrap-red"
        }
    }

    if (editable) {
        childNode = editing ? (
            <div>
                <Form.Item
                    style={{
                        margin: 0,
                        paddingRight:'5px',
                    }}
                    name={dataIndex[1]}
                    /*rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ]}*/
                >
                    <Input size='small' ref={inputRef} onPressEnter={save} onBlur={save} style={{fontSize: 14, }}/>
                </Form.Item>
                <small className='long-text-without-spaces' style={{paddingLeft: 12, }}>{pName}</small>
            </div>
        ) : (
            <div>
                <div
                    className={cssStyle}
                    style={{
                        paddingRight: 24,
                        fontSize: 14,
                        width: 144,
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
                <small className='long-text-without-spaces' style={{paddingLeft: 12, }}>{pName}</small>
            </div>
        )
    }

    return <td {...restProps}>{childNode}</td>
}
