import { Tag, Tooltip } from 'antd'
import { cropText } from '../Utils'


function AntTagWithTooltip(props) {

    const maxLen = props.maxLen || 20

    function handleClose(e) {
        e.preventDefault()
        if (props.unAsssignTag)
            props.unAsssignTag(props.text, props.record)
    }

    if (props.text.length < maxLen) {
        return (
            <Tag key={props.text} closable={props.closable} onClose={handleClose}>
                {props.text}
            </Tag>
        )
    }

    return (
        <Tooltip title={props.text} placement="bottom">
            <Tag key={props.text} closable={props.closable} onClose={handleClose}>
                {cropText(props.text, maxLen)}
            </Tag>
        </Tooltip>
    )
}

export default AntTagWithTooltip