import { Modal, Table, Tooltip } from "antd"
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, RightOutlined } from '@ant-design/icons'

import '../App.css'


function SearchTipsModal(props) {

    const dataSource = [
        {
            key: '1',
            is_query: true,
            query_content: 'apple watch',
            elasticsearch: '',
            normal_search: '',
        },
        {
            key: '2',
            is_query: false,
            query_content: 'apple i-watches',
            elasticsearch: 'check',
            normal_search: 'close',
        },
        {
            key: '3',
            is_query: false,
            query_content: '349-apple-watch-series',
            elasticsearch: 'check',
            normal_search: 'close',
        },
        {
            key: '4',
            is_query: false,
            query_content: 'apple-s9-watch',
            elasticsearch: 'check',
            normal_search: 'close',
        },
        {
            key: '5',
            is_query: true,
            query_content: 'airpods 2',
            elasticsearch: '',
            normal_search: '',
        },
        {
            key: '6',
            is_query: false,
            query_content: 'AirPods pro 2..',
            elasticsearch: 'check',
            normal_search: 'close',
        },
        {
            key: '7',
            is_query: false,
            query_content: 'AIRPODS PRO-2',
            elasticsearch: 'check',
            normal_search: 'close',
        },
        {
            key: '8',
            is_query: false,
            query_content: 'airpods 2nd',
            elasticsearch: 'close',
            normal_search: 'check',
        },
        {
            key: '9',
            is_query: true,
            query_content: 'iphone 14',
            elasticsearch: '',
            normal_search: '',
        },
        {
            key: '10',
            is_query: false,
            query_content: '*IPhone* :IN:14/128gb',
            elasticsearch: 'check',
            normal_search: 'close',
        },
        {
            key: '11',
            is_query: false,
            query_content: 'iPhone - 14',
            elasticsearch: 'check',
            normal_search: 'close',
        },
        {
            key: '12',
            is_query: false,
            query_content: '*Iphone 14pro',
            elasticsearch: 'close',
            normal_search: 'check',
        },
        {
            key: '13',
            is_query: false,
            query_content: 'Iphone 14promax',
            elasticsearch: 'close',
            normal_search: 'check',
        },
        {
            key: '14',
            is_query: true,
            query_content: 'pyson',
            elasticsearch: '',
            normal_search: '',
        },
        {
            key: '15',
            is_query: false,
            query_content: 'dyson',
            elasticsearch: 'check',
            normal_search: 'close',
        },
        {
            key: '16',
            is_query: true,
            query_content: 'iphone',
            elasticsearch: '',
            normal_search: '',
        },
        {
            key: '17',
            is_query: false,
            query_content: '*I phone',
            elasticsearch: 'check',
            normal_search: 'close',
        },
        {
            key: '18',
            is_query: false,
            query_content: 'I-phone',
            elasticsearch: 'check',
            normal_search: 'close',
        },
        {
            key: '19',
            is_query: false,
            query_content: 'Phone*',
            elasticsearch: 'check',
            normal_search: 'close',
        },
    ]

    function getText(record) {
        return (
            <div>
                {record.is_query ? <SearchOutlined /> : <RightOutlined />}
                {record.is_query ? <strong>{record.query_content}</strong> : record.query_content}
            </div>
        )
    }

    function getIcon(val) {
        if (val === 'check') {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Tooltip title='Hit' placement="bottom">
                        <CheckCircleOutlined style={{color:'green'}} />
                    </Tooltip>
                </div>
            )
        }
        else if (val === 'close') {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Tooltip title='No hit' placement="bottom">
                        <CloseCircleOutlined style={{color:'red'}} />
                    </Tooltip>
                </div>
            )
        } else {
            return ""
        }
    }

    const columns = [
        {
          title: 'Query / Content',
          dataIndex: 'query_content',
          key: 'query_content',
          render: (_, record) => { return getText(record) }
        },
        {
          title: <div style={{ textAlign: 'center' }}>Elastic Search</div>,
          dataIndex: 'elasticsearch',
          key: 'elasticsearch',
          render: (_, record) => { return getIcon(record.elasticsearch) }
        },
        {
          title: <div style={{ textAlign: 'center' }}>Normal Search</div>,
          dataIndex: 'normal_search',
          key: 'normal_search',
          render: (_, record) => { return getIcon(record.normal_search) }
        },
    ]

    function handleOk() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
    }

    function handleCancel() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
    }

    return (
        <Modal
            centered
            title={"Elastic Search vs Normal Search"}
            open={props.state.modal.isOpen}
            footer={[]}
            onOk={handleOk}
            onCancel={handleCancel}
            width={'700px'}
            >
                {
                    <Table dataSource={dataSource} columns={columns} pagination={false}/>
                }
        </Modal>
    )
}

export default SearchTipsModal