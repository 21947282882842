import { useEffect, useContext, useState } from 'react'
import { useImmer } from "use-immer"
import Axios from "axios"
import api from '../AxiosInstance'
import AntSelectWithAdd from "./AntSelectWithAdd"

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import { splitArrToFields } from '../Utils'


function ContactTags(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [items, setItems] = useImmer({data: []})

    function extractUserContactTags() {
        let userContactTags = []
        props.record.vendor_actions.forEach(item => {
            if (item["custom_user__full_name"] === appState.user.username && item["action"] === "TAG") {
                userContactTags.push(item['vendor_tag'])
            }
        })
        return userContactTags
    }

    useEffect(() => {
        function setSelectOptions() {
            const userContactTags = extractUserContactTags()
            const availableTags = props.filterOptions.contactTags.data.filter(function(item) {
                return !userContactTags.includes(item)
            })
            setItems(draft => {
                draft.data = availableTags
            })
        }
        setSelectOptions()
    }, [props.contagTagsDropDownData.requestCount])


    function updateTableData() {
        if (props.assignTagData.actionType === "addNew" || props.assignTagData.actionType === "assign") {
            props.dataSource.messages.forEach(function(row, index) {
                if (row.vendor.id === props.record.vendor.id) {
                    let vendor_actions = row.vendor_actions
                    let tempArr = [...vendor_actions]
                    tempArr.push({"custom_user__full_name": appState.user.username, "action": "TAG", "vendor_tag": props.assignTagData.tagName})
                    vendor_actions = tempArr
                    props.setDataSource(draft => {
                        draft.messages[index].vendor_actions = vendor_actions
                    })
                }
            })
            appDispatch({
                type: "success",
                data: "Tag assigned successfully"
            })
        } else {
            props.dataSource.messages.forEach(function(row, index) {
                if (row.vendor.id === props.record.vendor.id) {
                    let vendor_actions = row.vendor_actions
                    vendor_actions = vendor_actions.filter(
                        item => !(item["custom_user__full_name"] === appState.user.username && item["action"] === "TAG" && item["vendor_tag"] === props.assignTagData.tagName)
                    )
                    props.setDataSource(draft => {
                        draft.messages[index].vendor_actions = vendor_actions
                    })
                }
            })
            appDispatch({
                type: "success",
                data: "Tag removed successfully"
            })
        }
        props.setContagTagsDropDownData(draft => {
            draft.requestCount++
        })
    } 

    useEffect(() => {
        if (props.assignTagData.requestCount === 0 || props.assignTagData.contactId === -1 || props.assignTagData.actionType === '') {
            return
        }

        if (props.assignTagData.recordKey !== props.record.message.id) {
            return
        }

        const ourRequest = Axios.CancelToken.source()

        async function toggleContactAction() {
            props.setAssignTagData(draft => {
                draft.isLoading = true
            })
            try {
                const response = await api.post(
                    'toggle_vendor_action/',
                    { 'vendor_id': props.assignTagData.contactId, 'action': "TAG", 'tag': props.assignTagData.tagName },
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                //console.log(response.data)
                updateTableData()
            } catch (err) {
                //console.log(err)
                let errData = "Unable to remove tag!"
                if (props.assignTagData.actionType === "addNew" || props.assignTagData.actionType === "assign") {
                    errData = "Unable to assign tag!"
                }
                appDispatch({
                    type: "error",
                    err: err,
                    data: errData
                })
            } finally {
                props.setAssignTagData(draft => {
                    draft.isLoading = false
                    draft.recordKey = -1
                    draft.contactId = -1
                    draft.actionType = ''
                    draft.tagName = ''
                })
            }
        }
        toggleContactAction()
        return () => {
            ourRequest.cancel()
        }
    }, [props.assignTagData.requestCount])


    function addAndAssignNewTag(tag) {
        //console.log("addNewTag")
        //console.log(tag)

        // tag already assigned or in the list
        if (props.filterOptions.contactTags.data.includes(tag)) {
            return
        }

        let tempArr = [...props.filterOptions.contactTags.data]
        tempArr.push(tag)
        props.setFilterOptions(draft => {
            draft.contactTags.data = tempArr
        })

        props.setAssignTagData(draft => {
            draft.recordKey = props.record.message.id
            draft.contactId = props.record.vendor.id
            draft.actionType = "addNew"
            draft.tagName = tag
            draft.requestCount++
        })
    }

    function assignNewTag(tag) {
        //console.log("assignNewTag")
        //console.log(tag)

        props.setAssignTagData(draft => {
            draft.recordKey = props.record.message.id
            draft.contactId = props.record.vendor.id
            draft.actionType = "assign"
            draft.tagName = tag
            draft.requestCount++
        })
    }

    return (
        <AntSelectWithAdd
            items={items.data}
            addItem={(newValue) => {
                addAndAssignNewTag(newValue)
            }}
            onChange={(newValue) => {
                assignNewTag(newValue)
            }}
            selected={null}
            notFoundContent={"No available tag"}
            selectPlaceholder={"Add New Tag"}
            inputPlaceholder={"enter tag"}
            maxLen={255}
        />
    )
}

export default ContactTags