import { Link } from "react-router-dom"

import '../App.css'

function NotFound() {

    return (
        <div className='text-center'>
            <h2>Page Not Found</h2>
            <p>Go to <Link to="/">Home Page</Link></p>
        </div>
    )
}

export default NotFound
