import { useEffect, useContext } from 'react'
import { useImmer } from "use-immer"

import { Input, Modal, Space } from "antd"
import AntSelect from "./AntSelect"

import { NOT_AVAILABLE } from '../Constants'

import Axios from "axios"
import api from '../AxiosInstance'

import '../App.css'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'


function EditProductModelModal(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [selectedModel, setSelectedModel] = useImmer({id: null})
    const [editProductModelState, setEditProductModelState] = useImmer({name: '', brand: null, category: null, requestCount: 0})

    useEffect(() => {
        if (editProductModelState.requestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function updateProductModel() {
            const newObj = { ...editProductModelState }
            delete newObj['requestCount']
            try {
                const response = await api.patch(
                    `update_product_model/${selectedModel.id}/`,
                    newObj,
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                appDispatch({
                    type: "success",
                    data: "Product model updated"
                })
                props.setFilterOptions(draft => {
                    draft.productModels.requestCount++
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to update product model!"
                })
            }
        }
        updateProductModel()

        props.setFilterOptions(draft => {
            draft.requestCount++
        })

        return () => {
            ourRequest.cancel()
        }
    }, [editProductModelState.requestCount])

    function handleOk() {
        if (selectedModel.id === null || selectedModel.id === NOT_AVAILABLE) {
            appDispatch({
                type: "info",
                data: "Model must be selected!"
            })
        } else if (editProductModelState.name === '') {
            appDispatch({
                type: "info",
                data: "Model name can not be empty!"
            })
        } else if (editProductModelState.brand === null) {
            appDispatch({
                type: "info",
                data: "Brand must be selected!"
            })
        } else if (editProductModelState.category === null) {
            appDispatch({
                type: "info",
                data: "Category must be selected!"
            })
        } else {
            setEditProductModelState(draft => {
                draft.requestCount++
            })
            props.setState(draft => {
                draft.modal.isOpen = false
            })
        }
    }

    function handleCancel() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
    }
    
    return (
        <Modal
            centered
            title={"Edit Product Model"}
            open={props.state.modal.isOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
            <Space direction="vertical" size="small">
                <Space direction="horizontal" size="small">
                    <AntSelect
                        placeholder='Select Model'
                        size="small"
                        disabled={props.productModels.length === 0}
                        showSearch={true}
                        options={props.productModels}
                        onChange={(newValue) => {
                            setSelectedModel(draft => {
                                draft.id = newValue
                            })
                            const index = props.productModels.findIndex((item) => newValue === item.id)
                            const selectedModelInstance = props.productModels[index]
                            setEditProductModelState(draft => {
                                draft.name = selectedModelInstance.name
                            })
                            if (selectedModelInstance.brand !== null && selectedModelInstance.brand !== undefined) {
                                setEditProductModelState(draft => {
                                    draft.brand = selectedModelInstance.brand.id
                                })
                            }
                            if (selectedModelInstance.category !== null && selectedModelInstance.category !== undefined) {
                                setEditProductModelState(draft => {
                                    draft.category = selectedModelInstance.category.id
                                })
                            }
                        }}
                        width={210}/>
                    <Input
                        placeholder='New Model Name'
                        value={editProductModelState.name}
                        onChange={(e) => {
                            setEditProductModelState(draft => {
                                draft.name = e.target.value
                            })
                        }}
                        size='small' style={{width:'210px', }}/>
                </Space>
                <Space direction="horizontal" size="small">
                    <AntSelect
                        placeholder='Brand'
                        size="small"
                        selected={editProductModelState.brand}
                        disabled={props.brands.length === 0}
                        showSearch={true}
                        options={props.brands}
                        onChange={(newValue) => {
                            setEditProductModelState(draft => {
                                draft.brand = newValue
                            })
                        }}
                        width={210}/>
                    <AntSelect
                        placeholder='Category'
                        size="small"
                        selected={editProductModelState.category}
                        disabled={props.categories.length === 0}
                        showSearch={true}
                        options={props.categories}
                        onChange={(newValue) => {
                            setEditProductModelState(draft => {
                                draft.category = newValue
                            })
                        }}
                        width={210}/>
                </Space>
            </Space>
        </Modal>
    )
}

export default EditProductModelModal