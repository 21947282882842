import { useEffect } from 'react'
import { NOT_AVAILABLE } from "../Constants"
import Axios from "axios"
import api from '../AxiosInstance'


export function useFetchBrands(appState, appDispatch, filterOptions, setFilterOptions) {
    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchBrands() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setFilterOptions(draft => {
                draft.brands.isLoading = true
            })
            try {
                const response = await api.get('list_brands/', appState.token.get_config, { cancelToken: ourRequest.token })
                //console.log(`${response.data.length} brands found`)
                setFilterOptions(draft => {
                    draft.brands.data = response.data
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch brands!"
                })
            }
            setFilterOptions(draft => {
                draft.brands.isLoading = false
            })
        }
        fetchBrands()
        return () => {
            ourRequest.cancel()
        }
    }, [filterOptions.brands.requestCount, appState.loggedIn,])
}

export function useFetchCategories(appState, appDispatch, filterOptions, setFilterOptions) {
    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchCategories() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setFilterOptions(draft => {
                draft.categories.isLoading = true
            })
            try {
                const response = await api.get('list_categories/', appState.token.get_config, { cancelToken: ourRequest.token })
                //console.log(`${response.data.length} categories found`)
                setFilterOptions(draft => {
                    draft.categories.data = response.data
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch categories!"
                })
            }
            setFilterOptions(draft => {
                draft.categories.isLoading = false
            })
        }
        fetchCategories()
        return () => {
            ourRequest.cancel()
        }
    }, [filterOptions.categories.requestCount, appState.loggedIn,])
}

export function useFetchVendors(appState, appDispatch, filterOptions, setFilterOptions) {
    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchVendors() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setFilterOptions(draft => {
                draft.vendors.isLoading = true
            })
            try {
                const response = await api.get('list_vendors/', appState.token.get_config, { cancelToken: ourRequest.token })
                //console.log(`${response.data.length} vendors found`)
                setFilterOptions(draft => {
                    draft.vendors.data = response.data
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch vendors!"
                })
            }
            setFilterOptions(draft => {
                draft.vendors.isLoading = false
            })
        }
        fetchVendors()
        return () => {
            ourRequest.cancel()
        }
    }, [filterOptions.vendors.requestCount, appState.loggedIn,])
}

export function useFetchCompanies(appState, appDispatch, filterOptions, setFilterOptions) {
    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchCompanies() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setFilterOptions(draft => {
                draft.companies.isLoading = true
            })
            try {
                const response = await api.get('list_companies/', appState.token.get_config, { cancelToken: ourRequest.token })
                const companyArray = response.data
                if (companyArray.length > 0 && companyArray[0].id !== NOT_AVAILABLE) {
                    companyArray.unshift({id: NOT_AVAILABLE, name: NOT_AVAILABLE})
                }
                //console.log(`${response.data.length} companies found`)
                setFilterOptions(draft => {
                    draft.companies.data = companyArray
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch companies!"
                })
            }
            setFilterOptions(draft => {
                draft.companies.isLoading = false
            })
        }
        fetchCompanies()
        return () => {
            ourRequest.cancel()
        }
    }, [filterOptions.companies.requestCount, appState.loggedIn,])
}

export function useFetchDealers(appState, appDispatch, filterOptions, setFilterOptions) {
    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchDealers() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setFilterOptions(draft => {
                draft.dealers.isLoading = true
            })
            try {
                const response = await api.get('list_dealers/', appState.token.get_config, { cancelToken: ourRequest.token })
                //console.log(`${response.data.length} dealers found`)
                setFilterOptions(draft => {
                    draft.dealers.data = response.data
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch dealers!"
                })
            }
            setFilterOptions(draft => {
                draft.dealers.isLoading = false
            })
        }
        fetchDealers()
        return () => {
            ourRequest.cancel()
        }
    }, [filterOptions.dealers.requestCount, appState.loggedIn,])
}

export function useFetchSalesmen(appState, appDispatch, filterOptions, setFilterOptions) {
    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchSalesmen() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setFilterOptions(draft => {
                draft.salesmen.isLoading = true
            })
            try {
                const response = await api.get('list_salesmen/', appState.token.get_config, { cancelToken: ourRequest.token })
                const salesmen = response.data
                if (salesmen.length > 0) {
                    salesmen.unshift({id: -1, full_name: "Select Salesman"})
                }
                //console.log(`${response.data.length} salesmen found`)
                setFilterOptions(draft => {
                    draft.salesmen.data = response.data
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch salesmen!"
                })
            }
            setFilterOptions(draft => {
                draft.salesmen.isLoading = false
            })
        }
        fetchSalesmen()
        return () => {
            ourRequest.cancel()
        }
    }, [filterOptions.salesmen.requestCount, appState.loggedIn,])
}

export function useFetchCountries(appState, appDispatch, filterOptions, setFilterOptions) {
    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchCountries() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setFilterOptions(draft => {
                draft.countries.isLoading = true
            })
            try {
                const response = await api.get('list_countries/', appState.token.get_config, { cancelToken: ourRequest.token })
                const countryArray = response.data
                if (countryArray.length > 0 && countryArray[0].id !== NOT_AVAILABLE) {
                    countryArray.unshift({id: NOT_AVAILABLE, name: NOT_AVAILABLE})
                }
                //console.log(`${response.data.length} countries found`)
                setFilterOptions(draft => {
                    draft.countries.data = countryArray
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch countries!"
                })
            }
            setFilterOptions(draft => {
                draft.countries.isLoading = false
            })
        }
        fetchCountries()
        return () => {
            ourRequest.cancel()
        }
    }, [filterOptions.countries.requestCount, appState.loggedIn,])
}

export function useFetchRegions(appState, appDispatch, filterOptions, setFilterOptions) {
    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchRegions() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setFilterOptions(draft => {
                draft.regions.isLoading = true
            })
            try {
                const response = await api.get('list_regions/', appState.token.get_config, { cancelToken: ourRequest.token })
                const regionArray = response.data
                if (regionArray.length > 0 && regionArray[0].id !== NOT_AVAILABLE) {
                    regionArray.unshift({id: NOT_AVAILABLE, name: NOT_AVAILABLE})
                }
                //console.log(`${response.data.length} regions found`)
                setFilterOptions(draft => {
                    draft.regions.data = regionArray
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch regions!"
                })
            }
            setFilterOptions(draft => {
                draft.regions.isLoading = false
            })
        }
        fetchRegions()
        return () => {
            ourRequest.cancel()
        }
    }, [filterOptions.regions.requestCount, appState.loggedIn,])
}

export function useFetchWaGroupNames(appState, appDispatch, filterOptions, setFilterOptions) {
    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchWaGroupNames() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setFilterOptions(draft => {
                draft.waGroupNames.isLoading = true
            })
            try {
                const response = await api.get('list_wa_groups/', appState.token.get_config, { cancelToken: ourRequest.token })
                //console.log(`${response.data.length} wa groups found`)
                setFilterOptions(draft => {
                    draft.waGroupNames.data = response.data
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch Whatsapp group names!"
                })
            }
            setFilterOptions(draft => {
                draft.waGroupNames.isLoading = false
            })
        }
        fetchWaGroupNames()
        return () => {
            ourRequest.cancel()
        }
    }, [filterOptions.waGroupNames.requestCount, appState.loggedIn,])
}

export function useFetchContactTags(appState, appDispatch, filterOptions, setFilterOptions)
{
    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchContactTags() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setFilterOptions(draft => {
                draft.contactTags.isLoading = true
            })
            try {
                const response = await api.get('list_contact_tags/', appState.token.get_config, { cancelToken: ourRequest.token })
                //console.log(`${response.data.length} tags found`)
                setFilterOptions(draft => {
                    draft.contactTags.data = response.data
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch Contact tags!"
                })
            }
            setFilterOptions(draft => {
                draft.contactTags.isLoading = false
            })
        }
        fetchContactTags()
        return () => {
            ourRequest.cancel()
        }
    }, [filterOptions.contactTags.requestCount, appState.loggedIn,])
}