import { NOT_AVAILABLE } from "./Constants"

export function isNum(val) {
    return /^\d+$/.test(val)
}

export function isValidUPC(val) {
    /*if (!isNum(val)) {
        return false
    }*/
    if (val === null) return false
    if (val.length !== 12 && val.length !== 13 && val.length !== 14) {
        return false
    }
    return true
}

export function isValidEmail(email) {
    if (email === "" || email === null) return true
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(email)
}

export function formatPhoneNumber(str) {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '')
    
    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    
    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    
    return null
}

export function cropText(str_data, len_data) {
    if (str_data === null) return ""
    if (str_data === "") return ""
    if (str_data.length > len_data) {
        return (str_data.substring(0, len_data) + "...")
    } else {
        return str_data
    }
}

export function getFileNameAfterLastSlash(str_data) {
    const lastIndex = str_data.lastIndexOf("/")
    if (lastIndex !== -1) {
        const textAfterLastSlash = str_data.substring(lastIndex + 1)
        return textAfterLastSlash
    } else {
        return str_data
    }
}

export function capitalize(str) {
    if (str.length === 0) {
        return str // Return empty string if input is empty
    }
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function getLocalTime(isoString, isSub) {
    const date = new Date(isoString)
    const timeZoneOffset = date.getTimezoneOffset()
    const timeZoneOffsetMs = timeZoneOffset * 60 * 1000
    let localDate = new Date(date.getTime())
    if (isSub) {
        localDate = new Date(date.getTime() - timeZoneOffsetMs)
    }
    return localDate.toLocaleString(undefined, )
}

export function getOneMinuteBeforeUTC() {
    const currentDate = new Date()
    let oneMinuteBefore = new Date(currentDate)
    oneMinuteBefore.setMinutes(currentDate.getMinutes() - 1)
    const oneMinuteBeforeUTCString = oneMinuteBefore.toUTCString()
    return oneMinuteBeforeUTCString
}

export function isDateNew(jsTimestamp, diffTime) {
    const timestampDate = new Date(jsTimestamp)
    const currentUtcTime = new Date()
    const timeDifference = currentUtcTime - timestampDate
    const isWithinLastXHours = timeDifference <= diffTime // 24 * 60 * 60 * 1000
    return isWithinLastXHours
}

export function isItemInArrayIgnoreCase(array, name) {
    const lowerCaseName = name.toLowerCase() // Convert the item to lowercase
  
    // Use some() method to iterate over the array and check for a case-insensitive match
    return array.some((item) => item.name.toLowerCase() === lowerCaseName)
}

export function generateRandomPastelColor() {
    const hue = Math.floor(Math.random() * 360)
    const pastel = `hsl(${hue}, 50%, 80%)`
    return pastel
}

export function generateLighterColor(color, percent) {
    const hex = color.replace(/^#/, '')
    const r = parseInt(hex.slice(0, 2), 16)
    const g = parseInt(hex.slice(2, 4), 16)
    const b = parseInt(hex.slice(4, 6), 16)

    const newR = Math.min(255, Math.round(r * (1 + percent / 100)))
    const newG = Math.min(255, Math.round(g * (1 + percent / 100)))
    const newB = Math.min(255, Math.round(b * (1 + percent / 100)))

    return `rgb(${newR}, ${newG}, ${newB})`
}

// Function to handle copying the label to clipboard
export async function copyToClipboard(textToCopy) {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy)
    } else {
        // Use the 'out of viewport hidden text area' trick
        const textArea = document.createElement("textarea")
        textArea.value = textToCopy
            
        // Move textarea out of the viewport so it's not visible
        textArea.style.position = "absolute"
        textArea.style.left = "-999999px"
            
        document.body.prepend(textArea)
        textArea.select()

        try {
            document.execCommand('copy')
        } catch (error) {
            console.error(error)
        } finally {
            textArea.remove()
        }
    }
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export function isValid(item) {
    if (item === null || item === undefined || item === "" || item === NOT_AVAILABLE) return false
    return true
}

export function product_name(product_instance) {
    return product_instance === null ? '' : (product_instance.name === null ? '' : product_instance.name)
}

export function product_color(product_instance) {
    return product_instance === null ? '' : (product_instance.color === null ? '' : product_instance.color)
}

export function product_part_number(product_instance) {
    return product_instance === null ? '' : (product_instance.part_number === null ? '' : product_instance.part_number)
}

export function product_nation(product_instance) {
    return product_instance === null ? '' : (product_instance.nation === null ? '' : product_instance.nation)
}

export function product_specs(product_instance) {
    return product_instance === null ? '' : (product_instance.specs === null ? '' : product_instance.specs)
}

export function product_brand_name(product_model) {
    const result = product_model !== null ? (
       product_model.brand !== null ? product_model.brand.name : null) : null
    if (result === null) return ''
    else return result
}

export function product_category_name(product_model) {
    const result = product_model !== null ? (
        product_model.category !== null ? product_model.category.name : null) : null
    if (result === null) return ''
    else return result
}

export function vendor_name(vendor) {
    return vendor === null ? '' : (vendor.full_name === null ? '' : vendor.full_name)
}

export function vendor_email(vendor) {
    return vendor === null ? '' : (vendor.email === null ? '' : vendor.email)
}

export function vendor_company_name(vendor) {
    if (vendor === null) return ''
    return vendor.company !== null ? vendor.company.name : NOT_AVAILABLE
}

export function vendor_country_name(vendor) {
    if (vendor === null) return ''
    return vendor.company !== null ? (vendor.company.country !== null ? vendor.company.country.name : '') : ''
}

export function vendor_country_names_from_area_code(vendor) {
    if (vendor === null) return ''
    return vendor.country_names_from_area_code !== null ? vendor.country_names_from_area_code : ''
}


export function splitArrToFields(_arr) {
    const arr = [..._arr]
    const result = {}
    arr.forEach(item => {
        for (const key in item) {
            if (item.hasOwnProperty(key)) {
                if (!result[key]) {
                    result[key] = []
                }
                result[key].push(item[key])
            }
        }
    })
    if (Object.keys(result).length === 0 && result.constructor === Object)
        return null
    return result
}


export function returnDivFromText(text, fontSize) {
    return (<div style={{fontSize:`${fontSize}px`}}>{text}</div>)
}


// Regular expressions for UPC (12 digits) and EAN (13 digits)
export const isValidUPC_ = (code) => /^\d{12}$/.test(code)
export const isValidEAN_ = (code) => /^\d{13}$/.test(code)


export function zip(...arrays) {
    const minLength = Math.min(...arrays.map(arr => arr.length)) // Find the shortest array length
    return Array.from({ length: minLength }, (_, i) => arrays.map(arr => arr[i]))
}

// function to combine, sort, and separate the two arrays
export function sortAndAlignArrays(arr1, arr2) {
    // Combine both arrays into pairs
    const combined = arr1.map((value, index) => [value, arr2[index]])

    // Sort based on the `arr1` values
    //combined.sort((a, b) => a[0] - b[0])

    // Sort based on the `arr1` values (handles both numbers and strings)
    combined.sort((a, b) => {
        if (a[0] < b[0]) return -1 // a comes first
        if (a[0] > b[0]) return 1  // b comes first
        return 0                   // equal
    })

    // Separate the sorted pairs back into two aligned arrays
    const sortedArr1 = combined.map(item => item[0])
    const sortedArr2 = combined.map(item => item[1])

    return [sortedArr1, sortedArr2]
}