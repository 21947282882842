import { useContext, useEffect } from 'react'
import { Button, Space } from "antd"
import { RedoOutlined } from '@ant-design/icons'
import SideMenu from './SideMenu'
import Login from "./Login"

import StateContext from "../StateContext"

import '../App.css'

function WhatsApp(props) {
    const appState = useContext(StateContext)

    if (!appState.loggedIn) return <Login />

    return (
        <div className='App'>
            <SideMenu connected={props.waState.connected} />
            <div style={{display: 'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'1000px'}}>
                {props.socketState.data[appState.user.node] === "connected" ? <div className='wa-message-panel'>
                    <h4>{props.waState.header}</h4>
                    <p id="msg" className='withprewrap'>
                        {props.waState.message}
                    </p>
                    {props.waState.qrCode === ""/* || props.waState.connected*/ ? "" : 
                    <Space direction="vertical">
                        <img src={props.waState.qrCode} alt="QR Code" id="qrcode" width={300}/>
                    </Space>
                    }
                    <Button
                        disabled={props.refreshClicked.isClicked}
                        className='btn-style-red'
                        style={{marginLeft: '14px', width:'272px'}}
                        onClick={props.onRefreshClick}
                        //size="small"
                        icon={<RedoOutlined />}
                    >
                        Refresh QR Code
                    </Button>
                    <br />
                </div> : ""}
                <small style={{paddingLeft:'12px', paddingTop:'5px'}}>{"socket: "}{props.socketState.data[appState.user.node]}</small>
            </div>
        </div>
    )
}

export default WhatsApp