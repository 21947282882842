import { useEffect } from "react"
import { Spin } from 'antd'
import AntSelect from "./AntSelect"

function DealTypes(props) {

    useEffect(() => {
        props.setState(draft => {
            draft.isLoading = false
        })
    }, [])

    if (props.state.isLoading) return <Spin size="small" />

    return (
        <AntSelect
            disabled={props.disabled}
            size="small"
            selected={props.selected}
            options={props.state.payload}
            onChange={props.onChange}
            width={85}/>
    )
}

export default DealTypes
