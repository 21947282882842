import { useEffect, useContext } from 'react'
import { Modal, Radio } from "antd"
import { useImmer } from "use-immer"
import { cropText } from '../Utils'
import Axios from "axios"
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import { product_name, product_color, product_part_number, product_nation, product_specs, product_brand_name, product_category_name } from "../Utils"

function ProductModal(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [productModalData, setProductModalData] = useImmer({nameValue: 2, brandValue: 2, categoryValue: 2, colorValue: 2, partNumberValue: 2, nationValue:2, specsValue: 2})
    const [newProductData, setNewProductData] = useImmer({
        productThis: null,
        productModelThis:null,
        productModelChoice: 'nothing',
        productCreateThis: null,
        productZoho: null,
        productCreateZoho: null,
        updateRequestCount: 0,
        createThisRequestCount: 0,
        createZohoRequestCount: 0,
    })

    useEffect(() => {
        if (newProductData.createZohoRequestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        // EXPORT PRODUCT TO ZOHO
        async function createProductZoho() {
            props.setDataSource(draft => {
                draft.isLoading = true
            })
            //console.log(props.state.product_instance.UPC)
            //console.log(newProductData.productCreateZoho)
            let zoho_id = null
            try {
                const response = await api.post(
                    'import_export_product/',
                    {'UPC':props.state.product_instance.UPC, 'data': newProductData.productCreateZoho, 'type':'create'},
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                appDispatch({
                    type: "success",
                    data: "Product created in ZOHO"
                })
                zoho_id = response.data
                props.setState(draft => {
                    draft.newlyCreated = true
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to create product in ZOHO!"
                })
            }
            // IF ZOHO EXPORT SUCCESSFUL UPDATE PRODUCT IN DEALCATCER
            if (zoho_id !== null) {
                // can be simplified
                const new_product_data = {
                    'UPC': props.state.product_instance.UPC,
                    'name': props.state.product_instance.name,
                    'product_model': product_model_id(),
                    'color': props.state.product_instance.color,
                    'part_number': props.state.product_instance.part_number,
                    'nation': props.state.product_instance.nation,
                    'specs': props.state.product_instance.specs,
                    'zoho_id': zoho_id,
                }
                setNewProductData(draft => {
                    draft.productThis = new_product_data
                    draft.productModelThis = null
                    draft.productModelChoice = 'nothing'
                    draft.productZoho = null
                    draft.updateRequestCount++
                })
                //props.updateZohoId(props.state.key, zoho_id, 'Product')
                if (!props.state.isFireVendor) {
                    props.setDataSource(draft => {
                        draft.isLoading = false
                    })
                }
            } else {
                props.setDataSource(draft => {
                    draft.isLoading = false
                })
                // to discard changes
                props.setFilterOptions(draft => {
                    //draft.colorRowId = props.state.key
                    draft.requestCount++
                })
            }
        }
        createProductZoho()

        return () => {
            ourRequest.cancel()
        }
    }, [newProductData.createZohoRequestCount])

    useEffect(() => {
        if (newProductData.updateRequestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function updateProduct() {
            props.setDataSource(draft => {
                draft.isLoading = true
            })
            //let is_failed = false

            let createSuccessMsg = ""
            
            // CREATE BRAND
            if (newProductData.productModelThis !== null && newProductData.productModelThis.brand === null && newProductData.productModelThis.brand_name !== '') {
                try {
                    const response = await api.post(
                        'list_brands/',
                        {'name': newProductData.productModelThis.brand_name},
                        appState.token.post_config
                    )
                    createSuccessMsg = 'Brand, '
                    setNewProductData(draft => {
                        draft.productModelThis.brand = response.data.id  // update brand id with the new id obtained from db
                    })
                } catch (err) {
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to create new brand!"
                    })
                }
            }

            // CREATE CATEGORY
            if (newProductData.productModelThis !== null && newProductData.productModelThis.category === null && newProductData.productModelThis.category_name !== '') {
                try {
                    const response = await api.post(
                        'list_categories/',
                        {'name': newProductData.productModelThis.category_name},
                        appState.token.post_config
                    )
                    createSuccessMsg += 'Category, '
                    setNewProductData(draft => {
                        draft.productModelThis.category = response.data.id  // update category id with the new id obtained from db
                    })
                } catch (err) {
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to create new category!"
                    })
                }
            }

            // CREATE PRODUCT MODEL
            if (newProductData.productThis !== null &&
                newProductData.productModelChoice === 'create' &&
                newProductData.productModelThis.brand !== null &&
                newProductData.productModelThis.category !== null
            ) {
                try {
                    const response = await api.post(
                        'list_product_models/',
                        {'name': newProductData.productThis.name,  // give name of product to product model
                        'brand': newProductData.productModelThis.brand,
                        'category': newProductData.productModelThis.category},
                        appState.token.post_config
                    )
                    createSuccessMsg += 'Product Model, '
                    setNewProductData(draft => {
                        draft.productThis.product_model = response.data.id  // update product model id with the new id obtained from db
                    })
                } catch (err) {
                    //is_failed = true
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to create new product model!"
                    })
                }
            }

            if (createSuccessMsg.length > 0) {
                appDispatch({
                    type: "success",
                    data: createSuccessMsg.substring(0, createSuccessMsg.length-2) + ' created'
                })
            }

            let updateSuccessMsg = ''

            // UPDATE PRODUCT IN DEALCATCHER
            if (newProductData.productThis !== null) {
                try {
                    const response = await api.patch(
                        `update_product_instance/${props.state.product_instance.id}/`,
                        newProductData.productThis,
                        appState.token.post_config
                    )
                    updateSuccessMsg = 'Product, '
                } catch (err) {
                    //is_failed = true
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to update product in DealCatcher!"
                    })
                }
            }

            if (updateSuccessMsg.length > 0) {
                appDispatch({
                    type: "success",
                    data: updateSuccessMsg.substring(0, updateSuccessMsg.length-2) + ' updated in DealCatcher'
                })
            }

            // UPDATE PRODUCT IN ZOHO
            if (newProductData.productZoho !== null) {
                try {
                    const response = await api.post(
                        'import_export_product/',
                        {'UPC':props.state.product_instance.UPC, 'zoho_id':props.state.product_instance.zoho_id, 'data': newProductData.productZoho, 'type':'update'},
                        appState.token.post_config,
                        { cancelToken: ourRequest.token }
                    )
                    appDispatch({
                        type: "success",
                        data: "Product updated in ZOHO"
                    })
                } catch (err) {
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to update product in ZOHO!"
                    })
                }
            }
            if (props.state.isFireVendor) {
                props.setState(draft => {
                    draft.fireVendorReqCnt++
                })
            } else {//if (is_failed) {
                props.setDataSource(draft => {
                    draft.isLoading = false
                })
                props.setFilterOptions(draft => {
                    //draft.colorRowId = props.state.key
                    draft.requestCount++
                })
            }
        }
        updateProduct()

        return () => {
            ourRequest.cancel()
        }
    }, [newProductData.updateRequestCount])


    function handleUpdates() {
        if (props.state.zoho_product === null) {  // product not found in zoho, create product in zoho with info in dealcatcher
            const new_product_data_zoho = {
                'UPC': props.state.product_instance.UPC,
                'is_generic': props.state.product_instance.is_generic,
                'name': props.state.product_instance.name,
                'brand': product_brand_name(props.state.product_instance.product_model),
                'category': product_category_name(props.state.product_instance.product_model),
                'color': props.state.product_instance.color,
                'specs': props.state.product_instance.specs,
                'nation': props.state.product_instance.nation,
                'part_number': props.state.product_instance.part_number,
            }
            setNewProductData(draft => {
                draft.productCreateZoho = new_product_data_zoho
                draft.createZohoRequestCount++
            })
        } else {
            const selectedBrandName = productModalData.brandValue === 1 ?
                    product_brand_name(props.state.product_instance.product_model) : props.state.zoho_product.brand
            const selectedCategoryName = productModalData.categoryValue === 1 ?
                    product_category_name(props.state.product_instance.product_model) : props.state.zoho_product.category

            let isBrandSame = false
            if (productModalData.brandValue === 1) {
                isBrandSame = true
            } else {
                if (product_brand_name(props.state.product_instance.product_model).toLowerCase() === props.state.zoho_product.brand.toLowerCase()) {
                    isBrandSame = true
                }
            }

            let isCategorySame = false
            if (productModalData.categoryValue === 1) {
                isCategorySame = true
            } else {
                if (product_category_name(props.state.product_instance.product_model).toLowerCase() === props.state.zoho_product.category.toLowerCase()) {
                    isCategorySame = true
                }
            }

            let new_product_model_id = null
            let new_brand_id = null
            let new_category_id = null
            if (isBrandSame && isCategorySame) {  //do not change product model id
                new_product_model_id = product_model_id()
                new_brand_id = product_brand_id()
                new_category_id = product_category_id()
            } else {
                if (!isBrandSame) {
                    const brandIndex = props.brands.findIndex((item) => selectedBrandName.toLowerCase() === item.name.toLowerCase())
                    if (brandIndex !== -1) {  // brand with the same name found, so use it
                        new_brand_id = props.brands[brandIndex].id
                    }
                }
                if (!isCategorySame) {
                    const categoryIndex = props.categories.findIndex((item) => selectedCategoryName.toLowerCase() === item.name.toLowerCase())
                    if (categoryIndex !== -1) {  // category with the same name found, so use it
                        new_category_id = props.categories[categoryIndex].id
                    }
                }
                if (new_brand_id !== null && new_category_id !== null) {
                    const modelIndex = props.productModels.findIndex((item) => (
                            props.state.product_instance.name.toLowerCase() === item.name.toLowerCase() &&
                            new_brand_id === item.brand.id &&
                            new_category_id == item.category.id
                        )
                    )
                    if (modelIndex !== -1) {  // model with the same name, brand and category found, so use it
                        new_product_model_id = props.productModels[modelIndex].id
                    }
                }
            }

            const new_product_data = {
                'UPC': props.state.product_instance.UPC,
                'is_generic': props.state.product_instance.UPC === null ? true : false,
                'name': productModalData.nameValue === 1 ? props.state.product_instance.name : props.state.zoho_product.name,
                'product_model': new_product_model_id,
                'color': productModalData.colorValue === 1 ? props.state.product_instance.color : props.state.zoho_product.color,
                'part_number': productModalData.partNumberValue === 1 ? props.state.product_instance.part_number : props.state.zoho_product.part_number,
                'nation': productModalData.nationValue === 1 ? props.state.product_instance.nation : props.state.zoho_product.nation,
                'specs': productModalData.specsValue === 1 ? props.state.product_instance.specs : props.state.zoho_product.specs,
            }
            const new_product_model_data = {
                'brand': new_brand_id,
                'category': new_category_id,
                'brand_name': selectedBrandName,
                'category_name': selectedCategoryName,
            }
            const new_product_data_zoho = {
                'UPC': props.state.product_instance.UPC,
                'is_generic': props.state.product_instance.UPC === null ? true : false,
                'name': productModalData.nameValue === 1 ? props.state.product_instance.name : props.state.zoho_product.name,
                'brand': selectedBrandName,
                'category': selectedCategoryName,
                'color': productModalData.colorValue === 1 ? props.state.product_instance.color : props.state.zoho_product.color,
                'part_number': productModalData.partNumberValue === 1 ? props.state.product_instance.part_number : props.state.zoho_product.part_number,
                'nation': productModalData.nationValue === 1 ? props.state.product_instance.nation : props.state.zoho_product.nation,
                'specs': productModalData.specsValue === 1 ? props.state.product_instance.specs : props.state.zoho_product.specs,
            }
            const pm_choice = new_product_model_id === null ? 'create' : 'nothing'
            setNewProductData(draft => {
                draft.productThis = new_product_data
                draft.productModelThis = new_product_model_data
                draft.productModelChoice = pm_choice
                draft.productZoho = new_product_data_zoho
                draft.updateRequestCount++
            })
        }
    }

    function handleOk() {
        // handle product instance creation if necessary
        /*if (props.state.createProductInstance) {
            const new_product_data = {
                'UPC': props.state.product_instance.UPC,
                'name': props.state.product_instance.name,
                'product_model': product_model_id(),
                'color': props.state.product_instance.color,
                'part_number': props.state.product_instance.part_number,
                'nation': props.state.product_instance.nation,
                'specs': props.state.product_instance.specs,
            }
            setNewProductData(draft => {
                draft.productCreateThis = new_product_data
                draft.createThisRequestCount++
            })
        } else {
            handleUpdates()
        }*/

        handleUpdates()

        props.setState(draft => {
            draft.modal.isOpen = false
        })
    }

    function handleCancel() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
        props.setDataSource(draft => {
            draft.isLoading = false
        })
    }

    function onNameChange(e) {
        setProductModalData(draft => {
            draft.nameValue = e.target.value
        })
    }

    function onBrandChange(e) {
        setProductModalData(draft => {
            draft.brandValue = e.target.value
        })
    }

    function onCategoryChange(e) {
        setProductModalData(draft => {
            draft.categoryValue = e.target.value
        })
    }

    function onColorChange(e) {
        setProductModalData(draft => {
            draft.colorValue = e.target.value
        })
    }

    function onPartNumberChange(e) {
        setProductModalData(draft => {
            draft.partNumberValue = e.target.value
        })
    }

    function onNationChange(e) {
        setProductModalData(draft => {
            draft.nationValue = e.target.value
        })
    }

    function onSpecsChange(e) {
        setProductModalData(draft => {
            draft.specsValue = e.target.value
        })
    }

    function product_brand_id() {
        return props.state.product_instance.product_model !== null ? (
            props.state.product_instance.product_model.brand !== null ? props.state.product_instance.product_model.brand.id : null) : null
    }

    function product_category_id() {
        return props.state.product_instance.product_model !== null ? (
            props.state.product_instance.product_model.category !== null ? props.state.product_instance.product_model.category.id : null) : null
    }

    function product_model_id() {
        return props.state.product_instance.product_model === null ? null : props.state.product_instance.product_model.id
    }

    return (
        <Modal
            centered
            title={props.state.zoho_product === null ? "" : "Select Data to be Saved"}
            open={props.state.modal.isOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
                {
                    props.state.zoho_product === null ? `UPC ${props.state.product_instance !== null ? props.state.product_instance.UPC : ''} not found in ZOHO. Do you want to export this product to ZOHO?` :
                    <>
                        <p>{`UPC: ${props.state.product_instance !== null ? props.state.product_instance.UPC : ''}`}</p>
                        <span>Name: </span>
                        {product_name(props.state.product_instance) === props.state.zoho_product.name ? product_name(props.state.product_instance) :
                            <Radio.Group onChange={onNameChange} value={productModalData.nameValue}>
                                <Radio value={1}>{product_name(props.state.product_instance)}</Radio>
                                <Radio value={2}>{props.state.zoho_product.name}</Radio>
                            </Radio.Group>
                        }<br/>
                        <span>Brand: </span>
                        {product_brand_name(props.state.product_instance.product_model) === props.state.zoho_product.brand ? product_brand_name(props.state.product_instance.product_model) :
                            <Radio.Group onChange={onBrandChange} value={productModalData.brandValue}>
                                <Radio value={1}>{product_brand_name(props.state.product_instance.product_model)}</Radio>
                                <Radio value={2}>{props.state.zoho_product.brand}</Radio>
                            </Radio.Group>
                        }<br/>
                        <span>Category: </span>
                        {product_category_name(props.state.product_instance.product_model) === props.state.zoho_product.category ? product_category_name(props.state.product_instance.product_model) :
                            <Radio.Group onChange={onCategoryChange} value={productModalData.categoryValue}>
                                <Radio value={1}>{product_category_name(props.state.product_instance.product_model)}</Radio>
                                <Radio value={2}>{props.state.zoho_product.category}</Radio>
                            </Radio.Group>
                        }<br/>
                        <span>Spec: </span>
                        {product_nation(props.state.product_instance) === props.state.zoho_product.nation ? product_nation(props.state.product_instance) :
                            <Radio.Group onChange={onNationChange} value={productModalData.nationValue}>
                                <Radio value={1}>{product_nation(props.state.product_instance)}</Radio>
                                <Radio value={2}>{props.state.zoho_product.nation}</Radio>
                            </Radio.Group>
                        }<br/>
                        <span>PN: </span>
                        {product_part_number(props.state.product_instance) === props.state.zoho_product.part_number ? product_part_number(props.state.product_instance) :
                            <Radio.Group onChange={onPartNumberChange} value={productModalData.partNumberValue}>
                                <Radio value={1}>{product_part_number(props.state.product_instance)}</Radio>
                                <Radio value={2}>{props.state.zoho_product.part_number}</Radio>
                            </Radio.Group>
                        }<br/>
                        <span>Color: </span>
                        {product_color(props.state.product_instance) === props.state.zoho_product.color ? product_color(props.state.product_instance) :
                            <Radio.Group onChange={onColorChange} value={productModalData.colorValue}>
                                <Radio value={1}>{product_color(props.state.product_instance)}</Radio>
                                <Radio value={2}>{props.state.zoho_product.color}</Radio>
                            </Radio.Group>
                        }<br/>
                        <span>Desc: </span>
                        {product_specs(props.state.product_instance) === props.state.zoho_product.specs ? product_specs(props.state.product_instance) :
                            <Radio.Group onChange={onSpecsChange} value={productModalData.specsValue}>
                                <Radio value={1}>{cropText(props.state.product_instance.specs, 50)}</Radio>
                                <Radio value={2}>{cropText(props.state.zoho_product.specs, 50)}</Radio>
                            </Radio.Group>
                        }<br/>
                        <br />
                        <br />
                        <small>{"*DealCatcher <|> ZOHO"}</small><br />
                        <small>{"**Product will be updated in both ZOHO and DealCatcher"}</small>
                    </>
                }
        </Modal>
    )
}

export default ProductModal
