import { Spin, Space, Tooltip, Collapse } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { generateLighterColor, generateRandomPastelColor, getLocalTime } from '../Utils'
import '../App.css'


const { Panel } = Collapse


function MessagePanel(props) {
    
    const localIndex = props.messageData.localIndex
    const currMsgId = props.messageData.messageIndices[localIndex]
    const currMsgData = props.messageData.messageData[currMsgId]
    const numMessages = props.messageData.messageIndices.length

    function createSegments() {
        const segments = []
        let lastIndex = 0
        let dealIndex = 1
        let lighteningPercent = 0

        for (const [start, end] of currMsgData.deal_indices) {
            if (start > lastIndex) {
                segments.push({
                    content: currMsgData.msg_content.slice(lastIndex !== 0 ? lastIndex-1 : lastIndex, start),
                    color: '#FAF7F0',
                    idx: -1,
                })
            }

            segments.push({
                content: currMsgData.msg_content.slice(start, end),
                color: generateLighterColor('#F1D996', lighteningPercent),
                idx: dealIndex++,
            })
        
            lastIndex = end + 1
            lighteningPercent += 3
        }

        if (lastIndex < currMsgData.msg_content.length) {
            segments.push({
                content: currMsgData.msg_content.slice(lastIndex-1),
                color: '#FAF7F0',
                idx: -1,
            })
        }

        return segments
    }

    function highlightMessage() {
        const segments = createSegments()

        return (
            <div style={{marginTop: 0, }}>
                {segments.map((segment, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column', position: 'relative', }}>
                        {segment.idx === -1 ? 
                            <div
                                style={{
                                    backgroundColor: segment.color,
                                    marginLeft: '6px',
                                }}
                            >
                                <span className='withprewrap'>{segment.content}</span>
                            </div>
                        :
                            <div
                                style={{
                                    backgroundColor: segment.color,
                                    padding: '2px',
                                    marginLeft: '0px',
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                                //onMouseEnter={() => setHovered(true)}
                                //onMouseLeave={() => setHovered(false)}
                                onClick={() => props.setMessageData(draft => { draft.selectedDealId = currMsgData.deal_ids[segment.idx-1]})}
                            >
                                <p id={"spand-" + String(segment.idx)} className='withprewrap' style={{margin:2}}>{segment.content}</p>
                            </div>
                        }
                        {segment.idx === -1 ? "" :
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: -70,
                                    backgroundColor: segment.color,
                                    //color: 'white',
                                    padding: '1px',
                                    fontSize: '13px',
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                }}
                            >
                                Deal: {segment.idx}
                            </div>
                        }
                    </div>
                ))}
            </div>
        )
    }

    function prevMessage() {
        if (localIndex === 0 && props.dataSource.pagination.current === 1) return
        if (localIndex === 0) {
            props.setDataSource(draft => {
                draft.fromNext = true
                draft.pagination.current--
            })
        } else {
            props.setMessageData(draft => {
                draft.localIndex--
            })
        }
    }

    function nextMessage() {
        const numPages = Math.ceil(props.dataSource.pagination.total/props.dataSource.pagination.pageSize)
        if (localIndex === numMessages-1 && props.dataSource.pagination.current === numPages) return
        if (localIndex === numMessages-1) {
            props.setDataSource(draft => {
                draft.pagination.current++
            })
        } else {
            props.setMessageData(draft => {
                draft.localIndex++
            })
        }
    }

    if (currMsgData === undefined) return <></>

    if (props.dataSource.isLoading) return (
        <div style={{display: 'flex', alignItems:'center', justifyContent:'center', width: '400px', height:'300px'}}>
            <Spin />
        </div>
    )

    return (
        <Collapse
            size="small"
            bordered={false}
            defaultActiveKey={['1',]}
            style={{width: 400, fontSize: "13px", marginLeft:'0px', marginTop:props.marginTop, backgroundColor:'#FFEFA5'}}
        >
            <Panel header={`Selected Message (${currMsgId})`} key="1">
                <Space direction="vertical" size="small">
                    <p style={{margin: 3, marginTop: 'auto', fontSize: '14px', color:'#1677FF'}}>{`${currMsgData.vendor.full_name}`}</p>
                    {props.dataSource.isLoading ? "" :
                    <div className='message-panel-menu'>
                        <p style={{margin: 3, marginTop: 'auto', fontSize: '14px'}}>{`${getLocalTime(currMsgData.msg_time, false)}`}</p>
                        <div style={{marginLeft: 'auto', display:'flex', flexDirection:'column'}}>
                            <p style={{margin: 3, marginTop: 'auto', fontSize: '14px', }}>{`Num deals in page: ${currMsgData.deal_indices.length}`}</p>
                        </div>
                    </div>}
                    {props.dataSource.isLoading ? "" :
                    <div className='message-panel-menu'>
                        <div style={{marginRight: 'auto', display:'flex', flexDirection:'column'}}>
                            <div>
                                <Tooltip
                                    placement="left"
                                    title={<span>Previous Message/Page</span>}
                                >
                                    <CaretLeftOutlined
                                        style={{fontSize: '28px', color:'#6499c2', cursor:'pointer'}}
                                        onClick={()=>prevMessage()}
                                    />
                                </Tooltip>
                                <Tooltip
                                    placement="topRight"
                                    title={<span>Next Message/Page</span>}
                                >
                                    <CaretRightOutlined
                                        style={{fontSize: '28px', color:'#6499c2', cursor:'pointer'}}
                                        onClick={()=>nextMessage()}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <p style={{marginRight: 3, marginTop: '2px', marginRight: '4px', fontSize: '14px', userSelect:'none'}}>{`msg: ${localIndex+1}/${numMessages}`}</p>
                    </div>}
                    <div className='message-panel-no-scroll' style={{marginRight:'10px'}}>
                        {highlightMessage()}
                    </div>
                </Space>
            </Panel>
            {/*<Panel header="Contact Info" key="2">
                {"Detailed Contact Info will be here"}
            </Panel>*/}
        </Collapse>
    )
}

export default MessagePanel