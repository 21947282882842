import { useEffect, useContext } from 'react'
import { Space, Tooltip, Button, Spin } from "antd"
import { SaveOutlined, ExportOutlined} from '@ant-design/icons'
import Axios from "axios"
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

function DealActions(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    useEffect(() => {
        if (props.state.savingId === -1) return

        async function saveTableRow() {
            let isFailed = false
            props.setDataSource(draft => {
                draft.isLoading = true
            })
            //if (props.state.record !== null) {
            if (props.state.savingId !== -1) {
                let updateSuccessMsg = ""
                let piID = null

                const product_instance = props.state.record.product_instance
                const UPC = product_instance.UPC
                
                if (props.state.saveType === 'all' || props.state.saveType === 'product') {
                    /*** PRODUCT INSTANCE ***/
                    let new_product_data = {
                        'UPC': UPC === "" ? null : UPC,
                        'name': product_instance.name,
                        'color': product_instance.color,
                        'part_number': product_instance.part_number,
                        'nation': product_instance.nation,
                        'specs': product_instance.specs,
                        'is_generic': product_instance.is_generic,
                    }
                    if (product_instance.product_model !== null) {
                        new_product_data['product_model'] = product_instance.product_model.id
                    }

                    piID = product_instance.id

                    if (props.state.productInstance === null) {
                        try {
                            const response = await api.patch(
                                `update_product_instance/${piID}/`,
                                new_product_data,
                                appState.token.post_config
                            )
                            //console.log(response.data)
                            updateSuccessMsg = 'Product instance, '
                        } catch (err) {
                            //console.log(err)
                            appDispatch({
                                type: "error",
                                err: err,
                                data: "Unable to update product instance!"
                            })
                            isFailed = true
                        }
                    } else {
                        let pi = props.state.productInstance
                        let new_product_data = {
                            'UPC': pi.UPC === "" ? null : pi.UPC,
                            'name': pi.name,
                            'original_name': pi.name,
                            'color': pi.color,
                            'part_number': pi.part_number,
                            'nation': pi.nation,
                            'specs': pi.specs,
                            'is_generic': pi.is_generic,
                            'product_model': product_instance.product_model.id,
                        }
                        //console.log(new_product_data)
                        try {
                            const response = await api.post(
                                'create_product_instance/',
                                new_product_data,
                                //props.state.productInstance,
                                appState.token.post_config
                            )
                            piID = response.data.id
                            appDispatch({
                                type: "success",
                                data: "New product instance created"
                            })
                        } catch (err) {
                            //console.log(err)
                            appDispatch({
                                type: "error",
                                err: err,
                                data: "Unable to create product instance!"
                            })
                            isFailed = true
                        }
                    }
                }
                
                if (props.state.saveType === 'all' || props.state.saveType === 'vendor_export_deal') {
                    // COMPANY //
                    const company = props.state.record.vendor.company
                    if (company !== null) {
                        const new_company_data = {
                            'name': company.name,
                            'country': company.country !== null ? company.country.id : null
                        }
                        try {
                            const response = await api.patch(
                                `update_company/${company.id}/`,
                                new_company_data,
                                appState.token.post_config
                            )
                            //console.log(response.data)
                            updateSuccessMsg += 'Company, '
                        } catch (err) {
                            //console.log(err)
                            appDispatch({
                                type: "error",
                                err: err,
                                data: "Unable to update company!"
                            })
                            isFailed = true
                        }
                    }
                }

                if (
                    props.state.saveType === 'all' ||
                    props.state.saveType === 'vendor_export_deal' ||
                    props.state.saveType === 'vendor_spam' ||
                    props.state.saveType === 'vendor_unspam'
                ) {
                    // VENDOR //
                    const vendor = props.state.record.vendor
                    let new_vendor_data = {
                        'full_name': vendor.full_name,
                        'email': vendor.email,
                        'company': vendor.company !== null ? vendor.company.id : null,
                        'is_saved': true,
                    }
                    if (props.state.saveType === 'vendor_spam') {
                        new_vendor_data['is_spammer'] = true
                    } else {
                        new_vendor_data['is_spammer'] = false
                    }
                    try {
                        const response = await api.patch(
                            `update_vendor/${vendor.id}/`,
                            new_vendor_data,
                            appState.token.post_config
                        )
                        //console.log(response.data)
                        props.setFilterState(draft => {
                            draft.vendors.requestCount++
                        })
                        if (props.state.saveType === 'all' || props.state.saveType === 'vendor') {
                            updateSuccessMsg += 'Vendor, '
                        } else {
                            props.setFilterState(draft => {
                                draft.requestCount++
                            })
                        }
                        
                    } catch (err) {
                        //console.log(err)
                        appDispatch({
                            type: "error",
                            err: err,
                            data: "Unable to update vendor!"
                        })
                        isFailed = true
                    }
                }

                if (props.state.saveType === 'all') {
                    /*** DEAL ***/
                    const new_deal_data = {
                        'deal_type': props.state.record.deal_type,
                        'product_instance': piID,
                        'price': props.state.record.price,
                        'quantity': props.state.record.quantity,
                        'price_desc': props.state.record.price_desc,
                        'location': props.state.record.location,
                        'user_note': props.state.record.user_note,
                        //'status': props.state.record.status,
                        'status': 'SAVED',
                        //'zoho_id': props.state.record.zoho_id,
                    }

                    //console.log(new_deal_data)

                    try {
                        const response = await api.patch(
                            `update_deal/${props.state.record.id}/`,
                            new_deal_data,
                            appState.token.post_config
                        )
                        //console.log(response.data)
                        props.setIsUPCSet(props.state.record.id, (product_instance.UPC === "") ? false : true)
                        updateSuccessMsg += 'Deal, '
                    } catch (err) {
                        //console.log(err)
                        appDispatch({
                            type: "error",
                            err: err,
                            data: "Unable to update deal!"
                        })
                        isFailed = true
                    }
                }
                
                if (updateSuccessMsg.length > 0) {
                    appDispatch({
                        type: "success",
                        data: updateSuccessMsg.substring(0, updateSuccessMsg.length-2) + ' updated'
                    })
                }

                props.setState(draft => {
                    //draft.record = null
                    draft.savingId = -1
                })
            } else {
                appDispatch({
                    type: "error",
                    data: "Empty record!"
                })
                isFailed = true
            }

            if (isFailed) {
                props.setDataSource(draft => {
                    draft.isLoading = false
                })
                props.setFilterState(draft => {
                    draft.requestCount++
                })
                return
            }

            if (props.state.saveType === 'all' && !props.state.enableExport) { // save row
                props.setDataSource(draft => {
                    draft.isLoading = false
                })
                props.setFilterState(draft => {
                    //draft.colorRowId = props.state.record.id
                    draft.companies.requestCount++
                    draft.requestCount++
                })
            } else {
                if (props.state.saveType === 'all' || props.state.saveType === 'product') { // export to ZOHO or import product from ZOHO
                    props.setProductData(draft => {
                        draft.product_instance = props.state.record.product_instance
                        draft.key = props.state.record.key
                        draft.isFireVendor = props.state.enableExport
                        draft.requestCount++
                    })
                }
                else if (props.state.saveType === 'vendor_export_deal' || props.state.saveType === 'vendor') { // export to ZOHO continues or import vendor from ZOHO
                    props.setVendorData(draft => {
                        draft.vendor = props.state.record.vendor
                        draft.key = props.state.record.key
                        draft.isFireExport = props.state.enableExport
                        draft.requestCount++
                    })
                }
            }
        }
        saveTableRow()
        
    }, [props.saving])

    return (
        <Space size="small" >
            {props.contextHolder}
            {(props.saving) ? <Spin size="small" /> :
                props.displaySave ?
                <>
                    <Tooltip
                        placement="bottom"
                        title={<span>Save Changes</span>}
                    >
                        <Button
                            className='btn-style-red'
                            onClick={props.onSaveClick}
                            size="small"
                            icon={<SaveOutlined />}
                        />
                    </Tooltip>
                </>
                : ''
            }
            {props.displayExport ? <Tooltip
                placement="bottom"
                title={<span>Export Deal to ZOHO</span>/*Save Changes & Export to ZOHO*/}
            >
                <Button
                    className='btn-style-green'
                    onClick={props.onExportClick}
                    size="small"
                    icon={<ExportOutlined />}
                />
            </Tooltip> : ''}
        </Space>
    )
}

export default DealActions
