import { useContext } from "react"
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { LockOutlined } from '@ant-design/icons'

import '../App.css'

import StateContext from "../StateContext"


function ResetPassword() {

    const appState = useContext(StateContext)

    const navigate = useNavigate()

    function onFinish(values) {
        console.log(values)
        navigate('/')
    }

    //if (appState.loggedIn) return <></>

    return (
        <Form
            name='login'
            className='login-form'
            onFinish={onFinish}
        >
            <Form.Item
                name='password1'
                rules={[
                    {
                        required: true,
                        message: 'Please enter your password!'
                    }
                ]}
            >
                <Input prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='Password'/>
            </Form.Item>
            <Form.Item
                name='password2'
                rules={[
                    {
                        required: true,
                        message: 'Please enter your password!'
                    }
                ]}
            >
                <Input prefix={<LockOutlined className='site-form-item-icon' />} type='password' placeholder='Password Again'/>
            </Form.Item>
            <Form.Item>
                <Button type='primary' htmlType='submit' className='login-form-button'>
                    Reset
                </Button>
            </Form.Item>
        </Form>
    )
}

export default ResetPassword