import { useEffect } from 'react'
import { Spin } from 'antd'
import AntSelect from "./AntSelect"
import { NOT_AVAILABLE } from "../Constants"
import Axios from "axios"
import api from '../AxiosInstance'

function ProductModels(props) {
    if (props.state.isLoading) return <Spin size="small" />

    return (
        <AntSelect
            size="small"
            disabled={(props.state.payload.length === 0) || props.disabled}
            showSearch={props.showSearch}
            selected={(props.state.payload.length > 0) ? props.selected : ""}
            options={props.state.payload}
            onChange={props.onChange}
            width={props.width || 175}/>
    )
}

export default ProductModels

export function useFetchProductModels(appState, appDispatch, setProductModelState, filterOptions, setFilterOptions) {
    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchProductModels() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setProductModelState(draft => {
                draft.isLoading = true
            })
            setFilterOptions(draft => {
                draft.productModels.isLoading = true
            })
            try {
                const response = await api.get('list_product_models/', appState.token.get_config, { cancelToken: ourRequest.token })
                const productModelArray = response.data
                if (productModelArray.length > 0 && productModelArray[0].id !== NOT_AVAILABLE) {
                    productModelArray.unshift({id: NOT_AVAILABLE, name: NOT_AVAILABLE})
                }
                //console.log(`${productModelArray.length} product model found`)
                setProductModelState(draft => {
                    draft.payload = productModelArray
                })
                setFilterOptions(draft => {
                    draft.productModels.data = productModelArray
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch product models!"
                })
            }
            setProductModelState(draft => {
                draft.isLoading = false
            })
            setFilterOptions(draft => {
                draft.productModels.isLoading = false
            })
        }
        fetchProductModels()
        return () => {
            ourRequest.cancel()
        }
    }, [filterOptions.productModels.requestCount, appState.loggedIn,])
}