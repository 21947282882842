import { Modal } from "antd"

function ExportWarningModal(props) {

    function handleOk() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
        props.saveTableRow(props.state.record, true, 'all')
    }

    function handleCancel() {
        props.setState(draft => {
            draft.record = null
            draft.modal.isOpen = false
        })
        props.setDataSource(draft => {
            draft.isLoading = false
        })
    }

    return (
        <Modal
            centered
            title={""}
            open={props.state.modal.isOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
            <span>Did you check price and currency?</span>
        </Modal>
    )
}

export default ExportWarningModal
