import { useEffect } from "react"
import { Spin } from 'antd'
import AntSelect from "./AntSelect"
import Axios from "axios"
import api from "../AxiosInstance"


function DealStatuses(props) {
    if (props.state.isLoading) return <Spin size="small" />

    function getOptions() {
        if (props.zoho_id === null) {
            return props.state.payload.slice(0, 3) // CATCHED, IGNORED, SAVED
        } else{
            return props.state.payload.slice(3, 4) // EXPORTED
        }
    }

    return (
        <AntSelect
            size="small"
            disabled={props.selected === "EXPORTED" || props.disabled}
            selected={props.selected}
            options={getOptions()}
            onChange={props.onChange}
            width={120}/>
    )
}

export function useFetchDealStatuses(appState, appDispatch, setDealStatusState, filterOptions, setFilterOptions) {
    useEffect(() => {
        
        const ourRequest = Axios.CancelToken.source()

        async function fetchDealStatuses() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setDealStatusState(draft => {
                draft.isLoading = true
            })
            setFilterOptions(draft => {
                draft.statuses.isLoading = true
            })
            try {
                const response = await api.get('list_deal_statuses/', appState.token.get_config, { cancelToken: ourRequest.token })
                //console.log(`${response.data.length} statuses found`)
                setDealStatusState(draft => {
                    draft.payload = response.data
                })
                setFilterOptions(draft => {
                    draft.statuses.data = response.data
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch deal statuses!"
                })
            }
            setDealStatusState(draft => {
                draft.isLoading = false
            })
            setFilterOptions(draft => {
                draft.statuses.isLoading = false
            })
        }
        fetchDealStatuses()
        return () => {
            ourRequest.cancel()
        }
    }, [filterOptions.statuses.requestCount, appState.loggedIn,])
}

export default DealStatuses
