import { NOT_AVAILABLE } from "./Constants"
import { isValidEmail } from "./Utils"


function showWarning(warningMsg, setGenericWarningData) {
    if (warningMsg !== "") {
        setGenericWarningData(draft => {
            draft.title = warningMsg
            draft.modal.isOpen = true
        })
        return true
    }
    return false
}


export function checkProductFields(product_instance, setGenericWarningData) {
    let warningMsg = ""

    if (product_instance === null) {
        warningMsg = "Product not found!!!"
    } else if (product_instance.name === null || product_instance.name === "" || product_instance.name === NOT_AVAILABLE) {
        warningMsg = "Product Name can not be empty!!"
    } else if (product_instance.product_model === null) {
        warningMsg = "Product Category must be selected!!"
    } else if (product_instance.UPC === null || product_instance.UPC === "" || product_instance.UPC === NOT_AVAILABLE) {
        warningMsg = "UPC must be selected or entered!!"
    }

    return showWarning(warningMsg, setGenericWarningData)
}

export function checkVendorFields(vendor, setGenericWarningData) {
    let warningMsg = ""

    if (vendor === null) {
        warningMsg = "Vendor not found !!!"
    } else if (vendor.full_name === null || vendor.full_name === "" || vendor.full_name === NOT_AVAILABLE) {
        warningMsg = "Contact Name can not be empty!!"
    } else if (!isValidEmail(vendor.email)) {
        warningMsg = "Wrong Email format!!"
    } else if (vendor.company === null || vendor.company.name === "" || vendor.company.name === NOT_AVAILABLE) {
        warningMsg = "Company must be selected!!"
    }

    return showWarning(warningMsg, setGenericWarningData)
}

export function checkDealFields(record, setGenericWarningData) {
    let warningMsg = ""

    if (record === null) {
        warningMsg = "Record not found!!!"
    } else if (record.quantity === null || record.quantity === "" || record.quantity === 0) {
        warningMsg = "Quantity must be entered!!"
    } else if (record.location === null || record.location === "" || record.location === NOT_AVAILABLE) {
        warningMsg = "Location must be entered!!"
    }

    return showWarning(warningMsg, setGenericWarningData)
}