import { useContext } from 'react'
import { DatePicker, Space, Tooltip, Button } from "antd"
import { DownloadOutlined, FileSyncOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import AntSelect from "./AntSelect"
import { useImmer } from "use-immer"
import { getOneMinuteBeforeUTC } from "../Utils"
import { DATE_FILTER_OPTONS, LAST_WEEK, LAST_2_WEEKS, LAST_MONTH, LAST_3_MONTHS } from "../Constants"

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'


const { RangePicker } = DatePicker


function MessagesSearchBar(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [selectedStartIndex, setSelectedStartIndex] = useImmer({value: 3})

    function fetchMessages() {
        if (props.dataSource.pagination.current === 1) {
            props.setFilterOptions(draft => {
                draft.requestCount++
            })
        } else {
            props.setDataSource(draft => {
                draft.pagination.current = 1
            })
        }
    }

    function onRangeChange(value) {
        if (value === null) {
            props.setFilterOptions(draft => {
                draft.startDate = null
                draft.endDate = null
            })
            fetchMessages()
        }
    }

    function onRangeSelected(value) {
        if (value[0] === null || value[1] === null) return
        const startDate = new Date(value[0]['$d']).toUTCString()
        const endDate = new Date(value[1]['$d']).toUTCString()
        //console.log(startDate)
        props.setFilterOptions(draft => {
            draft.startDate = startDate
            draft.endDate = endDate
        })
        fetchMessages()
    }

    function onRangeChange2(value) {
        setSelectedStartIndex(draft => {
            draft.value = DATE_FILTER_OPTONS.indexOf(value)
        })
        let startDate = null
        if (value === "Last week") {
            startDate = new Date(new Date().getTime() - LAST_WEEK).toUTCString()
        } else if (value === "Last 2 weeks") {
            startDate = new Date(new Date().getTime() - LAST_2_WEEKS).toUTCString()
        } else if (value === "Last month") {
            startDate = new Date(new Date().getTime() - LAST_MONTH).toUTCString()
        } else if (value === "Last 3 months") {
            startDate = new Date(new Date().getTime() - LAST_3_MONTHS).toUTCString()
        }
        props.setFilterOptions(draft => {
            draft.startDate = startDate
        })
        fetchMessages()
    }

    function fetchNewMessages() {
        props.setDataSource(draft => {
            draft.queryDateTime = getOneMinuteBeforeUTC() //new Date().toUTCString()
        })
        fetchMessages()
    }

    function refreshPage() {
        props.setFilterOptions(draft => {
            draft.vendors.requestCount++
            //draft.companies.requestCount++
            draft.countries.requestCount++
            draft.regions.requestCount++
            draft.requestCount++
        })
    }

    function previous_page() {
        if (props.dataSource.pagination.current === 1) return
        props.setDataSource(draft => {
            draft.pagination.current--
        })
    }

    function next_page() {
        const numPages = Math.ceil(props.dataSource.pagination.total/props.dataSource.pagination.pageSize)
        if (props.dataSource.pagination.current === numPages) return
        props.setDataSource(draft => {
            draft.pagination.current++
        })
    }

    return (
        <div className='search-bar'>
            {/*<RangePicker
                disabled={props.disabled}
                showTime
                onOk={onRangeSelected}
                onChange={onRangeChange}/>*/}
            <AntSelect 
                size="small"
                selected={DATE_FILTER_OPTONS[selectedStartIndex.value]}
                options={DATE_FILTER_OPTONS}
                onChange={(newValue) => { 
                    onRangeChange2(newValue)
                }}
                width={200}
            />
            <strong style={{paddingRight:'5px'}}>
                {props.dataSource.currentTable === "Messages" ?
                    `${props.dataSource.pagination.total} messages from ${props.dataSource.vendor_count} contacts found` :
                    `${props.dataSource.pagination.total} contacts found`
                }
            </strong>
            {appState.user.role === "GUEST" ? "" :
            <Button onClick={props.onTableChangeClicked} type='link'>{props.dataSource.currentTable === "Messages" ? "List Contacts" : "List Messages"}</Button>
            }
            <Space size="small" style={{marginLeft: 'auto', paddingRight:'9px'}}>
                {props.contextHolder}
                <Tooltip title="Previous Page" placement="top">
                    <Button className='btn-style-blue' size='small' icon={<LeftOutlined />}
                        onClick={(e) => {
                            previous_page()
                        }} />
                </Tooltip>
                <Tooltip title="Next Page" placement="top">
                    <Button className='btn-style-blue' size='small' icon={<RightOutlined />}
                        onClick={(e) => {
                            next_page()
                        }} />
                </Tooltip>
                <div style={{width: '25px'}}/>
                <Tooltip
                    placement="top"
                    title={<span>Fetch New Messages</span>}
                >
                    <Button
                        disabled={props.disabled}
                        className="btn-style"
                        onClick={fetchNewMessages}
                        size="small"
                        icon={<DownloadOutlined />}
                    />
                </Tooltip>
                <Tooltip
                    placement="top"
                    title={<span>Reload Data</span>}
                >
                    <Button
                        disabled={props.disabled}
                        className="btn-style-red"
                        onClick={refreshPage}
                        size="small"
                        icon={<FileSyncOutlined />}
                    />
                </Tooltip>
            </Space>
        </div>
    )
}

export default MessagesSearchBar