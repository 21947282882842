import { Card, Space } from 'antd'
import { getLocalTime } from "../Utils"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment'


function ReachScreenOffers(props) {

    const groupByTime = (dateTimeList) => {
        const grouped = {}
    
        for (let i = dateTimeList.length - 1; i >= 0; i--) {
            const datetime = dateTimeList[i]
            const time = moment(datetime, 'DD.MM.YYYY HH:mm:ss')
            const timeStr = time.startOf('day').format('YYYY-MM-DD')
            if (!grouped[timeStr]) {
                grouped[timeStr] = 0
            }
            grouped[timeStr] += 1
        }
    
        return Object.entries(grouped).map(([time, count]) => ({
            datetime: time,
            value: count,
        }))
    }

    const customDot = (props) => {
        const { cx, cy } = props
        return <circle key={cx+cy+""} cx={cx} cy={cy} r={6} fill="#8884d8" /> // Change r value for size
    }

    function displayChart(arr) {
        if (arr.length === 0) return
        const dateTimeList = arr.map(item => {
            return item[1]
        })
        const data = groupByTime(dateTimeList)

        return (
            <ResponsiveContainer width="100%" height={200}>
                <LineChart data={data} margin={{ top: 20, right: 10, left: -20, bottom: 10 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="datetime" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} dot={customDot} />
                </LineChart>
            </ResponsiveContainer>
        )
    }

    function listItems(arr) {
        if (arr.length === 0) {
            return <p>---</p>
        }
        const res = arr.map((item, index) => {
            const numSent = item[2] === "-" ? "" : `(${item[2]})`
            return (
                <div key={index} style={{backgroundColor:'whitesmoke'}}>
                    <div style={{fontWeight:'bold'}}>{`> ${item[1]}`}</div>
                    <span style={{marginLeft:'16px'}}>{`${item[0]} ${numSent}`}</span>
                    <div style={{height:'6px'}} />
                </div>
            )
        })
        return res
    }

    function renderOffers() {
        const userToOfferMap = props.getIndividualOffers(props.dataSource.selectedItemIndex)

        const reachsComponent = Object.entries(userToOfferMap).map((item, index) => {
            const numBigGroups = item[1].bigGroups.length
            const numSmallGroups = item[1].smallGroups.length
            const numDMs = item[1].dms.length
            const total = numBigGroups+numSmallGroups+numDMs
            if (total === 0) return ""
            return (
                <Card
                    key={index}
                    title={`${item[0]} (${total})`}
                    style={{
                        width: 300,
                    }}
                    >
                    {numBigGroups === 0 ? "" : <>
                        <strong style={{backgroundColor: numBigGroups < props.dataSource.minNumGroups ? "#FF4D4F" : "#73D673", padding:5, borderRadius:3}}>{`Big Groups (${numBigGroups})`}</strong>
                        <div style={{height:'8px'}} />
                        <div style={{maxHeight:'300px', overflow:'auto'}}>{listItems(item[1].bigGroups)}</div>
                        <div >{displayChart(item[1].bigGroups)}</div>
                        <div style={{height:'16px'}} /> </>}
                    {numSmallGroups === 0 ? "" : <>
                        <strong style={{backgroundColor: numSmallGroups < props.dataSource.minNumGroups ? "#FF4D4F" : "#73D673", padding:5, borderRadius:3}}>{`Small Groups (${numSmallGroups})`}</strong>
                        <div style={{height:'8px'}} />
                        <div style={{maxHeight:'300px', overflow:'auto'}}>{listItems(item[1].smallGroups)}</div>
                        <div >{displayChart(item[1].smallGroups)}</div>
                        <div style={{height:'16px'}} /> </>}
                    {numDMs === 0 ? "" : <>
                        <strong style={{backgroundColor: numDMs < props.dataSource.minNumDMs ? "#FF4D4F" : "#73D673", padding:5, borderRadius:3}}>{`Direct Messages (${numDMs})`}</strong>
                        <div style={{height:'8px'}} />
                        <div style={{maxHeight:'300px', overflow:'auto'}}>{listItems(item[1].dms)}</div>
                        <div >{displayChart(item[1].dms)}</div> </>}
                </Card>
            )
        })

        return reachsComponent
    }

    return (
        
        <div style={{display: 'flex', flexDirection:'column', paddingLeft:'10px', paddingTop:'10px', paddingBottom:'10px'}}>
            <div className='grid-container-offers'>
                {renderOffers()}
            </div>
        </div>
    )
}

export default ReachScreenOffers