import { useEffect, useContext } from 'react'
import { useImmer } from "use-immer"
import { Space, Button, Tooltip, Input, Spin, message } from 'antd'
import { CopyOutlined, UpOutlined, DownOutlined, HighlightOutlined, SearchOutlined } from '@ant-design/icons'
//import { ContextMenuTrigger, ContextMenu, ContextMenuItem } from 'rctx-contextmenu'
import { cropText, getLocalTime, isValidEmail, isItemInArrayIgnoreCase, copyToClipboard } from '../Utils'
import { NOT_AVAILABLE } from "../Constants"
import SearchProductResultsModal from './SearchProductResultsModal'

import Axios from "axios"
import api from '../AxiosInstance'

import '../App.css'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

const { TextArea } = Input

function ExpandablePanel(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [messageApi, contextHolder] = message.useMessage()
    const [panelData, setPanelData] = useImmer({
        userNotes: [],
        isLoading: true,
    })
    const [dealData, setDealData] = useImmer({deal: null, selectedText: '', requestCount: 0})
    const [companyData, setCompanyData] = useImmer({company: {'name':'', 'country':null}, requestCount: 0})
    const [indexData, setIndexData] = useImmer({indices: null, requestCount: 0})
    const [orders, setOrders] = useImmer({data: []})
    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
            //className: 'no-trans',
            duration: '1.',
        })
    }
    //const [searchIn, setSearchIn] = useImmer({data: "DC"})
    const [searchProductData, setSearchProductData] = useImmer({data:null, requestCount:0})
    const [searchProductResults, setSearchProductResults] = useImmer({data:null, modal: {isOpen: false}})

    /*function onChangeSearchIn(e) {
        setSearchIn(draft => {
            draft.data = e
        })
    }*/

    useEffect(() => {
        if (searchProductData.requestCount === 0) return

        props.setLoading(true)

        const ourRequest = Axios.CancelToken.source()

        async function searchProducts() {
            try {
                let url = 'create_product_instance/'
                let sym = '?'
                if (searchProductData.data.part_number != "") {
                    url += `${sym}part_number=${searchProductData.data.part_number}`
                    sym = '&'
                }
                if (searchProductData.data.product_name != "") {
                    url += `${sym}product_name=${searchProductData.data.product_name}`
                    sym = '&'
                }
                if (searchProductData.data.color != "") {
                    url += `${sym}color=${searchProductData.data.color}`
                    sym = '&'
                }
                const response = await api.get(url, appState.token.get_config, { cancelToken: ourRequest.token })
                const dataSourceWithKey = response.data.map((row) => {
                    return {
                        ...row,
                        'key': row['id'], /* rowKey="id" not working */
                    }
                })
                //console.log(response.data)
                setSearchProductResults(draft => {
                    draft.data = dataSourceWithKey
                    draft.modal.isOpen = true
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: `Unable to search products: ${err.response.data.name[0]}`
                })
                props.setLoading(false)
            }
        }
        searchProducts()

        return () => {
            ourRequest.cancel()
        }
    }, [searchProductData.requestCount])


    useEffect(() => {
        if (props.record.message === null) return
        const ourRequest = Axios.CancelToken.source()

        async function fetchOrders() {
            setPanelData(draft => {
                draft.isLoading = true
            })
            try {
                const response = await api.get(
                    `get_orders_by_message_id/${props.record.message.id}/`, appState.token.get_config, { cancelToken: ourRequest.token })
                setOrders(draft => {
                    draft.data = response.data
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to get message orders!"
                })
            }
            setPanelData(draft => {
                draft.isLoading = false
            })
        }
        fetchOrders()

        //fetchUserNotes() {} // FUTURE

        return () => {
            ourRequest.cancel()
        }
    }, [props.record.id, props.filterOptions.requestCount])

    useEffect(() => {
        if (dealData.requestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function createNewDeal() {
            let colorRowId = -1
            let piID = null

            try {
                const response = await api.post(
                    'create_product_instance/',
                    {'name': dealData.selectedText, 'original_name': dealData.selectedText},
                    appState.token.post_config
                )
                //('success', 'New product instance created')
                piID = response.data.id
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to create product instance!"
                })
            }

            let new_deal_data  = { ...dealData.deal }
            new_deal_data['product_instance'] = piID

            try {
                const response = await api.post(
                    'list_deals/',
                    new_deal_data,
                    appState.token.post_config
                )
                colorRowId = response.data.id
                //('success', 'New deal created')
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to create new deal!"
                })
            }

            if (colorRowId !== -1) {
                props.setFilterOptions(draft => {
                    draft.colorRowId = colorRowId
                    draft.resetRowSpan = true
                    draft.requestCount++
                })
                let msg = "New deal "
                if(piID !== null) {
                    msg += " and product instance "
                }
                appDispatch({
                    type: "success",
                    data: msg + "created"
                })
            }
        }
        createNewDeal()

        return () => {
            ourRequest.cancel()
        }
    }, [dealData.requestCount])


    useEffect(() => {
        if (indexData.requestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function updateIndices() {
            try {
                const response = await api.patch(
                    `update_deal/${props.record.id}/`,
                    indexData.indices,
                    appState.token.post_config
                )
                appDispatch({
                    type: "success",
                    data: "Deal indices updated"
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to update deal!"
                })
            }
            props.setFilterOptions(draft => {
                draft.requestCount++
            })
        }
        updateIndices()

        return () => {
            ourRequest.cancel()
        }
    }, [indexData.requestCount])

    useEffect(() => {
        if (companyData.requestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function createNewCompany() {
            try {
                const response = await api.post(
                    'list_companies/',
                    companyData.company,
                    appState.token.post_config
                )
                appDispatch({
                    type: "success",
                    data: "New company created"
                })
                let tempArr = [...props.filterOptions.companies.data]
                tempArr.push(response.data)
                props.setFilterOptions(draft => {
                    draft.companies.data = tempArr
                })
                props.updateField('new_company', props.record.key, response.data)
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: `Unable to create new company`
                })
            }
        }
        createNewCompany()

        return () => {
            ourRequest.cancel()
        }
    }, [companyData.requestCount])

    function scrollToText() {
        const highlightNode = document.getElementById("span-" + String(props.record.id))
        if (highlightNode !== null) {
            const container = highlightNode.parentNode.parentNode
            container.scrollTo(0, highlightNode.offsetTop + highlightNode.offsetHeight / 2 - container.offsetHeight / 2)
        }
    }
    
    function highlightMessage(message) {
        scrollToText()
        const plainText1 = message.substring(0, props.record.msg_start_index)
        const highlightedText = message.substring(props.record.msg_start_index, props.record.msg_end_index)
        const plainText2 = message.substring(props.record.msg_end_index)
        return (
            <>
                {plainText1}
                <span id={"span-" + String(props.record.id)} style={{backgroundColor:'#F8B501'}}>{highlightedText}</span>
                {plainText2}
            </>
        )
    }

    function getStartAndEndIndices() {
        const pEl = document.getElementById("msg-" + String(props.record.id))
        const selection = window.getSelection()

        let start = 0
        for (const childNode of pEl.childNodes) {
            if (childNode === null || selection === null || selection.anchorNode === null) {
                return [0, 0, ""]
            }
            if (childNode.textContent === selection.anchorNode.textContent) {
                start += selection.anchorOffset
                break
            } else {
                start += childNode.textContent.length
            }
        }
        // const end = selection.focusOffset
        return [start, start + selection.toString().length, selection.toString()]
    }

    function countDecimals(val) {
        const arr = val.toString().split(".")
        if (arr.length !== 2) return 0
        const decimal = arr[1]
        let counter = 0
        for (let i = 0; i < decimal.length; i++) {
            if (decimal[i] === "0") return counter
            counter++
        }
        return counter
    }

    function addOrUpdateDeal(loc) {
        const start_end = getStartAndEndIndices()
        const start = start_end[0]
        const end = start_end[1]
        const selectedText  = start_end[2]

        //console.log(start, end)
        //console.log(selectedText)

        if (start >= 0 && start < end && selectedText !== "") {
            if (loc === 'update') {
                const new_deal_data = {
                    'msg_start_index': start,
                    'msg_end_index': end,
                }
                setIndexData(draft => {
                    draft.indices = new_deal_data
                    draft.requestCount++
                })
            } else {
                const timeCatched = new Date(Date.parse(props.record.time_catched))
                let currTimeCatched = timeCatched.getTime()
                if (loc === 'above') currTimeCatched--
                if (loc === 'below') currTimeCatched++
                currTimeCatched = new Date(currTimeCatched).toISOString()

                let newOrder = -1
                const order = Number(props.record.order)
                const numDecimals = countDecimals(order)
                
                if (numDecimals >= 9) {
                    appDispatch({
                        type: "error",
                        err: null,
                        data: "Maximum number of deals are reached in this range!"
                    })
                    return
                }

                const floatOrders = orders.data.map(Number)
                const sortedOrders = floatOrders.sort()
                const orderIndex = sortedOrders.indexOf(order)

                if (orderIndex === 0 && loc === 'above') {
                    newOrder = order / 2.
                } else if (orderIndex === floatOrders.length - 1 && loc === 'below') {
                    newOrder = (order + Math.floor(order + 1)) / 2.
                } else {
                    if (loc === 'above') {
                        newOrder = (sortedOrders[orderIndex - 1] + order) / 2.
                    } else {
                        newOrder = (order + sortedOrders[orderIndex + 1]) / 2.
                    }
                }
                //console.log(newOrder)

                const new_deal_data = {
                    //'product_instance': props.record.product_instance.id,
                    'deal_type': props.record.deal_type,
                    'price_currency': props.record.price_currency,
                    'price_desc': props.record.price_desc,
                    'location': props.record.location,
                    'vendor': props.record.vendor.id,
                    'message': props.record.message.id,
                    'time_catched': currTimeCatched,
                    'msg_start_index': start,
                    'msg_end_index': end,
                    'order': newOrder,
                }
                setDealData(draft => {
                    draft.selectedText = selectedText
                    draft.deal = new_deal_data
                    draft.requestCount++
                })
            }
        } else {
            appDispatch({
                type: "error",
                err: null,
                data: "Please select related text before creating or updating a deal!"
            })
        }
    }

    function sendMessage(phoneNumber, message) {
        const encodedMessage = encodeURIComponent(message)
        //const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`
        const whatsappUrl = `https://web.whatsapp.com/send/?phone=${phoneNumber}&text&type=phone_number&app_absent=0`
        window.open(whatsappUrl, '_blank')
    }

    function searchProduct() {
        const searchFields = {
            'part_number': props.record.product_instance.part_number,
            'product_name': props.record.product_instance.name,
            'color': props.record.product_instance.color
        }
        setSearchProductData(draft => {
            draft.data = searchFields
            draft.requestCount++
        })
    }

    function setProduct(selectedProduct) {
        props.setProduct(selectedProduct, props.record.key)
    }

    function locationBorderColor(record) {
        if (record.location === null || record.location === "")
            return "#ff7875"
        return ""
    }

    if (panelData.isLoading) return (
        <div style={{display: 'flex', alignItems:'center', justifyContent:'center', height:'200px'}}>
            <Spin />
        </div>
    )

    if (props.record.message === null) return <p>Message Not Found!!!</p>

    return (
        <Space size="small" style={{backgroundColor:'#faf7f0', width:'100%'}}>
            <SearchProductResultsModal
                state={searchProductResults}
                setState={setSearchProductResults}
                setLoading={props.setLoading}
                setProduct={setProduct}
            />
            {/*<ContextMenu id={"cm-" + String(props.record.id)} appendTo="body" className='my-context-menu'>
                <ContextMenuItem onClick={() => addOrUpdateDeal('above')}><UpOutlined />Add Deal Above</ContextMenuItem>
                <ContextMenuItem onClick={() => addOrUpdateDeal('below')}><DownOutlined />Add Deal Below</ContextMenuItem>
                {props.isFieldDisabled(props.record) ? <ContextMenuItem /> :
                <ContextMenuItem onClick={() => addOrUpdateDeal('update')}><HighlightOutlined />Update Highlighting</ContextMenuItem>}
            </ContextMenu>*/}
            <Space direction="horizontal" size="small" align='start'>
                <Space direction="vertical" size="small">
                    <div className='message-panel-menu'>
                        {contextHolder}
                        <Tooltip title="Copy Message to Clipboard" placement="bottom">
                            <Button className='message-panel-button btn-style' size='small' icon={<CopyOutlined />}
                                onClick={(e) => {
                                    //copyToClipboard(cropText(props.record.message.cleaned_content, 100))
                                    copyToClipboard(props.record.message.cleaned_content)
                                    success("Copied!")
                                }} />
                        </Tooltip>
                        <div style={{width:"15px"}} />
                        <Tooltip title="Add Deal Above" placement="bottom">
                            <Button className='message-panel-button btn-style-blue' size='small' icon={<UpOutlined />}
                                onClick={(e) => {
                                    addOrUpdateDeal('above')
                                }} />
                        </Tooltip>
                        <Tooltip title="Add Deal Below" placement="bottom">
                            <Button className='message-panel-button btn-style-blue' size='small' icon={<DownOutlined />}
                                onClick={(e) => {
                                    addOrUpdateDeal('below')
                                }} />
                        </Tooltip>
                        <Tooltip title="Update Highlighting" placement="bottom">
                            <Button className='message-panel-button btn-style-blue' size='small' icon={<HighlightOutlined />}
                                onClick={(e) => {
                                    addOrUpdateDeal('update')
                                }} />
                        </Tooltip>
                        {/*<Tooltip title="Open Message in New Tab" placement="bottom">
                            <Button className='message-panel-button' size='small' icon={<SelectOutlined rotate={90} />}
                                onClick={(e) => {
                                    console.log('open message')
                                }} />
                        </Tooltip>*/}
                        <div style={{marginLeft: 'auto', display:'flex', flexDirection:'column'}}>
                            <p style={{margin: 0, marginTop: 'auto', fontSize: '14px'}}><strong>{`msgID: ${props.record.message.id}`}</strong></p>
                        </div>
                    </div>
                    <div className='message-panel'>
                        {/*<p style={{margin: 0, fontStyle: 'italic', fontWeight: 'bold'}}>
                            {`${props.record.vendor.phone_number} wrote on ${new Date(Date.parse(props.record.message.message_time)).toLocaleString()} :`}
                            </p>*/}
                        <p id={"msg-" + String(props.record.id)} className='withprewrap'>
                            {highlightMessage(props.record.message.cleaned_content)}
                        </p>
                    </div>
                    {/*<ContextMenuTrigger
                        id={"cm-" + String(props.record.id)}>
                        <div className='message-panel'>
                            <p id={"msg-" + String(props.record.id)} className='withprewrap'>
                                {highlightMessage(props.record.message.cleaned_content)}
                            </p>
                        </div>
                    </ContextMenuTrigger>*/}
                </Space>
                <div style={{paddingLeft:'30px', paddingTop:'4px', display:'flex', flexDirection:'column', gap:'4px'}}>
                    <div className='other-deal-info' style={{height: '35px', fontSize:'12px'}}>
                        <strong>Inco:</strong>
                        <Input value={props.record.price_desc}
                                disabled={props.record.zoho_id !== null || props.isFieldDisabled(props.record)}
                                //onPressEnter={(e) => {props.updateField('price_desc', props.record.key, e)}}
                                //onBlur={(e) => {props.updateField('price_desc', props.record.key, e)}}
                                onChange={(e) => {props.updateField('price_desc', props.record.key, e)}}
                                size='small' style={{width:'120px', marginLeft:'2px'}}/>
                        <strong>Location:</strong>
                        <Input value={props.record.location}
                                disabled={props.record.zoho_id !== null || props.isFieldDisabled(props.record)}
                                //onPressEnter={(e) => {props.updateField('location', props.record.key, e)}}
                                //onBlur={(e) => {props.updateField('location', props.record.key, e)}}
                                onChange={(e) => {props.updateField('location', props.record.key, e)}}
                                size='small' style={{width:'120px', borderColor:locationBorderColor(props.record)}}/>
                        <div style={{marginLeft: 'auto', }}>
                            {/*<Select
                                    size="small"
                                    value={searchIn.data}
                                    onChange={(e) => {onChangeSearchIn(e)}}
                                    options={[{"label": "DC", "value": "DC"}, {"label": "ZOHO", "value": "ZOHO"}]}
                            style={{width: '105px', }}/>*/}
                            {props.record.status === "EXPORTED" || props.isFieldDisabled(props.record) ? "" :
                            <Tooltip title={'Search Product w/ Name, PN, Color'} placement="bottom"> {/*`Search Product in ${searchIn.data}`*/}
                                <Button className='message-panel-button btn-style-green' size='small' icon={<SearchOutlined />}
                                        style={{marginRight:'2px'}}
                                        onClick={(e) => {
                                            searchProduct()
                                        }} />
                            </Tooltip>}
                        </div>
                    </div>
                    <div className='other-deal-info' style={{height: '35px', fontSize:'12px'}}>
                        <strong style={{textDecoration:'underline'}}>Spec:</strong>
                        <Input value={props.record.product_instance === null ? '' : props.record.product_instance.nation}
                                disabled={props.record.product_instance === null || props.record.zoho_id !== null || props.isFieldDisabled(props.record)}
                                //onPressEnter={(e) => {props.updateField('nation', props.record.key, e)}}
                                //onBlur={(e) => {props.updateField('nation', props.record.key, e)}}
                                onChange={(e) => {props.updateField('nation', props.record.key, e)}}
                                size='small' style={{width:'121px', }}/>
                        <strong style={{textDecoration:'underline'}}>PN:</strong>
                        <Input value={props.record.product_instance === null ? '' : props.record.product_instance.part_number}
                                disabled={props.record.product_instance === null || props.record.zoho_id !== null || props.isFieldDisabled(props.record)}
                                //onPressEnter={(e) => {props.updateField('part_number', props.record.key, e)}}
                                //onBlur={(e) => {props.updateField('part_number', props.record.key, e)}}
                                onChange={(e) => {props.updateField('part_number', props.record.key, e)}}
                                size='small' style={{width:'153px', }}/>
                        <strong style={{textDecoration:'underline'}}>Color:</strong>
                        <Input value={props.record.product_instance === null ? '' : props.record.product_instance.color}
                                disabled={props.record.product_instance === null || props.record.zoho_id !== null || props.isFieldDisabled(props.record)}
                                //onPressEnter={(e) => {props.updateField('color', props.record.key, e)}}
                                //onBlur={(e) => {props.updateField('color', props.record.key, e)}}
                                onChange={(e) => {props.updateField('color', props.record.key, e)}}
                                size='small' style={{width:'136px', marginRight:'2px'}}/>
                    </div>
                    <div className='other-deal-info' style={{height: '110px', fontSize:'12px', paddingBottom:'3px'}}>
                        <strong style={{textDecoration:'underline'}}>Desc:</strong>
                        <TextArea
                            disabled={props.record.product_instance === null || props.record.zoho_id !== null || props.isFieldDisabled(props.record)}
                            rows={4}
                            value={props.record.product_instance === null ? '' : props.record.product_instance.specs}
                            onChange={(e) => {props.updateField('specs', props.record.key, e)}}
                            style={{fontSize:'14px', /*width:'515px',*/ resize:'none', marginRight:'2px'}}/>
                    </div>
                    <small />
                    <small />
                </div>
                <Space direction="vertical" size="small" style={{paddingLeft:'85px', paddingTop:'10px'}}>
                    <TextArea
                        placeholder='External Notes for Deal'
                        disabled={props.record.product_instance === null || props.record.zoho_id !== null || props.isFieldDisabled(props.record)}
                        rows={6}
                        defaultValue={props.record.user_notes}
                        onPressEnter={(e) => {props.updateField('user_note', props.record.key, e)}}
                        onBlur={(e) => {props.updateField('user_note', props.record.key, e)}}
                        style={{fontSize:'13px', width:'350px', marginLeft:'-50px', minHeight:'175px' /*resize:'none',*/ }}/>
                </Space>
            </Space>
        </Space>
    )
}

export default ExpandablePanel
