import { useEffect, useContext } from "react"
import { useNavigate } from 'react-router-dom'
import { useImmer } from "use-immer"
import { Form, Input, Button } from 'antd'
import { MailOutlined } from '@ant-design/icons'

import Axios from "axios"
import api from "../AxiosInstance"

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'

function Forgot() {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const navigate = useNavigate()
    const [forgotData, setForgotData] = useImmer({email:null, requestCount:0, })

    useEffect(() => {
        if (forgotData.requestCount === 0) return

        async function forgot() {
            try {
                const response = await api.post('users/reset_password/', forgotData.email)
                appDispatch({
                    type: "success",
                    data: "Please check your email"
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: null,
                    data: "Error sending email!"
                })
            }
        }
        forgot()
    }, [forgotData.requestCount])

    function onFinish(values) {
        setForgotData(draft => {
            draft.email = values.email
            draft.requestCount++
        })
    }

    function backToHomePage() {
        navigate('/')
    }

    if (appState.loggedIn) return <></>

    return (
        <Form
            name='login'
            className='login-form'
            style={{paddingTop:'25px', paddingBottom:'0px'}}
            onFinish={onFinish}
        >
            <h3 style={{width:'100%', textAlign:'center', paddingBottom: '10px'}}>Password Reset</h3>
            <Form.Item
                name='email'
                rules={[
                    {
                        required: true,
                        type: 'email',
                        message: 'Please enter a valid email address!'
                    },
                ]}
            >
                <Input prefix={<MailOutlined className='site-form-item-icon' />} placeholder='Email'/>
            </Form.Item>
            <Form.Item>
                <Button type='primary' htmlType='submit' className='login-form-button'>
                    Send
                </Button>
            </Form.Item>
            <Form.Item>
                <a className='login-form-forgot' onClick={backToHomePage}>
                    Home Page
                </a>
            </Form.Item>
        </Form>
    )
}

export default Forgot