import { useEffect, useContext } from 'react'
import { useImmer } from "use-immer"
import { Space, Tooltip, Spin } from "antd"
import { HeartOutlined, HeartFilled, DislikeOutlined, DislikeFilled } from '@ant-design/icons'
import Axios from "axios"
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import '../App.css'
import { splitArrToFields } from '../Utils'


function MessageActions(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [actionData, setActionData] = useImmer({ isLoading: false, messageId: -1, actionType: '', requestCount: 0 })

    function haveUserDoneAction(arr, actionType, username) {
        let result = false
        arr.forEach(item => {
            if (item['action'] === actionType && item['custom_user__full_name'] === username) {
                result = true
                return
            }
        })
        return result
    }

    function updateTableData() {
        const index = props.dataSource.messages.findIndex((item) => props.record.message.id === item.key)
        let message_actions = props.dataSource.messages[index].message_actions

        if (appState.user.role !== "DATA MANAGER") {
            if (message_actions.includes(actionData.actionType)) {
                message_actions = message_actions.filter(item => item !== actionData.actionType)
            } else {
                let tempArr = [...message_actions]
                tempArr.push(actionData.actionType)
                message_actions = tempArr
            }
        } else {
            const checkUserAction = haveUserDoneAction(message_actions, actionData.actionType, appState.user.username)
            if (checkUserAction) {
                message_actions = message_actions.filter(item => !(item["custom_user__full_name"] === appState.user.username && item["action"] === actionData.actionType))
            } else {
                let tempArr = [...message_actions]
                tempArr.push({"custom_user__full_name": appState.user.username, "action": actionData.actionType})
                message_actions = tempArr
            }
        }

        props.setDataSource(draft => {
            draft.messages[index].message_actions = message_actions
        })
    }

    useEffect(() => {
        if (actionData.requestCount === 0 || actionData.messageId === -1 || actionData.actionType === '') {
            return
        }

        const ourRequest = Axios.CancelToken.source()

        async function toggleMessageAction() {
            setActionData(draft => {
                draft.isLoading = true
            })
            try {
                const response = await api.post(
                    'toggle_message_action/', { 'message_id': actionData.messageId, 'action': actionData.actionType, }, appState.token.post_config, { cancelToken: ourRequest.token }
                )
                //console.log(response.data)
                let message = "Action is successfull"
                if (response.data === 'FAVORITE created') {
                    message = "Message is added to favorites"
                } else if (response.data === 'FAVORITE removed') {
                    message = "Message is removed from favorites"
                }
                appDispatch({
                    type: "success",
                    data: message
                })
                updateTableData()
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to do action!"
                })
            } finally {
                setActionData(draft => {
                    draft.isLoading = false
                })
            }
        }
        toggleMessageAction()
        return () => {
            ourRequest.cancel()
        }
    }, [actionData.requestCount])

    
    function onActionClick(actionType) {
        if (appState.user.role === "DATA MANAGER" && actionType === "FAVORITE")
            return
        if (appState.user.role !== "DATA MANAGER" && actionType === "DISLIKE")
            return
        setActionData(draft => {
            draft.messageId = props.record.message.id
            draft.actionType = actionType
            draft.requestCount++
        })
    }

    function heartStyle(hType) {
        let fontSize = '18px'
        if (hType === 'outlined')
            fontSize = '16px'
        let cursor = 'pointer'
        if (appState.user.role === "DATA MANAGER")
            cursor = 'default'
        return { fontSize: fontSize, color: 'red', cursor: cursor }
    }

    function getUsersFromActionType(arr, actionType) {
        let result = []
        arr.forEach(item => {
            if (item['action'] === actionType)
                result.push(item['custom_user__full_name'])
        })
        return result
    }

    function checkFavorite() {
        let checked = false
        let message = ""

        if (appState.user.role !== "DATA MANAGER") {
            if (props.record.message_actions.includes("FAVORITE")) {
                checked = true
                message = "Unfavorite"
            } else {
                checked = false
                message = "Favorite"
            }
        } else {
            const message_actions = splitArrToFields(props.record.message_actions)
            if (message_actions === null || !message_actions.action.includes("FAVORITE")) {
                checked = false
                message = "No favs"
            } else {
                checked = true
                message = getUsersFromActionType(props.record.message_actions, "FAVORITE").join(', ')
            }
        }

        if (checked) {
            return (
                <Tooltip
                    placement="right"
                    title={<span>{message}</span>}
                >
                    <HeartFilled
                        style={heartStyle('filled')}
                        onClick={() => onActionClick('FAVORITE')}
                    />
                </Tooltip>
            )
        }

        return (
            <Tooltip
                placement="right"
                title={<span>{message}</span>}
            >
                <HeartOutlined
                    style={heartStyle('outlined')}
                    onClick={() => onActionClick('FAVORITE')}
                />
            </Tooltip>
        )
    }

    function checkDislike() {
        if (appState.user.role !== "DATA MANAGER") {
            return ""
        }

        const usersDisliked = getUsersFromActionType(props.record.message_actions, "DISLIKE")

        if (usersDisliked.includes(appState.user.username)) {
            return (
                <Tooltip
                    placement="right"
                    title={<span>{"Undislike"}</span>}
                >
                    <DislikeFilled
                        style={{fontSize:'18px', color:'#1677FF', cursor:'pointer'}}
                        onClick={() => onActionClick('DISLIKE')}
                    />
                </Tooltip>
            )
        }

        return (
            <Tooltip
                placement="right"
                title={<span>{"Dislike"}</span>}
            >
                <DislikeOutlined
                    style={{fontSize:'16px', color:'#1677FF', cursor:'pointer'}}
                    onClick={() => onActionClick('DISLIKE')}
                />
            </Tooltip>
        )
    }

    if (actionData.isLoading) {
        return <Spin size="small" />
    }

    return (
        <Space size="small">
            {
                checkFavorite()
            }
            {
                checkDislike()
            }
        </Space>
    )
}

export default MessageActions