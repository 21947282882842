import { useEffect, useContext, useRef } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { useImmer } from "use-immer"
import { List, Button, Space, Alert, Tooltip, Tag, Spin, Avatar } from 'antd'
import { UserOutlined, PhoneOutlined, MailOutlined, FlagOutlined, BankOutlined, ShoppingOutlined, SkinOutlined, CommentOutlined } from '@ant-design/icons'
import { cropText, formatPhoneNumber, getLocalTime } from "../Utils"
import SideMenu from './SideMenu'
import NotFound from "./NotFound"

import Axios from "axios"
import api from '../AxiosInstance'

import '../App.css'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'


function ContactScreen(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const chatWindowRef = useRef(null)

    const { contactId } = useParams()
    const [navState, setNavState] = useImmer({navType: ""})
    const navigate = useNavigate()

    const [contactData, setContactData] = useImmer({isLoading:false, data:null, page:1, pageSize:100, requestCount:0, prevContainerHeight:0})

    useEffect(() => {
        const navType = performance.getEntriesByType("navigation")[0].type
        setNavState(draft => {
            draft.navType = navType
        })
    }, [navigate])

    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()

        async function fetchContactInfo() {
            if (!appState.loggedIn) return
            setContactData(draft => {
                draft.isLoading = true
            })
            try {
                let url = 'get_contact_info/'
                url += `?page_size=${contactData.pageSize}`
                url += `&page=${contactData.page}`
                url += `&contact_id=${contactId}`

                const response = await api.get(
                    url, appState.token.get_config, { cancelToken: ourRequest.token }
                )
                //console.log(response.data)

                if (contactData.data === null) {
                    setContactData(draft => {
                        draft.data = response.data
                    })
                } else {
                    const cumMessages = response.data.messages.concat(contactData.data.messages)
                    setContactData(draft => {
                        draft.data.messages = cumMessages
                    })
                }
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to get contact info!"
                })
            } finally {
                setContactData(draft => {
                    draft.isLoading = false
                })
            }
        }
        fetchContactInfo()
        return () => {
            ourRequest.cancel()
        }
    }, [contactId, contactData.requestCount])

    useEffect(() => {
        if (chatWindowRef === null || chatWindowRef.current === null) return
        if (contactData.requestCount === 0) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight
        } else {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight - contactData.prevContainerHeight
        }
        setContactData(draft => {
            draft.prevContainerHeight = chatWindowRef.current.scrollHeight
        })
    }, [contactData.data])

    function get_product_model_names(mapping_list) {
        if (mapping_list.length === 0) return "Unknown"
        return mapping_list.map((item, index) => (
            <Tooltip key={index} title={item.product_model.name} placement="bottom">
                <p key={item.product_model.name}>
                    {cropText(item.product_model.name, 35)}
                </p>
            </Tooltip>
        ))
    }

    function get_common_groups(common_groups) {
        if (common_groups.length === 0) return "-"
        return common_groups.map((item, index) => (
            <Tooltip key={index} title={item} placement="bottom">
                <p key={item}>
                    {cropText(item, 35)}
                </p>
            </Tooltip>
        ))
    }

    let phoneNumber = contactData.data !== null ? contactData.data.vendor.phone_number : null
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber)
    if (formattedPhoneNumber !== null) phoneNumber = formattedPhoneNumber

    const commonGroupCount = contactData.data === null ? 0 : (contactData.data.common_groups === null ? 0 : contactData.data.common_groups.length)

    const vendor_data = [
        {
            icon: <UserOutlined />,
            title: 'Contact Name',
            value: contactData.data === null ? "" : contactData.data.vendor.full_name,
        },
        {
            icon: <SkinOutlined />,
            title: 'Type',
            value: contactData.data === null ? "" : contactData.data.vendor_type,
        },
        {
            icon: <PhoneOutlined />,
            title: 'Phone Number',
            value: phoneNumber,
        },
        {
            icon: <FlagOutlined />,
            title: 'Country',
            value: contactData.data === null ? "" : contactData.data.vendor.country_names_from_area_code,
        },
        {
            icon: <MailOutlined />,
            title: 'E-mail',
            value: contactData.data === null ? "" : (contactData.data.vendor.email === null ? "Unknown" : contactData.data.vendor.email),
        },
        {
            icon: <BankOutlined />,
            title: 'Company',
            value: contactData.data === null ? "" : (contactData.data.vendor.company === null ? "Unknown" : contactData.data.vendor.company.name),
        },
        {
            icon: <ShoppingOutlined />,
            title: 'Product Types',
            value: contactData.data === null ? "" : (
                contactData.data.product_name_mapping === null ? "" : get_product_model_names(contactData.data.product_name_mapping)
            ),
        },
        {
            icon: <CommentOutlined />,
            title: `Common Groups (${commonGroupCount})`,
            value: contactData.data === null ? "" : (
                contactData.data.common_groups === null ? "" : get_common_groups(contactData.data.common_groups)
            ),
        },
    ]

    const handleScroll = (e) => {
        /*if (e.target.scrollTop === 0) {} */
        setContactData(draft => {
            draft.page++
            draft.requestCount++
        })
    }

    function getGroupNames(message) {
        return (
            <Tooltip title={message.group_names} placement="bottom">
                <p>
                    {cropText(message.group_names, 105)}
                </p>
            </Tooltip>
        )
    }

    /*if (document.referrer === "" || navState.navType !== "navigate") {
        return <NotFound />
    }*/

    if (contactData.isLoading) {
        return <Spin size="small" />
    }

    return (
        <div className='App'>
            <SideMenu connected={props.connected}/>
            <div style={{width:'12px'}}/>
            <div className="chat-window" ref={chatWindowRef} /*onScroll={handleScroll}*/>
                {(contactData.data !== null && (contactData.page * contactData.pageSize < contactData.data.count)) ? 
                    <Button style={{display:'block', marginLeft:'auto',}} type="link" onClick={handleScroll}>Load Older Messages</Button> : ""}
                <List
                    className="message-list"
                    itemLayout="horizontal"
                    dataSource={contactData.data === null ? [] : contactData.data.messages}
                    renderItem={(message, index) => (
                        <List.Item key={index} style={{backgroundColor: Math.floor(index / contactData.pageSize) % 2 === 0 ? '#f0f0f0': '#fafafa', borderRadius:4}}>
                            <List.Item.Meta
                                avatar={<Space direction="vertical" size="small">
                                            <small>{`${contactData.data.count - (contactData.data.messages.length - index - 1)}/${contactData.data.count}`}</small>
                                            <Avatar
                                                style={{
                                                    backgroundColor: message.is_incoming ? '#f56a00' : '#87d068',
                                                    marginTop:-6,
                                                }}
                                                //size="large"
                                            >
                                                {message.sender_name[0]}
                                            </Avatar>
                                        </Space>}
                                title={<Space><div>{message.sender_name + " ➺ "}</div>{getGroupNames(message)}</Space>}
                                description={message.content}
                            />
                            <div className="chat-time">
                                {getLocalTime(message.message_time)}
                            </div>
                        </List.Item>
                    )}
                />
            </div>
            <Space direction='horizontal' align='start' className='settings-panel'>
                <List style={{width: '400px', marginLeft: '15px'}}
                    dataSource={vendor_data}
                    renderItem={(item, index) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<div style={{paddingTop:'36px'}}>{item.icon}</div>}
                                title={<p>{item.title}</p>}
                                description={item.value}
                            />
                        </List.Item>
                    )}
                />
            </Space>
        </div>
    )
}

export default ContactScreen