import { useEffect, useContext } from "react"
import { AutoComplete } from 'antd'
import Axios from "axios"
import api from "../AxiosInstance"

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

function ProductUPCs(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    useEffect(() => {
        if (props.state.UPC === null) {
            return
        }
        const ourRequest = Axios.CancelToken.source()

        let productInstance = null

        async function fetchProductInstanceByUPC() {
            try {
                const response = await api.get(
                    `get_product_instance_by_UPC/${props.state.UPC}/`, appState.token.get_config, { cancelToken: ourRequest.token }
                )
                productInstance = response.data
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "info",
                    err: err,
                    data: "Product not found!"
                })
            } finally {
                props.setState(draft => {
                    draft.productInstance = productInstance
                    draft.requestCount++
                })
            }
        }
        fetchProductInstanceByUPC()
        return () => {
            ourRequest.cancel()
        }
    }, [props.loading])

    return (
        <AutoComplete
            size="small"
            disabled={props.disabled}
            value={props.value}
            options={props.options}
            loading={props.loading}
            onSelect={props.onSelect}
            onSearch={props.onSearch}
            style={{
                width: 124,
            }}/>
    )
}

export default ProductUPCs
