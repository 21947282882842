import { useContext } from 'react'
import { useLocation, Link } from "react-router-dom"
import { Tooltip, Button, Badge } from "antd"
import { TableOutlined, MessageOutlined, LinkOutlined, NodeIndexOutlined,
    ContactsOutlined, WhatsAppOutlined, /*SettingOutlined,*/ InfoCircleOutlined,
    SubnodeOutlined, UploadOutlined, WechatOutlined } from '@ant-design/icons'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'


function SideMenu(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const location = useLocation()

    function temp() {}

    return (
        <div className="SideMenu">
            <Link to='/messages'>
                <Tooltip
                    placement="right"
                    title={<span>Messages</span>}
                >
                    <Button
                        type={(location.pathname === "/" || location.pathname === "/messages" || location.pathname === "/messages/") ? "primary": "default"}
                        //className={(location.pathname === "/") ? "btn-style-red": "btn-style"}
                        onClick={temp}
                        size="large"
                        icon={<MessageOutlined />}
                    />
                </Tooltip>
            </Link>
            {/*appState.user.role.startsWith("SALES") || appState.user.role === "GUEST" ? "" :
            <Link to='/deals'>
                <Tooltip
                    placement="right"
                    title={<span>Deals</span>}
                >
                    <Button
                        type={(location.pathname === "/deals" || location.pathname === "/deals/") ? "primary": "default"}
                        //className={(location.pathname === "/") ? "btn-style-red": "btn-style"}
                        onClick={temp}
                        size="large"
                        icon={<TableOutlined />}
                    />
                </Tooltip>
            </Link>
            */}
            { appState.user.role !== "DATA MANAGER-" ? "" :
            <Link to='/manage_mappings'>
                <Tooltip
                    placement="right"
                    title={<span>Manage mappings</span>}
                >
                    <Button
                        type={(location.pathname === "/manage_mappings" || location.pathname === "/manage_mappings/") ? "primary": "default"}
                        //className={(location.pathname === "/") ? "btn-style-red": "btn-style"}
                        onClick={temp}
                        size="large"
                        icon={<SubnodeOutlined />}
                    />
                </Tooltip>
            </Link>
            }
            { appState.user.role !== "DATA MANAGER-" ? "" :
            <Link to='/mappings'>
                <Tooltip
                    placement="right"
                    title={<span>Product mappings</span>}
                >
                    <Button
                        type={(location.pathname === "/mappings" || location.pathname === "/mappings/") ? "primary": "default"}
                        //className={(location.pathname === "/") ? "btn-style-red": "btn-style"}
                        onClick={temp}
                        size="large"
                        icon={<NodeIndexOutlined />}
                    />
                </Tooltip>
            </Link>
            }
            { appState.user.role === "GUEST" ? "" :
            <Link to='/chatlinks'>
                <Tooltip
                    placement="right"
                    title={<span>ChatLinks</span>}
                >
                    <Button
                        type={(location.pathname === "/chatlinks" || location.pathname === "/chatlinks/") ? "primary": "default"}
                        //className={(location.pathname === "/") ? "btn-style-red": "btn-style"}
                        onClick={temp}
                        size="large"
                        icon={<LinkOutlined />}
                    />
                </Tooltip>
            </Link>
            }
            { appState.user.role === "GUEST" ? "" :
            <Link to='/reaches'>
                <Tooltip
                    placement="right"
                    title={<span>Reaches</span>}
                >
                    <Button
                        type={(location.pathname === "/reaches" || location.pathname === "/reaches/") ? "primary": "default"}
                        //className={(location.pathname === "/") ? "btn-style-red": "btn-style"}
                        onClick={temp}
                        size="large"
                        icon={<WechatOutlined />}
                    />
                </Tooltip>
            </Link>
            }
            {/*<Link to='/contacts'>
                <Tooltip
                    placement="right"
                    title={<span>Contacts</span>}
                >
                    <Button
                        type={(location.pathname === "/contacts" || location.pathname === "/contacts/") ? "primary": "default"}
                        //className={(location.pathname === "/") ? "btn-style-red": "btn-style"}
                        onClick={temp}
                        size="large"
                        icon={<ContactsOutlined />}
                    />
                </Tooltip>
            </Link>*/}
            { appState.user.role === "GUEST" || appState.user.username === "admin" ? "" :
            <Link to='/whatsapp' /*target="_blank"*/>
                <Badge count={" "} size="small" color={props.connected ? "#52c41a" : "#f5222d"}>
                    <Tooltip
                        placement="right"
                        title={<span>WhatsApp</span>}
                    >
                        <Button
                            type={(location.pathname === "/whatsapp" || location.pathname === "/whatsapp/") ? "primary": "default"}
                            //className={(location.pathname === "/whatsapp") ? "btn-style-red": "btn-style"}
                            onClick={temp}
                            size="large"
                            icon={<WhatsAppOutlined />}
                        />
                    </Tooltip>
                </Badge>
            </Link>
            }
            { appState.user.role === "GUEST" || appState.user.role === "SALESMAN" ? "" :
            <Link to='/whatsapp2' /*target="_blank"*/>
                {/*<Badge count={" "} size="small" color={props.connected ? "#52c41a" : "#f5222d"}>*/}
                    <Tooltip
                        placement="right"
                        title={<span>WhatsApp Team</span>}
                    >
                        <Button
                            type={(location.pathname === "/whatsapp2" || location.pathname === "/whatsapp2/") ? "primary": "default"}
                            //className={(location.pathname === "/whatsapp") ? "btn-style-red": "btn-style"}
                            onClick={temp}
                            size="large"
                            icon={<><WhatsAppOutlined style={{margin:-4}}/> <WhatsAppOutlined style={{margin:-4}}/></>}
                        />
                    </Tooltip>
                {/*</Badge>*/}
            </Link>
            }
            { appState.user.role !== "DATA MANAGER-" ? "" :
            <Link to='/file_upload'>
                <Tooltip
                    placement="right"
                    title={<span>Manage file uploads</span>}
                >
                    <Button
                        type={(location.pathname === "/file_upload" || location.pathname === "/file_upload/") ? "primary": "default"}
                        //className={(location.pathname === "/") ? "btn-style-red": "btn-style"}
                        onClick={temp}
                        size="large"
                        icon={<UploadOutlined />}
                    />
                </Tooltip>
            </Link>
            }
            { appState.user.role === "GUEST" ? "" :
            <Link to='/settings' /*target="_blank"*/>
                <Tooltip
                    placement="right"
                    title={<span>User Info</span>}
                >
                    <Button
                        type={(location.pathname === "/settings" || location.pathname === "/settings/") ? "primary": "default"}
                        //className={(location.pathname === "/settings") ? "btn-style-red": "btn-style"}
                        onClick={temp}
                        size="large"
                        icon={<InfoCircleOutlined />}
                    />
                </Tooltip>
            </Link>
            }
            {/*
            <Link to='/test'> //target="_blank">
                <Tooltip
                    placement="right"
                    title={<span>Test</span>}
                >
                    <Button
                        type={(location.pathname === "/test" || location.pathname === "/test/") ? "primary": "default"}
                        //className={(location.pathname === "/test") ? "btn-style-red": "btn-style"}
                        onClick={temp}
                        size="large"
                        icon={<SettingOutlined />}
                    />
                </Tooltip>
            </Link>
            */}
        </div>
    )
}

export default SideMenu