import { useEffect, useContext } from 'react'
import { Modal, Radio } from "antd"
import { useImmer } from "use-immer"
import { cropText } from '../Utils'
import Axios from "axios"
import api from '../AxiosInstance'

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

import { vendor_name, vendor_email, vendor_company_name, vendor_country_name } from "../Utils"

function VendorModal(props) {

    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [vendorModalData, setVendorModalData] = useImmer({nameValue: 2, emailValue: 2, companyNameValue: 2, countryNameValue: 2})
    const [newVendorData, setNewVendorData] = useImmer({
        company: null, vendorThis: null, vendorZoho: null, vendorCreateZoho: null, updateRequestCount: 0, createRequestCount: 0
    })

    useEffect(() => {
        if (newVendorData.createRequestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function createVendor() {
            props.setDataSource(draft => {
                draft.isLoading = true
            })
            let zoho_id = null
            try {
                const response = await api.post(
                    'import_export_vendor/',
                    {'phone_number':props.state.vendor.phone_number, 'data': newVendorData.vendorCreateZoho, 'type':'create'},
                    appState.token.post_config,
                    { cancelToken: ourRequest.token }
                )
                appDispatch({
                    type: "success",
                    data: "Vendor created in ZOHO"
                })
                zoho_id = response.data
                props.setState(draft => {
                    draft.newlyCreated = true
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to create vendor in ZOHO!"
                })
            }
            if (zoho_id !== null) {
                const new_vendor_data = {
                    'full_name': props.state.vendor.full_name,
                    'email': props.state.vendor.email,
                    'company': vendor_company_id(),
                    'zoho_id': zoho_id,
                }
                setNewVendorData(draft => {
                    draft.company = null
                    draft.vendorThis = new_vendor_data
                    draft.vendorZoho = null
                    draft.updateRequestCount++
                })
                //props.updateZohoId(props.state.key, zoho_id, 'Vendor')
            } else {
                // to discard changes
                props.setFilterOptions(draft => {
                    //draft.colorRowId = props.state.key
                    draft.requestCount++
                })
            }
            props.setDataSource(draft => {
                draft.isLoading = false
            })
        }
        createVendor()

        return () => {
            ourRequest.cancel()
        }
    }, [newVendorData.createRequestCount])

    useEffect(() => {
        if (newVendorData.updateRequestCount === 0) return

        const ourRequest = Axios.CancelToken.source()

        async function updateVendor() {
            props.setDataSource(draft => {
                draft.isLoading = true
            })
            // CREATE COMPANY
            if (newVendorData.company !== null) {
                try {
                    const response = await api.post(
                        'list_companies/',
                        newVendorData.company,
                        appState.token.post_config
                    )
                    appDispatch({
                        type: "success",
                        data: "New company created"
                    })
                    setNewVendorData(draft => {
                        draft.vendorThis.company = response.data.id  // update company id with the new id obtained from db
                    })
                } catch (err) {
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to create new company!"
                    })
                }
            }
            // UPDATE VENDOR IN DEALCATCHER
            if (newVendorData.vendorThis !== null) {
                try {
                    const response = await api.patch(
                        `update_vendor/${props.state.vendor.id}/`,
                        newVendorData.vendorThis,
                        appState.token.post_config
                    )
                    appDispatch({
                        type: "success",
                        data: "Vendor updated in DealCatcher"
                    })
                    props.setFilterOptions(draft => {
                        draft.vendors.requestCount++
                    })
                } catch (err) {
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to update vendor in DealCatcher!"
                    })
                }
            }
            // UPDATE VENDOR IN ZOHO
            if (newVendorData.vendorZoho !== null) {
                try {
                    const response = await api.post(
                        'import_export_vendor/',
                        {'phone_number':props.state.vendor.phone_number, 'data': newVendorData.vendorZoho, 'type':'update'},
                        appState.token.post_config,
                        { cancelToken: ourRequest.token }
                    )
                    appDispatch({
                        type: "success",
                        data: "Vendor updated in ZOHO"
                    })
                } catch (err) {
                    //console.log(err)
                    appDispatch({
                        type: "error",
                        err: err,
                        data: "Unable to update vendor in ZOHO!"
                    })
                }
            }
            if (props.state.isFireExport) {
                props.setState(draft => {
                    draft.fireExportReqCnt++
                })
            } else {
                props.setDataSource(draft => {
                    draft.isLoading = false
                })
                props.setFilterOptions(draft => {
                    //draft.colorRowId = props.state.key
                    draft.companies.requestCount++
                    draft.requestCount++
                })
            }
        }
        updateVendor()

        return () => {
            ourRequest.cancel()
        }
    }, [newVendorData.updateRequestCount])


    function handleOk() {
        if (props.state.zoho_vendor === null) {  // contact not found in zoho, create contact in zoho with info in dealcatcher
            const new_vendor_data_zoho = {
                'full_name': props.state.vendor.full_name,
                'email': props.state.vendor.email,
                'company_name': vendor_company_name(props.state.vendor),
                'country_name': vendor_country_name(props.state.vendor),
            }
            setNewVendorData(draft => {
                draft.vendorCreateZoho = new_vendor_data_zoho
                draft.createRequestCount++
            })
        } else {  // update contact in dealcatcher and zoho
            let new_company_id = null
            let new_company_data = null
            if (vendorModalData.companyNameValue === 2) {
                const selectedCompanyName = props.state.zoho_vendor.company.name
                const companyIndex = props.companies.findIndex((item) => selectedCompanyName.toLowerCase() === item.name.toLowerCase())
                if (companyIndex !== -1) {  // company with the same name found, so use it
                    new_company_id = props.companies[companyIndex].id
                } else {  // company with this name is not found create new one
                    // handle country
                    let new_country_id = null
                    if (vendorModalData.countryNameValue === 1) {
                        new_country_id = vendor_country_id()
                    } else { // search country in countries
                        const selectedCountryName = props.state.zoho_vendor.company.country_name
                        const countryIndex = props.countries.findIndex((item) => selectedCountryName.toLowerCase() === item.name.toLowerCase())
                        if (countryIndex !== -1) {
                            new_country_id = props.countries[countryIndex].id
                        } else { // if country is not found, then use dealcatcher's country
                            new_country_id = vendor_country_id()
                        }
                    }
                    // create new company
                    new_company_data = {
                        'name': selectedCompanyName,
                        'country': new_country_id,
                    }
                    if (selectedCompanyName === null || selectedCompanyName === '') {
                        new_company_data = null
                    }
                }
            }

            let email = vendorModalData.emailValue === 1 ? props.state.vendor.email : props.state.zoho_vendor.email
            if (email == '') email = null
            
            const new_vendor_data = {
                'full_name': vendorModalData.nameValue === 1 ? props.state.vendor.full_name : props.state.zoho_vendor.full_name,
                'email': email,
                'company': vendorModalData.companyNameValue === 1 ? vendor_company_id() : new_company_id,
            }
            const new_vendor_data_zoho = {
                'full_name': vendorModalData.nameValue === 1 ? props.state.vendor.full_name : props.state.zoho_vendor.full_name,
                'email': email,
                'company_name': vendorModalData.companyNameValue === 1 ? vendor_company_name(props.state.vendor) : props.state.zoho_vendor.company.name,
                'country_name': vendorModalData.countryNameValue === 1 ? vendor_country_name(props.state.vendor) : props.state.zoho_vendor.company.country_name,
            }
            setNewVendorData(draft => {
                draft.company = new_company_data
                draft.vendorThis = new_vendor_data
                draft.vendorZoho = new_vendor_data_zoho
                draft.updateRequestCount++
            })
        }
        props.setState(draft => {
            draft.modal.isOpen = false
        })
    }

    function handleCancel() {
        props.setState(draft => {
            draft.modal.isOpen = false
        })
        props.setFilterOptions(draft => {
            draft.companies.requestCount++
            draft.requestCount++
        })
        props.setDataSource(draft => {
            draft.isLoading = false
        })
    }

    function onNameChange(e) {
        setVendorModalData(draft => {
            draft.nameValue = e.target.value
        })
    }

    function onEmailChange(e) {
        setVendorModalData(draft => {
            draft.emailValue = e.target.value
        })
    }

    function onCompanyNameChange(e) {
        setVendorModalData(draft => {
            draft.companyNameValue = e.target.value
        })
    }

    function onCountryNameChange(e) {
        setVendorModalData(draft => {
            draft.countryNameValue = e.target.value
        })
    }

    function vendor_company_id() {
        return props.state.vendor.company !== null ? props.state.vendor.company.id : null
    }

    function vendor_country_id() {
        return props.state.vendor.company !== null ? (props.state.vendor.company.country !== null ? props.state.vendor.company.country.id : null) : null
    }

    return (
        <Modal
            centered
            title={props.state.zoho_vendor === null ? "" : "Select Data to be Saved"}
            open={props.state.modal.isOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
                {
                    props.state.zoho_vendor === null ? `Contact ${props.state.vendor !== null ? props.state.vendor.phone_number : ''} not found in ZOHO. Do you want to export this contact to ZOHO?` :
                    <>
                        <p>{`Contact: ${props.state.vendor !== null ? props.state.vendor.phone_number : ''}`}</p>
                        <span>Name: </span>
                        {vendor_name(props.state.vendor) === props.state.zoho_vendor.full_name ? vendor_name(props.state.vendor) :
                            <Radio.Group onChange={onNameChange} value={vendorModalData.nameValue}>
                                <Radio value={1}>{vendor_name(props.state.vendor)}</Radio>
                                <Radio value={2}>{props.state.zoho_vendor.full_name}</Radio>
                            </Radio.Group>
                        }<br/>
                        <span>Email: </span>
                        {vendor_email(props.state.vendor) === props.state.zoho_vendor.email ? vendor_email(props.state.vendor) :
                            <Radio.Group onChange={onEmailChange} value={vendorModalData.emailValue}>
                                <Radio value={1}>{vendor_email(props.state.vendor)}</Radio>
                                <Radio value={2}>{props.state.zoho_vendor.email}</Radio>
                            </Radio.Group>
                        }<br/>
                        <span>Company Name: </span>
                        {vendor_company_name(props.state.vendor) === props.state.zoho_vendor.company.name ? cropText(vendor_company_name(props.state.vendor), 20) :
                            <Radio.Group onChange={onCompanyNameChange} value={vendorModalData.companyNameValue}>
                                <Radio value={1}>{cropText(vendor_company_name(props.state.vendor), 20)}</Radio>
                                <Radio value={2}>{cropText(props.state.zoho_vendor.company.name, 20)}</Radio>
                            </Radio.Group>
                        }<br/>
                        <span>Country: </span>
                        {vendor_country_name(props.state.vendor) === props.state.zoho_vendor.company.country_name ? vendor_country_name(props.state.vendor) :
                            <Radio.Group onChange={onCountryNameChange} value={vendorModalData.countryNameValue}>
                                <Radio value={1}>{vendor_country_name(props.state.vendor)}</Radio>
                                <Radio value={2}>{props.state.zoho_vendor.company.country_name}</Radio>
                            </Radio.Group>
                        }<br/>
                        <br />
                        <br />
                        <small>{"*DealCatcher <|> ZOHO"}</small><br />
                        <small>{"**Contact will be updated in both ZOHO and DealCatcher"}</small>
                    </>
                }
        </Modal>
    )
}

export default VendorModal
