import { useEffect, useContext } from "react"
import { Select } from 'antd'
import Axios from "axios"
import api from "../AxiosInstance"

import StateContext from "../StateContext"
import DispatchContext from '../DispatchContext'

function WAGroupNames(props) {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    useEffect(() => {
        if (props.state.searchText === undefined || props.state.searchText === null || props.state.searchText === "" || props.loading === false) {
            props.setState(draft => {
                draft.loading = false
            })
            return
        }
        const ourRequest = Axios.CancelToken.source()

        let waGroupNames = []

        async function fetchWAGroupNames() {
            try {
                const response = await api.post(
                    `search_wa_groups/`, { 'search_text':props.state.searchText }, appState.token.post_config, { cancelToken: ourRequest.token }
                )
                waGroupNames = response.data
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to get group names!"
                })
            } finally {
                props.setFilterOptions(draft => {
                    draft.waGroupNames.data = waGroupNames
                })
                props.setState(draft => {
                    draft.loading = false
                })
            }
        }
        fetchWAGroupNames()
        return () => {
            ourRequest.cancel()
        }
    }, [props.loading])

    return (
        <Select
            size="small"
            mode="tags"
            placeholder="Search Whatsapp Groups"
            notFoundContent="Type to Search"
            disabled={props.disabled}
            value={props.value}
            options={props.options}
            loading={props.loading}
            onChange={props.onChange}
            onSearch={props.onSearch}
            filterOption={false}
            style={{
                width: 200,
            }}/>
    )
}


export default WAGroupNames
