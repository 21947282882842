import { useEffect } from "react"
import { Spin } from 'antd'
import AntSelect from "./AntSelect"
import Axios from "axios"
import api from "../AxiosInstance"


function MessageStates(props) {
    if (props.state.isLoading) return <Spin size="small" />

    function getOptions() {
        return props.state.payload
    }

    return (
        <AntSelect
            disabled={props.disabled}
            selected={props.selected}
            options={getOptions()}
            onChange={props.onChange}
            width={200}
            size="small"
            />
    )
}

export function useFetchMessageStates(appState, appDispatch, setMessageStatesState, filterOptions, setFilterOptions) {
    useEffect(() => {
        
        const ourRequest = Axios.CancelToken.source()

        async function fetchMessageStates() {
            if (!appState.loggedIn || appState.user.role === "GUEST") return
            setMessageStatesState(draft => {
                draft.isLoading = true
            })
            setFilterOptions(draft => {
                draft.states.isLoading = true
            })
            try {
                const response = await api.get('list_message_states/', appState.token.get_config, { cancelToken: ourRequest.token })
                //console.log(`${response.data.length} states found`)
                setMessageStatesState(draft => {
                    draft.payload = response.data
                })
                setFilterOptions(draft => {
                    draft.states.data = response.data
                })
            } catch (err) {
                //console.log(err)
                appDispatch({
                    type: "error",
                    err: err,
                    data: "Unable to fetch message states!"
                })
            }
            setMessageStatesState(draft => {
                draft.isLoading = false
            })
            setFilterOptions(draft => {
                draft.states.isLoading = false
            })
        }
        fetchMessageStates()
        return () => {
            ourRequest.cancel()
        }
    }, [filterOptions.states.requestCount, appState.loggedIn,])
}

export default MessageStates
